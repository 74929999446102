import React from 'react';
import classnames from 'classnames';
import { Container, ContainerStyleSize, DirectionAndPlacement, Size } from '@pointdotcom/pds';
import MainFooter from 'components/prequal/MainFooter';
import MainHeader from 'components/prequal/MainHeader';
import PageContent from 'components/prequal/PageContent';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { useProduct } from 'containers/prequal/hooks/useProduct';
import { Products } from 'store/constants';
import * as styles from './styles';

interface WhiteContainerProps {
  content?: null | React.ComponentProps<typeof PageContent.Header>['content'];
  align?: DirectionAndPlacement.Left | DirectionAndPlacement.Center;
  compact?: boolean;
  header?: boolean;
  children: React.ReactNode;
  contentContainerSize?: ContainerStyleSize;
  product?: Products;
}

export default function WhiteContainer({
  content = null,
  align = DirectionAndPlacement.Center,
  compact,
  header = true,
  children,
  contentContainerSize,
  product: productFromProps,
}: WhiteContainerProps) {
  const nameSpace = 'White';
  const cn = [nameSpace];
  let ContentHeader = null;

  if (content) {
    ContentHeader = (
      <PageContent.Header
        content={content}
        titleSize={Size.Splash}
        titleAlign={align}
        contrastingSubheader
      />
    );
  }

  const { product = productFromProps ?? Products.HEI } = useProduct();
  const logoHref = generateUrlFromPage(pages.PREQUAL_START, { product });

  return (
    <styles.WhiteLayoutStyle
      className={classnames(cn, {
        compact,
        headerless: !header,
        centerAligned: align === 'center',
      })}
    >
      <section className="topSection">
        <Container className="mainContainer" styleSize={contentContainerSize}>
          {header && <MainHeader logoHref={logoHref} />}
          <section>
            <div>
              <div>
                <div>{ContentHeader}</div>
              </div>
            </div>
            {children}
          </section>
        </Container>
      </section>
      <MainFooter product={product} />
    </styles.WhiteLayoutStyle>
  );
}
