import { useState } from 'react';
import { useIsMobile } from '@pointdotcom/pds';
import { useIsTextTruncated } from 'hooks/useTextIsTruncated';

interface TaskTooltipProps {
  titleRef: React.RefObject<HTMLSpanElement>;
}

const useTaskTooltip = ({ titleRef }: TaskTooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const isTruncated = useIsTextTruncated(titleRef);
  const { isTouchDevice } = useIsMobile();

  const handleShowTooltip = (e: React.MouseEvent | React.TouchEvent) => {
    if (isTouchDevice && e.type !== 'touchstart') {
      return;
    }

    if (showTooltip) {
      setShowTooltip(false);
    } else if (isTruncated) {
      setShowTooltip(true);
    }
  };
  const handleHideTooltip = (e: React.MouseEvent | React.TouchEvent) => {
    if (isTouchDevice && e.type !== 'touchstart') {
      return;
    }
    setShowTooltip(false);
  };

  return { showTooltip, handleShowTooltip, handleHideTooltip };
};

export default useTaskTooltip;
