import React from 'react';
import AuthenticatedDashboard from 'components/AuthenticatedDashboard';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import StandardDashboard from './StandardDashboard';
import TimelineDashboard from './TimelineDashboard';

const DashboardPage = () => {
  const dashboardTimelineEnabled = useFeatureFlag(FeatureFlag.DashboardTimeline) === 'enabled';
  const DashboardImpl = dashboardTimelineEnabled ? TimelineDashboard : StandardDashboard;

  return (
    <AuthenticatedDashboard>
      {({ dashboard }) => dashboard != null && <DashboardImpl dashboard={dashboard} />}
    </AuthenticatedDashboard>
  );
};

export default DashboardPage;
