import React, { useEffect, useMemo, useRef, useState } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import {
  Breakpoint,
  Button,
  ButtonClickFunc,
  Container,
  DirectionAndPlacement,
  Display,
  Header,
  HelpTextAnimated,
  Icon,
  IconName,
  Input,
  InputChangeEvent,
  InputMask,
  InputMaskFormatter,
  InputMaskType,
  MaskEventType,
  QueryComponent,
  ResponsiveText,
  Size,
  Style,
  TemplatedText,
  useIsMobile,
} from '@pointdotcom/pds';
import HeiOfferEstimatorPageCapModal from 'containers/hei/HeiOfferEstimatorPage/HeiOfferEstimatorPageCapModal';
import { FeatureFlag, useFeatureFlagWhenInView } from 'lib/featureFlags';
import { captureProperties } from 'lib/posthogEvents';
import { ShareType } from 'models/BaseEstimateModel';
import OfferEstimateModel from 'models/OfferEstimateModel';
import { currencyMask } from 'models/helpers';
import { Products } from 'store/constants';
import { HeaderDescriptionStyle } from '../styles';
import CapShieldIcon from './CapShieldIcon';
import ScenariosTableCapTip from './ScenariosTableCapTip';
import i18n from './i18n';
import scenarios, { Scenario, ScenarioType, scenarioOrder } from './scenarios';
import * as styles from './styles';

const percMask = new InputMask({
  type: InputMaskType.Number,
  options: { format: InputMaskFormatter.Percentage, precision: 1 },
});

smoothscroll.polyfill();

type FullScenario = Omit<Scenario, 'appreciationPerc'> & {
  key: ScenarioType;
  appreciationPerc: number;
  durationInYears: number;
  appreciationYears: number;
  homeValue: number;
  finalHomeValue: number;
};

enum TableColumn {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Scenario = 'scenario',
  HomeValue = 'homeValue',
  YourShare = 'yourShare',
  TotalRepayment = 'totalRepayment',
  Repayment = 'repayment',
  EquivApr = 'equivApr',
  MonthlyPayment = 'monthlyPayment',
}

const tableHeaderText: Record<TableColumn, React.ReactNode> = {
  [TableColumn.Scenario]: (
    <ResponsiveText mobileText={i18n.homeAppreciationPlural}>
      {i18n.homeAppreciation}
    </ResponsiveText>
  ),
  [TableColumn.HomeValue]: i18n.finalHomeValue,
  [TableColumn.YourShare]: i18n.yourShare,
  [TableColumn.TotalRepayment]: i18n.yourTotalRepayment,
  [TableColumn.Repayment]: i18n.repayment,
  [TableColumn.EquivApr]: i18n.equivalentAPR,
  [TableColumn.MonthlyPayment]: i18n.monthlyPayment,
};

const tableHeaderFootnotes: Partial<Record<TableColumn, React.ReactNode>> = {
  [TableColumn.YourShare]: i18n.doesNotReflect,
  [TableColumn.EquivApr]: i18n.doesNotHaveInterest,
};

interface FlagProps {
  scenariosTableHomeValueFlagEnabled: boolean;
}

function getTableColumns({ scenariosTableHomeValueFlagEnabled }: FlagProps): TableColumn[] {
  if (scenariosTableHomeValueFlagEnabled) {
    return [
      TableColumn.Scenario,
      TableColumn.Repayment,
      TableColumn.EquivApr,
      TableColumn.MonthlyPayment,
    ];
  } else {
    return [
      TableColumn.Scenario,
      TableColumn.HomeValue,
      TableColumn.YourShare,
      TableColumn.TotalRepayment,
      TableColumn.EquivApr,
    ];
  }
}

interface TableFooterProps {
  tableColumns: TableColumn[];
}

const TableFooter = ({ tableColumns }: TableFooterProps) => (
  <styles.ScenarioTableFooterStyle data-testid="ScenarioTableFooterStyle">
    <Container>
      <ol>
        {tableColumns.map(
          (column) =>
            tableHeaderFootnotes[column] != null && (
              <li key={column}>{tableHeaderFootnotes[column]}</li>
            )
        )}
      </ol>
    </Container>
  </styles.ScenarioTableFooterStyle>
);

function useTableFlags(): [React.RefCallback<HTMLElement>, FlagProps] {
  const [tableRef, scenariosTableHomeValueFlagValue] = useFeatureFlagWhenInView(
    FeatureFlag.Cro32ScenariosTableHomeValue
  );
  const flags: FlagProps = useMemo(
    () => ({
      scenariosTableHomeValueFlagEnabled: scenariosTableHomeValueFlagValue === 'test',
    }),
    [scenariosTableHomeValueFlagValue]
  );

  return [tableRef, flags];
}

type TableHeaderProps = React.ComponentProps<typeof styles.TableHeaderStyle>;

const TableHeader = ({ collapsed }: TableHeaderProps) => {
  const [headerRef, flags] = useTableFlags();
  const tableColumns = getTableColumns(flags);

  let nextFootnoteNumber = 1;

  return (
    <styles.TableHeaderStyle ref={headerRef} collapsed={collapsed} data-testid="TableHeaderStyle">
      <Container>
        <styles.ScenarioHeaderItemContentStyle collapsed={collapsed}>
          <styles.ScenarioHeaderHeaderColumnStyle>
            {tableHeaderText[tableColumns[0]]}
          </styles.ScenarioHeaderHeaderColumnStyle>
          <styles.ScenarioCellsHeaderSectionStyle>
            {tableColumns.slice(1).map((headerTextKey) => (
              <styles.ScenarioHeaderCellStyle key={headerTextKey}>
                <span>
                  {tableHeaderText[headerTextKey]}
                  {tableHeaderFootnotes[headerTextKey] != null && <sup>{nextFootnoteNumber++}</sup>}
                </span>
              </styles.ScenarioHeaderCellStyle>
            ))}
            <styles.ScenarioCTACellStyle> </styles.ScenarioCTACellStyle>
          </styles.ScenarioCellsHeaderSectionStyle>
        </styles.ScenarioHeaderItemContentStyle>
      </Container>
    </styles.TableHeaderStyle>
  );
};

interface CustomInputProps {
  onChange: InputChangeEvent;
  value?: string;
  hasInputValue: boolean;
  _ref: React.Ref<null | HTMLInputElement>;
  isHomeValue?: boolean;
  onTempMessageChanged: (message: undefined | string) => void;
}

const CustomInput = ({
  onChange,
  value = '',
  hasInputValue,
  _ref,
  isHomeValue = false,
  onTempMessageChanged,
}: CustomInputProps) => {
  return (
    <styles.CustomInputFormStyle
      hasValue={hasInputValue}
      isHomeValue={isHomeValue}
      noValidate
      onSubmit={(e) => e.preventDefault()}
    >
      <Input
        aria-label={isHomeValue ? i18n.enterHomeValue : i18n.enterAnAnnualPerc}
        incrementable
        inputMode="numeric"
        mask={InputMaskType.Number}
        maskOptions={!isHomeValue && { format: InputMaskFormatter.Percentage, precision: 1 }}
        step={isHomeValue ? 10_000 : undefined}
        controlStyleType={Style.Default}
        onChange={onChange}
        placeholder=""
        value={value}
        min={isHomeValue ? 10_000 : -99}
        max={isHomeValue ? 99_999_999 : 99}
        noMargin
        ref={_ref}
        {...({ size: isHomeValue ? 8 : 6 } as TSFixMe)}
        styleType={Style.Mono}
        styleAlign={DirectionAndPlacement.Center}
        styleSize={Size.Small}
        maskOnEvent={isHomeValue ? MaskEventType.Change : MaskEventType.Blur}
        hideTempMessage
        onTempMessageChanged={onTempMessageChanged}
      />
    </styles.CustomInputFormStyle>
  );
};

interface CustomInputWithLabelProps {
  inputValue: string;
  hasInputValue: boolean;
  scenarioLabel: string;
  onInputChange: InputChangeEvent;
  _ref: React.Ref<null | HTMLInputElement>;
  isHomeValue?: boolean;
}

function CustomInputWithLabel({
  inputValue,
  hasInputValue,
  scenarioLabel,
  onInputChange,
  _ref,
  isHomeValue = false,
}: CustomInputWithLabelProps): JSX.Element {
  const handleSuppressClick: React.MouseEventHandler = (e) => {
    // prevents page zooming on mobile
    const parent = document.getElementsByClassName('InputContainerStyle')[0];
    if (e && e.target && (parent as TSFixMe).length > 0 && parent.contains(e.target as TSFixMe)) {
      e.preventDefault();
    }
  };

  const [tempMessage, setTempMessage] = useState<undefined | string>(undefined);

  return (
    <styles.HeaderContentCustomStyle onClick={handleSuppressClick}>
      <styles.HeaderContentCustomRowStyle hasValue={hasInputValue} isHomeValue={isHomeValue}>
        {!hasInputValue || isHomeValue ? (
          <>
            <styles.HeaderContentCustomHeaderStyle>
              {' '}
              {scenarioLabel}{' '}
            </styles.HeaderContentCustomHeaderStyle>
            <styles.HeaderContentSpacerStyle />
          </>
        ) : (
          isHomeValue && (
            <QueryComponent
              display={Display.Contents}
              queriedComponent={
                <>
                  <styles.HeaderContentCustomHeaderStyle>
                    {' '}
                    {scenarioLabel}{' '}
                  </styles.HeaderContentCustomHeaderStyle>
                  <styles.HeaderContentSpacerStyle />
                </>
              }
            />
          )
        )}
        {isHomeValue && !hasInputValue && (
          <QueryComponent
            display={Display.Contents}
            // eslint-disable-next-line react/jsx-no-useless-fragment
            queriedComponent={<></>}
            mobileBreakpoint={Breakpoint.TabletBreakpoint}
          >
            <styles.HeaderContentSpacerStyle />
          </QueryComponent>
        )}
        <CustomInput
          onChange={onInputChange}
          value={inputValue}
          hasInputValue={hasInputValue}
          _ref={_ref}
          isHomeValue={isHomeValue}
          onTempMessageChanged={setTempMessage}
        />
        {!hasInputValue && !isHomeValue && (
          <>
            <styles.HeaderContentSpacerStyle />
            <styles.PercSignStyle>%</styles.PercSignStyle>
          </>
        )}
        {hasInputValue && !isHomeValue && (
          <>
            {' '}
            <styles.HeaderContentSpacerStyle />
            <QueryComponent
              queriedComponent={i18n.plainAnnualIncrease}
              mobileBreakpoint={Breakpoint.MobileMidBreakpoint}
            >
              {i18n.plainAnnualIncreaseIn}
            </QueryComponent>
          </>
        )}
        <styles.HeaderContentSpacerStyle />
      </styles.HeaderContentCustomRowStyle>
      <HelpTextAnimated noMargin show={!!tempMessage}>
        {tempMessage}
      </HelpTextAnimated>
    </styles.HeaderContentCustomStyle>
  );
}

interface ScenarioCellsCustomPromptProps {
  isHomeValue: boolean;
}

const ScenarioCellsCustomPrompt = ({ isHomeValue = false }: ScenarioCellsCustomPromptProps) => (
  <styles.ScenarioCellsCustomInputStyle isHomeValue={isHomeValue}>
    <div>
      <Icon name={IconName.ChevronLeft} styleType={Style.Subtle} strokeSize={0} />
      <aside>{isHomeValue ? i18n.enterHomeValue : i18n.enterAnAnnualPerc}</aside>
    </div>
  </styles.ScenarioCellsCustomInputStyle>
);

type ScenarioCellValue = {
  header: React.ReactNode;
  value: string;
  capDifference?: null | number;
};

interface ScenarioCellProps {
  cellItem?: ScenarioCellValue;
  estimate: OfferEstimateModel;
  tooltipHangsDown?: boolean;
  showCapTip?: null | boolean;
  onToggleCapTip?: () => void;
}

function ScenarioCell({
  cellItem,
  estimate,
  tooltipHangsDown = false,
  showCapTip = null,
  onToggleCapTip,
}: ScenarioCellProps) {
  const [capModalIsOpen, setCapModalIsOpen] = useState<boolean>(false);

  const handleKeypress: React.KeyboardEventHandler = (event) => {
    if (event.code === 'Enter' && onToggleCapTip) {
      event.preventDefault();
      event.stopPropagation();
      onToggleCapTip();
    }
  };

  const handleClick: React.MouseEventHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onToggleCapTip?.();
  };

  const isClickable = onToggleCapTip != null && cellItem?.capDifference != null;

  if (!cellItem) {
    return null;
  }

  return (
    <styles.ScenarioCellStyle
      capTipIsShown={showCapTip}
      tabIndex={isClickable ? 0 : undefined}
      onClick={isClickable ? handleClick : undefined}
      onKeyDown={isClickable ? handleKeypress : undefined}
      tooltipHangsDown={tooltipHangsDown}
    >
      <aside>{cellItem.header}</aside>
      <styles.ScenarioCellValueStyle>
        {cellItem.capDifference != null && (
          <CapShieldIcon>
            <ScenariosTableCapTip
              capDifference={cellItem.capDifference}
              onClickCapLink={() => setCapModalIsOpen(true)}
            />
            <HeiOfferEstimatorPageCapModal
              estimate={estimate}
              isOpen={capModalIsOpen}
              onModalClose={() => setCapModalIsOpen(false)}
            />
          </CapShieldIcon>
        )}
        {cellItem.value}
      </styles.ScenarioCellValueStyle>
    </styles.ScenarioCellStyle>
  );
}

interface ScenarioCellsProps {
  fullScenario: FullScenario;
  onCalcClick: (
    e: React.MouseEvent<HTMLButtonElement>,
    options: { scenario: ScenarioType }
  ) => unknown;
  estimate: null | OfferEstimateModel;
  tooltipHangsDown?: boolean;
  showCapTip?: null | boolean;
  onToggleCapTip?: () => void;
  flags: FlagProps;
}

function ScenarioCells({
  fullScenario,
  onCalcClick,
  estimate,
  tooltipHangsDown,
  showCapTip,
  onToggleCapTip,
  flags,
}: ScenarioCellsProps): null | JSX.Element {
  if (!estimate) {
    return null;
  }

  const handleCalcClick: ButtonClickFunc = (e) => {
    onCalcClick(e, { scenario: fullScenario.key });
  };

  const { finalHomeValue } = fullScenario;
  const finalHomeValueFormatted: string = currencyMask.getFormatted(finalHomeValue);
  const homeOwnerShareFormatted = estimate.getFormattedHomeOwnerShare({
    appreciationPerc: fullScenario.appreciationPerc / 100,
    durationInYears: fullScenario.appreciationYears,
    homeValue: finalHomeValue,
  });

  const pointsShareWithOfferAmount = estimate.getHomeownerRepaymentBase({
    lessTheOfferAmount: false,
    appreciationPerc: fullScenario.appreciationPerc / 100,
    durationInYears: fullScenario.durationInYears,
    homeValue: finalHomeValue,
  });

  const capDifference =
    estimate.getHomeownerRepaymentBase({
      lessTheOfferAmount: false,
      appreciationPerc: fullScenario.appreciationPerc / 100,
      durationInYears: fullScenario.durationInYears,
      shareType: ShareType.AppreciationBased,
      homeValue: finalHomeValue,
    }) - pointsShareWithOfferAmount || null;

  const pointsShareFormatted: string = currencyMask.getFormatted(
    Math.max(0, pointsShareWithOfferAmount)
  );

  const equivInterestFormatted = estimate.getFormattedEquivAPR({
    appreciationPerc: fullScenario.appreciationPerc / 100,
    durationInYears: fullScenario.durationInYears,
    pointsShareCustom: pointsShareWithOfferAmount,
  });

  const cellValuesMap: Partial<Record<TableColumn, Omit<ScenarioCellValue, 'header'>>> = {
    [TableColumn.HomeValue]: {
      // final home value
      value: finalHomeValueFormatted,
    },
    [TableColumn.YourShare]: {
      // You keep
      value: homeOwnerShareFormatted,
    },
    [TableColumn.TotalRepayment]: {
      // point gets
      value: pointsShareFormatted,
      capDifference,
    },
    [TableColumn.Repayment]: {
      // point gets
      value: pointsShareFormatted,
      capDifference,
    },
    [TableColumn.EquivApr]: {
      // Equivalent APR
      value: equivInterestFormatted,
    },
    [TableColumn.MonthlyPayment]: {
      // Monthly payment
      value: currencyMask.getFormatted(0),
    },
  };

  return (
    <styles.ScenarioCellsSectionStyle>
      {getTableColumns(flags).map(
        (key) =>
          cellValuesMap[key] != null && (
            <ScenarioCell
              key={key}
              cellItem={{ ...cellValuesMap[key], header: tableHeaderText[key] }}
              estimate={estimate}
              tooltipHangsDown={tooltipHangsDown}
              showCapTip={showCapTip}
              onToggleCapTip={onToggleCapTip}
            />
          )
      )}
      <styles.ScenarioCTACellStyle>
        <Button
          styleAlign={DirectionAndPlacement.Center}
          styleType={Style.Dark}
          styleSize={Size.Small}
          onClick={handleCalcClick}
          gaTrackingId={`SeeTheMath${fullScenario.key.charAt(0).toUpperCase() + fullScenario.key.slice(1)}`}
        >
          {i18n.seeTheMath}
        </Button>
      </styles.ScenarioCTACellStyle>
    </styles.ScenarioCellsSectionStyle>
  );
}

interface ScenarioItemProps {
  customScenarioPerc: string;
  setCustomScenarioPerc: (newValue: string) => void;
  customScenarioValue: string;
  setCustomScenarioValue: (newValue: string) => void;
  scenario: Scenario;
  scenarioKey: ScenarioType;
  onCalcClick: (
    e: React.MouseEvent<HTMLButtonElement>,
    options: { scenario: ScenarioType }
  ) => unknown;
  estimate: OfferEstimateModel;
  product: Products;
  getBuyBackTime: () => string | number;
  isLastRow?: boolean;
  showCapTip?: null | boolean;
  onToggleCapTip?: () => void;
  flags: FlagProps;
}

const ScenarioItem = ({
  customScenarioPerc,
  setCustomScenarioPerc,
  customScenarioValue,
  setCustomScenarioValue,
  scenario,
  scenarioKey,
  onCalcClick,
  estimate,
  product,
  getBuyBackTime,
  isLastRow,
  showCapTip,
  onToggleCapTip,
  flags,
}: ScenarioItemProps) => {
  const customInputRef = useRef<null | HTMLInputElement>(null);

  const handleCustomPercChange: InputChangeEvent = (e, { value }) => {
    setCustomScenarioPerc(value);
  };
  const handleCustomValueChange: InputChangeEvent = (e, { value }) => {
    setCustomScenarioValue(value);
  };

  const parsedCustomValue = parseFloat(
    (flags.scenariosTableHomeValueFlagEnabled ? customScenarioValue : customScenarioPerc).replace(
      /[, $%]/g,
      ''
    )
  );

  const selectedYear = getBuyBackTime();
  const scenarioLabel = scenario.label;
  const hasInputValue = !Number.isNaN(parsedCustomValue);
  const ContentComponentStyle =
    scenarioKey === ScenarioType.CUSTOM
      ? styles.ScenarioItemCustomContentStyle
      : styles.ScenarioItemContentStyle;

  const fullScenario: FullScenario = useMemo(() => {
    const durationInYears = parseInt(selectedYear as TSFixMe, 10);
    const homeValue = estimate.getPricing()?.getHomeValue() || 0;

    if (scenario.appreciationPerc == null && flags.scenariosTableHomeValueFlagEnabled) {
      const finalHomeValue = parsedCustomValue;

      return {
        ...scenario,
        key: scenarioKey,
        appreciationPerc: 100 * ((finalHomeValue / homeValue) ** (1 / durationInYears) - 1),
        durationInYears,
        appreciationYears: durationInYears,
        homeValue,
        finalHomeValue,
      };
    } else {
      const appreciationPerc = scenario.appreciationPerc ?? parseFloat(customScenarioPerc);
      const appreciationYears = scenario.appreciationIsTotal ? 1 : durationInYears;

      return {
        ...scenario,
        key: scenarioKey,
        appreciationPerc,
        durationInYears,
        appreciationYears,
        homeValue,
        finalHomeValue: estimate.getHomeValueScenario({
          appreciationPerc: appreciationPerc / 100,
          durationInYears: appreciationYears,
        }),
      };
    }
  }, [
    customScenarioPerc,
    estimate,
    flags.scenariosTableHomeValueFlagEnabled,
    parsedCustomValue,
    scenario,
    scenarioKey,
    selectedYear,
  ]);

  const { scenarioMessage, formattedPerc, plainScenarioMessage } = useMemo(() => {
    if (flags.scenariosTableHomeValueFlagEnabled) {
      return {
        scenarioMessage: (
          <TemplatedText
            values={{ amount: currencyMask.getFormatted(fullScenario.finalHomeValue) }}
          >
            {i18n.amountFinalHomeValue}
          </TemplatedText>
        ),
      };
    } else {
      const { appreciationPerc } = fullScenario;

      const i18nValueChangeInfix =
        `${fullScenario.appreciationIsTotal ? 'Total' : 'Annual'}${appreciationPerc < 0 ? 'Decline' : 'Increase'}` as const;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const formattedPerc = percMask.getFormatted(Math.abs(appreciationPerc));
      return {
        scenarioMessage: (
          <QueryComponent
            display={Display.Contents}
            queriedComponent={
              <TemplatedText values={{ perc: formattedPerc }}>
                {i18n[`perc${i18nValueChangeInfix}`]}
              </TemplatedText>
            }
            mobileBreakpoint={Breakpoint.MobileMidBreakpoint}
          >
            <TemplatedText values={{ perc: formattedPerc }}>
              {i18n[`perc${i18nValueChangeInfix}In`]}
            </TemplatedText>
          </QueryComponent>
        ),
        formattedPerc,
        plainScenarioMessage: i18n[`plain${i18nValueChangeInfix}In`],
      };
    }
  }, [flags.scenariosTableHomeValueFlagEnabled, fullScenario]);

  return (
    <styles.ScenarioItemStyle
      product={product}
      scenario={scenarioKey}
      showValues={flags.scenariosTableHomeValueFlagEnabled}
      data-testid={`scenario-${scenarioKey}`}
      {...captureProperties({ table_row: scenarioKey })}
    >
      <Container>
        <ContentComponentStyle hasCustomInputVal={hasInputValue}>
          <styles.ScenarioHeaderColumnStyle scenario={scenarioKey} product={product}>
            <div>
              {scenarioKey === ScenarioType.CUSTOM ? (
                flags.scenariosTableHomeValueFlagEnabled ? (
                  <CustomInputWithLabel
                    key="value"
                    onInputChange={handleCustomValueChange}
                    inputValue={customScenarioValue}
                    hasInputValue={hasInputValue}
                    _ref={customInputRef}
                    scenarioLabel={scenarioLabel}
                    isHomeValue
                  />
                ) : (
                  <CustomInputWithLabel
                    key="perc"
                    onInputChange={handleCustomPercChange}
                    inputValue={customScenarioPerc}
                    hasInputValue={hasInputValue}
                    _ref={customInputRef}
                    scenarioLabel={scenarioLabel}
                  />
                )
              ) : (
                <styles.HeaderContentScenarioLabelStyle>
                  {scenarioLabel}
                  <span>{scenarioMessage}</span>
                </styles.HeaderContentScenarioLabelStyle>
              )}

              <QueryComponent
                mobileBreakpoint={Breakpoint.TabletBreakpoint}
                queriedComponent={
                  <styles.HeaderContentOverStyle>
                    <styles.PercLabelStyle>{scenarioMessage}</styles.PercLabelStyle>
                  </styles.HeaderContentOverStyle>
                }
              >
                {formattedPerc != null && plainScenarioMessage != null && (
                  <styles.HeaderContentOverStyle>
                    <styles.PercNumberStyle isHomeValue={false}>
                      {' '}
                      {formattedPerc}
                    </styles.PercNumberStyle>
                    <styles.PercLabelStyle> {plainScenarioMessage}</styles.PercLabelStyle>
                  </styles.HeaderContentOverStyle>
                )}
              </QueryComponent>

              {scenarioKey !== ScenarioType.CUSTOM && <styles.TableTicksStyle />}
            </div>
          </styles.ScenarioHeaderColumnStyle>
          {scenarioKey === ScenarioType.CUSTOM && !hasInputValue ? (
            <ScenarioCellsCustomPrompt isHomeValue={flags.scenariosTableHomeValueFlagEnabled} />
          ) : (
            <ScenarioCells
              fullScenario={fullScenario}
              estimate={estimate}
              onCalcClick={onCalcClick}
              tooltipHangsDown={!isLastRow}
              showCapTip={showCapTip}
              onToggleCapTip={onToggleCapTip}
              flags={flags}
            />
          )}
        </ContentComponentStyle>
      </Container>
    </styles.ScenarioItemStyle>
  );
};

interface ScenariosTableProps {
  estimate: OfferEstimateModel;
  onCalcClick?: (
    e: React.MouseEvent<HTMLButtonElement>,
    options: { scenario: ScenarioType }
  ) => unknown;
  preTableHeaderText?: null | string;
  product: Products;
  customScenarioPerc: string;
  setCustomScenarioPerc: (newValue: string) => void;
  customScenarioValue: string;
  setCustomScenarioValue: (newValue: string) => void;
  getBuyBackTime: () => string | number;
}

export default function ScenariosTable({
  estimate,
  onCalcClick = () => undefined,
  preTableHeaderText,
  product,
  customScenarioPerc,
  setCustomScenarioPerc,
  customScenarioValue,
  setCustomScenarioValue,
  getBuyBackTime,
}: ScenariosTableProps): JSX.Element {
  const scrollElmContainer = useRef<null | HTMLDivElement>(null);
  const { isMobile } = useIsMobile();
  const yearFormatted = new Date().getFullYear() + parseInt(getBuyBackTime() as TSFixMe, 10);

  const [capTipScenario, setCapTipScenario] = useState<null | ScenarioType>(null);

  const handleToggleCapTip = (scenarioKey: ScenarioType) => {
    setCapTipScenario((currentScenario) => (scenarioKey === currentScenario ? null : scenarioKey));
  };

  const handleClick: React.MouseEventHandler = () => {
    setCapTipScenario(null);
  };

  useEffect(() => {
    const scrollElementContainer = scrollElmContainer.current;

    if (!scrollElementContainer) {
      return;
    }

    if (scrollElementContainer.scrollLeft !== 0 && !isMobile) {
      scrollElementContainer.scrollTo(0, 0);
    }
  }, [isMobile]);

  const [tableRef, flags] = useTableFlags();

  return (
    <>
      <styles.HeaderDescInputPrintContainerStyle onClick={handleClick}>
        <HeaderDescriptionStyle>
          <Header styleAlign={DirectionAndPlacement.Left}>
            <TemplatedText
              values={{
                amount: estimate.getPricing()?.getFormattedOptionInvestmentAmount(),
                year: yearFormatted,
              }}
            >
              {
                estimate
                  ? i18n.homeAppreciationScenariosWithAmount
                  : i18n.homeAppreciationScenarios /* FORK */
              }
            </TemplatedText>
          </Header>
        </HeaderDescriptionStyle>
      </styles.HeaderDescInputPrintContainerStyle>
      {preTableHeaderText && (
        <styles.TablePreHeaderStyle>
          <Container>{preTableHeaderText}</Container>
        </styles.TablePreHeaderStyle>
      )}
      <styles.ScenariosStyle ref={tableRef} onClick={handleClick}>
        <TableHeader />
        <styles.ScenarioItemsContainerStyle
          ref={scrollElmContainer}
          data-testid="ScenarioItemsContainerStyle"
        >
          {scenarioOrder.map((scenarioKey, index) => (
            <ScenarioItem
              key={scenarioKey}
              estimate={estimate}
              scenarioKey={scenarioKey}
              scenario={scenarios[scenarioKey]}
              onCalcClick={onCalcClick}
              product={product}
              customScenarioPerc={customScenarioPerc}
              setCustomScenarioPerc={setCustomScenarioPerc}
              customScenarioValue={customScenarioValue}
              setCustomScenarioValue={setCustomScenarioValue}
              getBuyBackTime={getBuyBackTime}
              isLastRow={index === scenarioOrder.length - 1}
              showCapTip={capTipScenario == null ? null : capTipScenario === scenarioKey}
              onToggleCapTip={() => handleToggleCapTip(scenarioKey)}
              flags={flags}
            />
          ))}
        </styles.ScenarioItemsContainerStyle>
        <TableFooter tableColumns={getTableColumns(flags)} />
      </styles.ScenariosStyle>
    </>
  );
}

export { TableHeader as ScenariosTableHeader };
