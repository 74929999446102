import * as z from "zod";
import * as Underwrite_Core_Pricing from "./Underwrite.Core.Pricing.types";

export enum FollowUpCategory {
  ApplicantFlow = "applicant-flow",
  ApplicantSignatureFlow = "applicant-signature-flow",
  ApplicantSpouseFlow = "applicant-spouse-flow",
  ClosingDisclosureFlow = "closing-disclosure-flow",
  DisclosureNotification = "disclosure-notification",
  EstimateCalculatorFlow = "estimate-calculator-flow",
  EstimateNotification = "estimate-notification",
  EvidenceOfInsuranceFlow = "evidence-of-insurance-flow",
  FinancialCounselingFlow = "acknowledgment-of-heir-flow",
}
export const followUpCategorySchema = z.nativeEnum(FollowUpCategory);

export const promotionDetailsSchema = z
  .object({
    description: z.string(),
    label: z.string(),
    cap_percentage: z.number(),
    promotion_term: z.number(),
  })
  .catchall(z.unknown() as z.ZodType);
export type PromotionDetails = z.infer<typeof promotionDetailsSchema>;

export const debtPayoffItemSchema = z
  .object({
    name: z.string(),
    balance: z.string().regex(/^[-+]?[0-9]+(\.[0-9]+)?([eE][-+]?[0-9]+)?$/),
  })
  .catchall(z.unknown() as z.ZodType);
export type DebtPayoffItem = z.infer<typeof debtPayoffItemSchema>;

export const ownerPaidLabeledValueSchema = z
  .object({
    label: z.string(),
    value: z.number(),
  })
  .catchall(z.unknown() as z.ZodType);
export type OwnerPaidLabeledValue = z.infer<typeof ownerPaidLabeledValueSchema>;

export const ownerPaidValuesSchema = z
  .object({
    appraisal_fee: ownerPaidLabeledValueSchema.optional(),
    assignment_recording_fee: ownerPaidLabeledValueSchema.optional(),
    attorney_fee: ownerPaidLabeledValueSchema.optional(),
    closing_protection_letter_fee: ownerPaidLabeledValueSchema.optional(),
    corrective_deeds_recording_fee: ownerPaidLabeledValueSchema.optional(),
    coversheet_recording_fee: ownerPaidLabeledValueSchema.optional(),
    credit_report_fee: ownerPaidLabeledValueSchema.optional(),
    deed_recording_fee: ownerPaidLabeledValueSchema.optional(),
    document_preparation_fee: ownerPaidLabeledValueSchema.optional(),
    documentary_stamp_tax: ownerPaidLabeledValueSchema.optional(),
    endorsement_fee: ownerPaidLabeledValueSchema.optional(),
    eno_insurance: ownerPaidLabeledValueSchema.optional(),
    escrow_fee: ownerPaidLabeledValueSchema.optional(),
    financial_counseling_fee: ownerPaidLabeledValueSchema.optional(),
    flood_certification_fee: ownerPaidLabeledValueSchema.optional(),
    income_verification_fee: ownerPaidLabeledValueSchema.optional(),
    intangible_tax: ownerPaidLabeledValueSchema.optional(),
    lien_certificate_fee: ownerPaidLabeledValueSchema.optional(),
    lien_certificate_recording_fee: ownerPaidLabeledValueSchema.optional(),
    lien_search_fee: ownerPaidLabeledValueSchema.optional(),
    miscellaneous_fee: ownerPaidLabeledValueSchema.optional(),
    mortgage_tax: ownerPaidLabeledValueSchema.optional(),
    notary_fee: ownerPaidLabeledValueSchema.optional(),
    option_recording_fee: ownerPaidLabeledValueSchema.optional(),
    origination_charge: ownerPaidLabeledValueSchema.optional(),
    origination_fee: ownerPaidLabeledValueSchema.optional(),
    other_costs: ownerPaidLabeledValueSchema.optional(),
    other_recording_fee: ownerPaidLabeledValueSchema.optional(),
    other_title_fee_point: ownerPaidLabeledValueSchema.optional(),
    other_title_fee: ownerPaidLabeledValueSchema.optional(),
    overnight_delivery_fee: ownerPaidLabeledValueSchema.optional(),
    recordation_tax: ownerPaidLabeledValueSchema.optional(),
    recording_process_service_fee: ownerPaidLabeledValueSchema.optional(),
    report_fee: ownerPaidLabeledValueSchema.optional(),
    satisfactions_recording_fee: ownerPaidLabeledValueSchema.optional(),
    scanbacks_fee: ownerPaidLabeledValueSchema.optional(),
    settlement_recording_fee: ownerPaidLabeledValueSchema.optional(),
    tax_service_fee: ownerPaidLabeledValueSchema.optional(),
    third_party_services: ownerPaidLabeledValueSchema.optional(),
    tideland_search_fee: ownerPaidLabeledValueSchema.optional(),
    title_insurance: ownerPaidLabeledValueSchema.optional(),
    title_processing_fee: ownerPaidLabeledValueSchema.optional(),
    total_closing_costs: ownerPaidLabeledValueSchema.optional(),
    total_option_agreement_costs: ownerPaidLabeledValueSchema.optional(),
    wire_transfer_fee: ownerPaidLabeledValueSchema.optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type OwnerPaidValues = z.infer<typeof ownerPaidValuesSchema>;

export enum FollowUpStatus {
  Draft = "draft",
  New = "new",
  Open = "open",
  Submitted = "submitted",
  RequiresReview = "requires_review",
  Closed = "closed",
  Expired = "expired",
  Inactive = "inactive",
}
export const followUpStatusSchema = z.nativeEnum(FollowUpStatus);

export const closingDisclosureFlowFollowUpResponseSchema = z
  .object({
    category: z.literal(FollowUpCategory.ClosingDisclosureFlow),
    success: z.boolean(),
    authenticate: z.literal(true).optional(),
    rejected: z.boolean().optional(),
    estimate: z
      .object({
        appraised_property_value: z.number(),
        cap_percentage: z.number(),
        promotion: promotionDetailsSchema.nullable(),
        cash_to_close: z.number(),
        debt_payoff: z.array(debtPayoffItemSchema).nullable(),
        debt_payoff_amount: z.number(),
        fee_rate: z
          .string()
          .regex(/^[-+]?[0-9]+(\.[0-9]+)?([eE][-+]?[0-9]+)?$/),
        option_percentage: z.number(),
        owner_paid: ownerPaidValuesSchema,
        prepayment: z.number(),
        risk_adjustment: z.number(),
        term: z.number(),
        valid_until: z.string().datetime({ offset: true }),
      })
      .catchall(z.unknown() as z.ZodType)
      .optional(),
    first_name: z.string(),
    last_name: z.string(),
    read_only: z.boolean(),
    csrf_token: z.string(),
    appraised: z.boolean().nullable(),
    status: followUpStatusSchema,
    document_path: z.string().url().nullable(),
    pdf_access_url: z.string().url().nullable(),
  })
  .catchall(z.unknown() as z.ZodType);
export type ClosingDisclosureFlowFollowUpResponse = z.infer<
  typeof closingDisclosureFlowFollowUpResponseSchema
>;

export const updateEstimateCalculatorFlowFollowUpRequestSchema = z
  .object({
    accept: z.literal("true").optional(),
    new_request_amount: z
      .string()
      .regex(/^[-+]?[0-9]+(\.[0-9]+)?([eE][-+]?[0-9]+)?$/)
      .optional(),
    reject: z.literal("true").optional(),
    reject_reason: z.string().optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type UpdateEstimateCalculatorFlowFollowUpRequest = z.infer<
  typeof updateEstimateCalculatorFlowFollowUpRequestSchema
>;

export const estimateCalculatorFlowFollowUpResponseSchema = z
  .object({
    category: z.literal(FollowUpCategory.EstimateCalculatorFlow),
    success: z.boolean(),
    authenticate: z.literal(true).optional(),
    rejected: z.boolean().optional(),
    estimate: z
      .object({
        appraised_property_value: z.number(),
        cap_percentage: z.number(),
        promotion: promotionDetailsSchema.nullable(),
        cash_to_close: z.number(),
        debt_payoff: z.array(debtPayoffItemSchema).nullable(),
        debt_payoff_amount: z.number(),
        fee_rate: z
          .string()
          .regex(/^[-+]?[0-9]+(\.[0-9]+)?([eE][-+]?[0-9]+)?$/),
        option_percentage: z.number(),
        owner_paid: ownerPaidValuesSchema,
        prepayment: z.number(),
        risk_adjustment: z.number(),
        term: z.number(),
        valid_until: z.string().datetime({ offset: true }),
        preliminary: z.boolean(),
        maximum_possible_option_payment: z.number().nullable(),
        stepped_pricing: Underwrite_Core_Pricing.steppedPricingSchema,
      })
      .catchall(z.unknown() as z.ZodType)
      .optional(),
    first_name: z.string(),
    last_name: z.string(),
    read_only: z.boolean(),
    csrf_token: z.string(),
    appraised: z.boolean().nullable(),
    display_borrower_info_doc_acknowledgment: z.boolean(),
    status: followUpStatusSchema,
  })
  .catchall(z.unknown() as z.ZodType);
export type EstimateCalculatorFlowFollowUpResponse = z.infer<
  typeof estimateCalculatorFlowFollowUpResponseSchema
>;

export enum FinancialCounselingChoice {
  Counseling = "counseling",
}
export const financialCounselingChoiceSchema = z.nativeEnum(
  FinancialCounselingChoice,
);

export const financialCounselingFlowResponseSchema = z
  .object({
    category: z.literal(FollowUpCategory.FinancialCounselingFlow),
    success: z.boolean(),
    authenticate: z.literal(true).optional(),
    acknowledgment_of_heir: z
      .object({
        choice: financialCounselingChoiceSchema.nullable(),
      })
      .catchall(z.unknown() as z.ZodType)
      .optional(),
    first_name: z.string(),
    last_name: z.string(),
    read_only: z.boolean(),
    csrf_token: z.string(),
    appraised: z.boolean().nullable(),
    status: followUpStatusSchema,
    document_path: z.string().url().nullable(),
  })
  .catchall(z.unknown() as z.ZodType);
export type FinancialCounselingFlowResponse = z.infer<
  typeof financialCounselingFlowResponseSchema
>;

export const updateFinancialCounselingFlowFollowUpRequestSchema = z
  .object({
    choice: financialCounselingChoiceSchema.optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type UpdateFinancialCounselingFlowFollowUpRequest = z.infer<
  typeof updateFinancialCounselingFlowFollowUpRequestSchema
>;

export const followUpResponseSchema = z.discriminatedUnion("category", [
  closingDisclosureFlowFollowUpResponseSchema,
  estimateCalculatorFlowFollowUpResponseSchema,
  financialCounselingFlowResponseSchema,
]);
export type FollowUpResponse = z.infer<typeof followUpResponseSchema>;
