import React from 'react';
import { Size } from '@pointdotcom/pds';
import { WarningIconProps } from './constants';
import * as styles from './styles';

export default function WarningIcon({
  styleSize = Size.Default,
  strongWarning = false,
}: WarningIconProps) {
  return (
    <styles.WarningIconStyle styleSize={styleSize} strongWarning={strongWarning}>
      !
    </styles.WarningIconStyle>
  );
}
