import React from 'react';
import { Color, Icon, IconName, SplashText, Style, useIsMobile } from '@pointdotcom/pds';
import ChatIcon from 'components/ChatIcon';
import { getDashboardChatAcknowledged, setDashboardChatAcknowledged } from 'store/general';
import { useDispatch, useSelector } from 'store/hooks';
import i18n from './i18n';
import * as styles from './styles';

interface ChatlioButtonProps {
  mode: 'static-light' | 'static-dark' | 'floating';
}

const ChatlioButton = React.forwardRef<HTMLDivElement, ChatlioButtonProps>(({ mode }, ref) => {
  const dispatch = useDispatch();
  const [isConfigured, setIsConfigured] = React.useState(false);
  const chatButtonWasUsed = useSelector(getDashboardChatAcknowledged);
  const { isMobile } = useIsMobile();

  // Chatlio configuration
  if (!isConfigured && window._chatlio?.configure) {
    window._chatlio?.configure({
      agentLabel: isMobile ? i18n.chatlioTitleShort : i18n.chatlioTitle,
    });
    setIsConfigured(true);
  }

  // The Chatlio chip is their little icon which sits in the bottom right corner of the screen
  const isChatExpanded = window._chatlio?.isExpanded ? window._chatlio?.isExpanded() : false;
  const showChatlioChip = isMobile || isChatExpanded || chatButtonWasUsed;
  const floatingModeButtonClosed = mode === 'floating' && showChatlioChip;

  // Open the window in chat mode
  function openChat() {
    window._chatlio?.show({ expanded: true });
    dispatch(setDashboardChatAcknowledged(true));
  }

  // Hide the floating button and just show the chip
  function minimizeFloatingView(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    dispatch(setDashboardChatAcknowledged(true));
  }

  return (
    <>
      <styles.ChatlioWidgetGlobalStyle show={showChatlioChip} />
      {!floatingModeButtonClosed && (
        <styles.ChatButtonStyle
          ref={ref}
          mode={mode}
          styleType={mode === 'static-light' ? Style.Tertiary : Style.Quaternary}
          onClick={openChat}
          aria-label={i18n.startChat}
          block
          onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
            if (e.key === 'Enter' || e.key === ' ') openChat();
          }}
        >
          <ChatIcon />
          <styles.ButtonLabelStyle mode={mode}>
            {mode === 'floating' && (
              <SplashText italic noMargin>
                {i18n.haveQuestions}
              </SplashText>
            )}
            {i18n.startChat}
          </styles.ButtonLabelStyle>
          {mode === 'floating' && (
            <styles.CloseButtonStyle aria-label="close" onClick={minimizeFloatingView}>
              <Icon name={IconName.Close} color={Color.White} styleScale={0.6} />
            </styles.CloseButtonStyle>
          )}
        </styles.ChatButtonStyle>
      )}
    </>
  );
});

export default ChatlioButton;
