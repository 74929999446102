import styled, { css } from 'styled-components';
import {
  Accordion,
  AccordionItem,
  AccordionStyles,
  HeaderStyles,
  Icon,
  IconStyles,
  hover,
  lightenDarkenColor,
  mobileScreen,
  pxToRem,
  rgba,
} from '@pointdotcom/pds';
import { TimelineItemStatus } from 'services/apiTypes/homeownerTypes';
import DashboardSection from '../DashboardSection';

export const FundingTimelineSectionStyle = styled(DashboardSection).attrs({
  className: 'FundingTimelineSectionStyle',
})`
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    background-color: ${({ theme }) => theme.Color.GrayLight1};
  }
`;

export const StatusPillStyle = styled.div.attrs({
  className: 'StatusPillStyle',
})<{ status: TimelineItemStatus }>`
  text-transform: uppercase;
  border-radius: 16px;
  font-size: ${pxToRem(9)};
  font-weight: 900;
  background: ${({ theme, status }) => {
    let color = theme.line.color.default;
    if (status === TimelineItemStatus.InProgress) {
      color = theme.Color.PointBlack;
    }
    if (status === TimelineItemStatus.Completed) {
      color = theme.Color.Gray4;
    }
    return color;
  }};
  color: ${({ theme, status }) =>
    status === TimelineItemStatus.NotStarted
      ? theme.Color.GrayLightestAccessible
      : theme.Color.White};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  letter-spacing: 0.25em;
  white-space: nowrap;
  align-self: center;
  ${mobileScreen} {
    font-size: ${pxToRem(8)};
    padding: 0.4rem 0.7rem;
  }
`;

export const StatusDotStyle = styled(Icon).attrs({
  className: 'StatusDotStyle',
})`
  text-align: center;
  display: block;
  width: var(--statusDotSize);
  height: var(--statusDotSize);
  background: ${({ theme }) => theme.Color.Gray1};
  border-radius: var(--statusDotSize);
  svg {
    position: relative;
    width: 82%;
    height: 82%;
    top: 10%;
  }
  path {
    stroke: ${({ theme }) => theme.Color.Gray4};
  }
  &:after {
    --lineWidth: 3px;
    content: '';
    display: block;
    position: absolute;
    /* margin-top: calc(var(--statusDotSize) + 0.5rem); */
    margin-left: calc(var(--statusDotSize) / 2 - var(--lineWidth) / 2);
    margin-top: 0.5rem;
    width: var(--lineWidth);
    height: calc(100% + var(--stageGap));
    background: ${({ theme }) => theme.Color.Gray1};
  }
`;

export const ItemHeaderStyle = styled.div.attrs({
  className: 'ItemHeaderStyle',
})`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: var(--statusDotHeaderTextGap);
  grid-row-gap: 0.3rem;
  > * {
    &:first-child {
      grid-row: span 2;
    }
    &:last-child {
      font-size: ${pxToRem(14)};
      font-weight: normal;
      color: ${({ theme }) => theme.Color.Gray3};
    }
  }
`;

export const InnerItemHeaderStyle = styled.div.attrs({
  className: 'InnerItemHeaderStyle',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  > * {
    &:first-child {
      flex: 3;
    }
    &:last-child {
      flex: 1;
    }
  }
  ${mobileScreen} {
    > * {
      &:first-child {
        flex: 1.3;
      }
    }
  }
`;

const timelineItemCompletedStyle = css`
  background-color: ${({ theme }) => theme.Color.GrayLight1};
  border-color: transparent;
  ${hover(css`
    background-color: ${({ theme }) => lightenDarkenColor(theme.Color.GrayLight1, -0.01)};
  `)}
  ${mobileScreen} {
    border-color: ${({ theme }) => theme.line.color.default};
  }
`;

const timelineItemCurrentStyle = css`
  box-shadow: 0px 0px 11px 5px ${({ theme }) => rgba(theme.Color.Gray4, 0.11)};
  border-color: transparent;
  ${StatusDotStyle} {
    background: ${({ theme }) => theme.Color.PointBlack};
    svg {
      visibility: hidden;
    }
    &:after {
      top: 100%;
      margin-top: 1px; // account for the border of the accordion items
    }
  }
  ${hover(css`
    border-color: ${({ theme }) => theme.line.color.default};
  `)};
`;

const timelineItemNotStartedStyle = css`
  border-color: ${({ theme }) => theme.line.color.default};
  ${hover(css`
    background-color: ${({ theme }) => lightenDarkenColor(theme.Color.GrayLight1, 0.01)};
  `)};

  ${StatusDotStyle} {
    svg {
      visibility: hidden;
    }
  }
`;

export const TimelineItemStyle = styled(AccordionItem).attrs({
  className: 'TimelineItemStyle',
})<{ status: TimelineItemStatus }>`
  border-width: 1px;
  border-style: solid;
  position: relative;
  border-radius: 3px;
  background-color: white;
  border: 1px solid;
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  aside {
    margin-bottom: 1.8rem;
    margin-right: calc(var(--itemInlinePadding) + 1rem + var(--rowChevronSize));
  }
  button {
    color: inherit;
    > ${IconStyles.IconStyle} {
      color: ${({ theme }) => theme.line.color.default};
    }
  }
  > button {
    padding: var(--itemBlockPadding) var(--itemInlinePadding);
  }
  > ${AccordionStyles.AccordionItemHeaderStyle} {
    font-weight: 700;
  }
  > ${AccordionStyles.AccordionItemContainerStyle} {
    margin-left: calc(
      var(--itemInlinePadding) + var(--statusDotSize) + var(--statusDotHeaderTextGap)
    );
  }
  ${AccordionStyles.AccordionStyle} {
    margin-bottom: 2rem;
    border: 1px solid ${({ theme }) => theme.line.color.default};
    border-left: none;
    border-right: none;
    color: ${({ theme }) => theme.Color.PointBlue};
    button {
      padding-right: var(--itemInlinePadding);
      color: inherit;
      font-size: inherit;
    }
    ${AccordionStyles.AccordionItemContainerStyle} {
      padding-right: calc(var(--itemInlinePadding) + var(--statusDotHeaderTextGap));
      color: ${({ theme }) => theme.Color.GrayLightestAccessible};
    }
  }
  ${({ status }) => status === TimelineItemStatus.Completed && timelineItemCompletedStyle};
  ${({ status }) => status === TimelineItemStatus.InProgress && timelineItemCurrentStyle};
  ${({ status }) => status === TimelineItemStatus.NotStarted && timelineItemNotStartedStyle};
  ${mobileScreen} {
    font-size: ${pxToRem(14)};
  }
`;

export const TimelineAccordionStyle = styled(Accordion).attrs({
  className: 'TimelineAccordionStyle',
})`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  ${TimelineItemStyle} {
    > ${IconStyles.IconStyle} {
      width: var(--rowChevronSize);
      height: var(--rowChevronSize);
    }
  }
`;

export const TimelineStageSectionStyle = styled.div.attrs({
  className: 'TimelineStageSectionStyle',
})`
  &:first-child {
    ${HeaderStyles.HeaderStyle} {
      ${StatusDotStyle} {
        &:after {
          top: 0;
          margin-top: calc(var(--blockPadding) * -1);
        }
        background: none;
        margin-left: 1px; // account for the border of the accordion items
      }
    }
  }
  &:not(:first-child) {
    ${HeaderStyles.HeaderStyle} {
      ${StatusDotStyle} {
        &:after {
          display: none;
        }
      }
    }
  }
  ${HeaderStyles.HeaderStyle} {
    color: ${({ theme }) => theme.Color.Gray3};
    ${StatusDotStyle} {
      background: none;
      > * {
        display: none;
      }
    }
    position: relative;
    --blockPadding: 1rem;
    margin: 0;
    padding-block: var(--blockPadding);
    padding-left: var(--itemInlinePadding);
    > * {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: var(--statusDotHeaderTextGap);
      font-size: ${pxToRem(11)};
    }
  }
  ${mobileScreen} {
    ${HeaderStyles.HeaderStyle} {
      > * {
        font-size: ${pxToRem(10)};
      }
    }
  }
`;

export const TimelineStageWrapperStyle = styled.div.attrs({
  className: 'TimelineStageWrapperStyle',
})`
  --itemInlinePadding: 3rem;
  --itemBlockPadding: 2rem;
  --statusDotSize: ${pxToRem(25)};
  --statusDotHeaderTextGap: 1rem;
  --stageGap: 1rem;
  --rowChevronSize: 1rem;
  display: flex;
  flex-flow: column nowrap;
  gap: var(--stageGap);
  ${mobileScreen} {
    --itemInlinePadding: 1.2rem;
    --itemBlockPadding: 1.5rem;
    --statusDotSize: ${pxToRem(18)};
    --statusDotHeaderTextGap: 0.8rem;
  }
`;
