export default {
  pageTitle: 'Point Tasks',
  subheaderBreadcrumb: 'Tasks',
  yourTasks: 'Your tasks',
  open: 'Open',
  completed: 'Completed',
  weDontNeed:
    'We don’t need anything else from you at the moment, but we may reach out with new requests so check back here often.',
  goBack: 'Go back to your dashboard',
  backTo: 'Back to dashboard',
  openTasks: 'Open tasks',
  upcomingTasks: 'Upcoming tasks',
  submittedTasks: 'Submitted tasks',
  thereAreCurrently: 'There are currently no %taskStatus% tasks',
};
