import * as z from "zod";
import * as Underwrite_Core_Dockets from "./Underwrite.Core.Dockets.types";

export enum TaskStatus {
  New = "New",
  Active = "Active",
  SubmissionInProgress = "SubmissionInProgress",
  Submitted = "Submitted",
  Upcoming = "Upcoming",
}
export const taskStatusSchema = z.nativeEnum(TaskStatus);

/**
 * Verification status of the task
 */
export enum VerificationStatus {
  /**
   * Verification was not started
   */
  NotAttempted = "NotAttempted",
  /**
   * Verification started but no results yet
   */
  InProgress = "InProgress",
  /**
   * Verification finished and deemed the document as invalid
   */
  Invalid = "Invalid",
  /**
   * Verification finished and deemed the document as valid
   */
  Valid = "Valid",
  /**
   * Verification finished and deemed the document as invalid but customer requested a manual review
   */
  Skipped = "Skipped",
}
export const verificationStatusSchema = z.nativeEnum(VerificationStatus);

/**
 * Task verification status together with text representation of the problems if any
 */
export const taskVerificationStatusSchema = z
  .object({
    status: verificationStatusSchema,
    problems: z.array(z.string()).optional(),
    recommendations: z.array(z.string()).optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type TaskVerificationStatus = z.infer<
  typeof taskVerificationStatusSchema
>;

export const taskFileSchema = z
  .object({
    fileId: z.string(),
    fileName: z.string(),
    fileType: z.string(),
    previewUrl: z.string().url().nullable().optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type TaskFile = z.infer<typeof taskFileSchema>;

/**
 * Specifc detail of a direct link task
 */
export const directLinkDetailSchema = z
  .object({
    href: z.string(),
  })
  .catchall(z.unknown() as z.ZodType);
export type DirectLinkDetail = z.infer<typeof directLinkDetailSchema>;

/**
 * Specifc detail of a quiz or product call task
 */
export const quizOrProductCallDetailSchema = z
  .object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    phoneNumber: z.string(),
    callWith: z.string(),
  })
  .catchall(z.unknown() as z.ZodType);
export type QuizOrProductCallDetail = z.infer<
  typeof quizOrProductCallDetailSchema
>;

/**
 * Specifc detail of a customer application
 */
export const applicationDetailSchema = z
  .object({
    estimateKey: z.string(),
    adqStatus: Underwrite_Core_Dockets.automatedDocketQualificationStatusSchema
      .nullable()
      .optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type ApplicationDetail = z.infer<typeof applicationDetailSchema>;

/**
 * Specifc detail of a generic text question
 */
export const genericTextFollowupQuestionDetailSchema = z
  .object({
    requestText: z.string(),
  })
  .catchall(z.unknown() as z.ZodType);
export type GenericTextFollowupQuestionDetail = z.infer<
  typeof genericTextFollowupQuestionDetailSchema
>;

export const documentHelpSchema = z
  .object({
    what: z.array(z.string()),
    where: z
      .object({
        description: z.array(z.string()),
        sources: z.array(
          z
            .object({
              source: z.string(),
              instruction: z.string().optional(),
            })
            .catchall(z.unknown() as z.ZodType),
        ),
      })
      .catchall(z.unknown() as z.ZodType),
    exampleUrl: z.string().url().nullable(),
  })
  .catchall(z.unknown() as z.ZodType);
export type DocumentHelp = z.infer<typeof documentHelpSchema>;

/**
 * Specifc detail of a generic document request followup question
 */
export const genericDocumentFollowupQuestionDetailSchema =
  genericTextFollowupQuestionDetailSchema.extend({
    documentCategory: z.string().nullable(),
    verifiable: z.boolean().optional(),
    verification: taskVerificationStatusSchema.optional(),
    help: documentHelpSchema.optional(),
    hideNotes: z.boolean().optional(),
  });
export type GenericDocumentFollowupQuestionDetail = z.infer<
  typeof genericDocumentFollowupQuestionDetailSchema
>;

export const documentUploadSectionSchema = z
  .object({
    documentCategory: z.string(),
    isCompleted: z.boolean(),
    isRequired: z.boolean(),
    title: z.string(),
    help: documentHelpSchema.optional(),
    files: z.array(taskFileSchema),
  })
  .catchall(z.unknown() as z.ZodType);
export type DocumentUploadSection = z.infer<typeof documentUploadSectionSchema>;

export const documentUploadDetailSchema = z
  .object({
    sections: z.array(documentUploadSectionSchema),
  })
  .catchall(z.unknown() as z.ZodType);
export type DocumentUploadDetail = z.infer<typeof documentUploadDetailSchema>;

export const taskSubmissionErrorSchema = z
  .object({
    id: z.string(),
    dateTime: z.string().datetime({ offset: true }).optional(),
    message: z.string().optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type TaskSubmissionError = z.infer<typeof taskSubmissionErrorSchema>;

export enum TaskType {
  GenericTextFollowupQuestion = "GenericTextFollowupQuestion",
  GenericDocumentFollowupQuestion = "GenericDocumentFollowupQuestion",
  DirectLink = "DirectLink",
  QuizOrProductCall = "QuizOrProductCall",
  Application = "Application",
  ReadOnly = "ReadOnly",
  DocumentUpload = "DocumentUpload",
  /**
   * Not used directly, but is to break assumption that all task types are known
   */
  Unknown = "Unknown",
}
export const taskTypeSchema = z.nativeEnum(TaskType);

export const baseTaskSchema = z
  .object({
    id: z.string(),
    type: taskTypeSchema,
    title: z.string().optional(),
    taskDescription: z.string(),
    status: taskStatusSchema,
    submittedAt: z.string().datetime({ offset: true }).nullable(),
    files: z.array(taskFileSchema),
    fallbackHref: z.string().optional(),
    submissionErrors: z.array(taskSubmissionErrorSchema).optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type BaseTask = z.infer<typeof baseTaskSchema>;

export enum VerificationRequestType {
  ManualReviewRequested = "MANUAL_REVIEW_REQUESTED",
  NotVerifiable = "NOT_VERIFIABLE",
  VerificationFlagOff = "VERIFICATION_FLAG_OFF",
  VerificationRequested = "VERIFICATION_REQUESTED",
}
export const verificationRequestTypeSchema = z.nativeEnum(
  VerificationRequestType,
);

/**
 * HO response for the assigned task
 */
export const taskSubmitRequestSchema = z
  .object({
    notes: z.string().optional(),
    skipVerification: z.boolean().optional(),
    verificationRequest: verificationRequestTypeSchema.optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type TaskSubmitRequest = z.infer<typeof taskSubmitRequestSchema>;

export const taskSubmissionStatusSchema = z
  .object({
    taskStatus: taskStatusSchema,
    verification: taskVerificationStatusSchema.optional(),
    submissionErrors: z.array(taskSubmissionErrorSchema).optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type TaskSubmissionStatus = z.infer<typeof taskSubmissionStatusSchema>;

/**
 * HO request for assitance with a task
 */
export const taskHelpRequestSchema = z
  .object({
    homeownerQuestion: z.string(),
    homeownerContactInfo: z
      .object({
        name: z.string(),
        email: z.string().email(),
      })
      .catchall(z.unknown() as z.ZodType),
  })
  .catchall(z.unknown() as z.ZodType);
export type TaskHelpRequest = z.infer<typeof taskHelpRequestSchema>;

/**
 * HO response for upload a file
 */
export const taskFileUploadSuccessResponseSchema = taskFileSchema;
export type TaskFileUploadSuccessResponse = z.infer<
  typeof taskFileUploadSuccessResponseSchema
>;

/**
 * A task assigned to the homeowner
 */
export const taskSchema = z.discriminatedUnion("type", [
  baseTaskSchema.extend({
    type: z.literal(TaskType.GenericTextFollowupQuestion),
    detail: genericTextFollowupQuestionDetailSchema,
  }),
  baseTaskSchema.extend({
    type: z.literal(TaskType.GenericDocumentFollowupQuestion),
    detail: genericDocumentFollowupQuestionDetailSchema,
  }),
  baseTaskSchema.extend({
    type: z.literal(TaskType.QuizOrProductCall),
    detail: quizOrProductCallDetailSchema,
  }),
  baseTaskSchema.extend({
    type: z.literal(TaskType.DirectLink),
    detail: directLinkDetailSchema,
  }),
  baseTaskSchema.extend({
    type: z.literal(TaskType.Application),
    detail: applicationDetailSchema,
  }),
  baseTaskSchema.extend({
    type: z.literal(TaskType.ReadOnly),
    detail: z.object({}).catchall(z.unknown() as z.ZodType),
  }),
  baseTaskSchema.extend({
    type: z.literal(TaskType.DocumentUpload),
    detail: documentUploadDetailSchema,
  }),
  baseTaskSchema.extend({
    type: z.literal(TaskType.Unknown),
    detail: z.object({}).catchall(z.unknown() as z.ZodType),
  }),
]);
export type Task = z.infer<typeof taskSchema>;
