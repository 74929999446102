import React from 'react';
import Helmet from 'react-helmet';
import { useLocation, useParams } from 'react-router';
import { Container, DirectionAndPlacement, Header, Logo, Size } from '@pointdotcom/pds';
import MainFooter from 'components/MainFooter';
import ScheduleCalendar, { getYCBMParamsFromURLSearchParams } from 'components/ScheduleCalendar';
import { getURLParam } from 'containers/helpers';
import { useProduct } from 'containers/prequal/hooks';
import { useCalendar } from 'containers/prequal/hooks/useCalendar';
import * as appStyles from 'styles/';
import i18n from './i18n';
import * as styles from './styles';

export default function SchedulePage() {
  const { product } = useProduct();
  const { calendar } = useParams();
  const estimateKey = getURLParam('ESTIMATEID');

  // Is this for a specific state?
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  let forStateAbbr = '';
  if (params.get('property_state')) {
    forStateAbbr = params.get('property_state');
  }

  const { calendarURL } = useCalendar({ product, calendar, forStateAbbr });
  const ycbmParams = getYCBMParamsFromURLSearchParams() || {};

  if (!ycbmParams.team) {
    ycbmParams.team = 'any';
  }

  if (estimateKey) {
    ycbmParams.ESTIMATEID = estimateKey;
  }

  const iframeParams = new URLSearchParams(ycbmParams).toString();

  return (
    <styles.SchedulePageStyle>
      <Helmet title={i18n[`${product}PageTitle`]} />
      <appStyles.HeroContainerStyle>
        <Container>
          <Logo />
          <Header
            styleSize={Size.Splash}
            styleAlign={DirectionAndPlacement.Center}
            maxWidth="800px"
          >
            {i18n.scheduleATime}
          </Header>
          <Header
            styleSize={Size.Medium}
            styleAlign={DirectionAndPlacement.Center}
            maxWidth="800px"
          >
            {i18n.clickOnAny}
          </Header>
          <ScheduleCalendar iframeParams={iframeParams} calendarURL={calendarURL} />
        </Container>
      </appStyles.HeroContainerStyle>
      <MainFooter />
    </styles.SchedulePageStyle>
  );
}
