export default {
  homeValueAlert: 'Home value alert',
  zillowIsAn:
    '*Zillow is an online real estate database company that gathers and analyzes publicly available information about real estate values.',
  theValueYou:
    'The value you entered is about %amount% %higherLower% than the value we estimated%sup%',
  theValueYouZillow:
    'The value you entered is about %amount% %higherLower% than the value Zillow%sup% estimates',

  adjustValue: 'Adjust value',
  useMyValue: 'Use my value',
  ourEstimate:
    '*Our estimate is based on publicly available information about real estate values. It does not take into account the condition of your home or other private information.',
  pleaseExplain: 'Please explain',
  whenWeCalculate:
    'When we calculate your initial estimated offer, we’ll use the home value you entered. Later in the process, an independent third-party appraisal of your home will help determine your final offer and pricing which may be different than the initial estimated offer. ',
  toProceed:
    'To proceed with your estimated home value, please explain why you think your home will appraise for the amount you entered: ',
};
