import React from 'react';
import {
  Button,
  DirectionAndPlacement,
  FormChangeEvent,
  Header,
  HelpText,
  MarkText,
  ModalProps,
  Size,
  Style,
  TemplatedText,
  TextArea,
} from '@pointdotcom/pds';
import WarningIcon from 'components/WarningIcon';
import { useDispatch } from 'store/hooks';
import { answerHomeValueExplanation } from 'store/property';
import i18n from './i18n';
import * as styles from './styles';

interface ConfirmationModalProps extends ModalProps {
  isOpen: boolean;
  onModalClose: () => void;
  onSubmit: (e: React.FormEvent) => void;
  percDiff: number;
  loading: boolean;
  avmIsFromZillow: boolean;
}

export default function ({
  onSubmit,
  percDiff,
  loading,
  avmIsFromZillow,
  ...modalProps
}: ConfirmationModalProps) {
  const avmDescription = avmIsFromZillow ? i18n.theValueYouZillow : i18n.theValueYou;
  const helperText = avmIsFromZillow ? i18n.zillowIsAn : i18n.ourEstimate;
  const [explanation, setExplanation] = React.useState('');
  const dispatch = useDispatch();
  const upperHeader = (
    <styles.ModalUpperHeaderStyle>
      <Header italic noMargin>
        <WarningIcon styleSize={Size.Small} />
        {i18n.homeValueAlert}
      </Header>
    </styles.ModalUpperHeaderStyle>
  );
  const handleChange: FormChangeEvent = (e, { value }) => {
    setExplanation(value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(answerHomeValueExplanation(explanation));
    onSubmit(e);
  };

  return (
    <styles.ConfirmDescribeValueModalStyle upperHeader={upperHeader} {...modalProps}>
      <styles.ZestimateFormLayoutStyle>
        <Header maxWidth="95%">
          <TemplatedText
            values={{
              amount: `${Math.round(Math.abs(percDiff))}%`,
              higherLower: <MarkText>{percDiff < 0 ? 'lower' : 'higher'}</MarkText>,
              sup: <sup>*</sup>,
            }}
          >
            {avmDescription}
          </TemplatedText>
        </Header>
        <p>{i18n.whenWeCalculate}</p>
        <p>{i18n.toProceed}</p>
        <form onSubmit={handleSubmit}>
          <TextArea placeholder={i18n.pleaseExplain} value={explanation} onChange={handleChange} />
          <HelpText>{helperText}</HelpText>
          <styles.ButtonRowStyle>
            <Button
              type="button"
              content={i18n.adjustValue}
              styleType={Style.Tertiary}
              onClick={modalProps.onModalClose}
              block
            />
            <Button
              type="submit"
              content={i18n.useMyValue}
              loading={loading}
              styleAlign={DirectionAndPlacement.Right}
              disabled={!explanation}
              block
            />
          </styles.ButtonRowStyle>
        </form>
      </styles.ZestimateFormLayoutStyle>
    </styles.ConfirmDescribeValueModalStyle>
  );
}
