import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { useProduct } from 'containers/prequal/hooks/useProduct';
import { useSelector } from 'store/hooks';

/**
 * Redirect the user to the beginning of the appropriate prequal flow
 * if they haven't already completed the first step, e.g. maybe followed a link
 * to a page in the middle of the flow.
 */
export const useRedirectToBeginning = () => {
  const { product } = useProduct();
  const navigate = useNavigate();
  const location = useLocation();

  const streetAddress = useSelector((state) => state.property.address?.streetAddress);
  const currentRoute = location.pathname;
  const startRoute = generateUrlFromPage(pages.PREQUAL_START, { product });

  useEffect(() => {
    if (currentRoute === startRoute) return;
    if (streetAddress) return;

    // wait for a brief pause in case we are navigating to a new page and then check if we still need the redirect
    const timeoutId = setTimeout(() => {
      const routeAfterPause = window.location.pathname;
      if (routeAfterPause === currentRoute) {
        navigate(startRoute, { replace: true });
      }
    });

    return () => clearTimeout(timeoutId);
  }, [navigate, currentRoute, streetAddress, startRoute]);
};
