import * as React from 'react';
import { Navigate, RouteObject, useParams, useSearchParams } from 'react-router';
import { PageRouteMap, PageRouteMapItem, generateUrl } from 'containers/helpers';

interface NavigateFromLegacyProps {
  routeMapItem: PageRouteMapItem;
}

function NavigateFromLegacyPath({ routeMapItem }: NavigateFromLegacyProps) {
  const routeParams = useParams();
  const [searchParams] = useSearchParams();

  return (
    <Navigate
      to={generateUrl(routeMapItem.path, routeParams, Object.fromEntries(searchParams.entries()))}
      replace
    />
  );
}

export function redirectLegacyRoutes(pageRouteMap: PageRouteMap): RouteObject[] {
  return Object.entries(pageRouteMap).flatMap(([, routeMapItem]) =>
    (routeMapItem.legacyPaths ?? []).map(
      (legacyPath): RouteObject => ({
        path: legacyPath,
        element: <NavigateFromLegacyPath routeMapItem={routeMapItem} />,
      })
    )
  );
}
