import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import DashboardModel from 'models/DashboardModel';
import { useDashboardQuery, useLazyDashboardQuery } from 'services/api/homeownerApi';
import { TaskType } from 'services/apiTypes/taskTypes';
import { AutomatedDocketQualificationStatus } from 'services/apiTypes/underwriteTypes';

function useDashboardRequest() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const personId = params.get('person_id');
  const personType = params.get('person_type');

  return useMemo(() => ({ personId, personType }), [personId, personType]);
}

const initialPollingInterval = 300;

export function useDashboardData() {
  const dashboardRequest = useDashboardRequest();

  const { data: dashboard, isLoading, isError, refetch } = useDashboardQuery(dashboardRequest);

  const dashboardModel = useMemo(
    () => (dashboard ? new DashboardModel(dashboard) : null),
    [dashboard]
  );

  const applicationTask = dashboardModel?.getTask({ type: TaskType.Application });
  const isWaitingForApplicationTask =
    dashboardModel?.application?.estimateKey != null && applicationTask == null;

  const isADQProcessingNow = useMemo(
    () =>
      dashboardModel
        ?.getTasks({ type: TaskType.Application })
        .some((task) => task.detail.adqStatus === AutomatedDocketQualificationStatus.Processing) ??
      false,
    [dashboardModel]
  );

  const isProcessingNow = isWaitingForApplicationTask || isADQProcessingNow;

  useEffect(() => {
    if (isProcessingNow) {
      let currentPollingInterval = initialPollingInterval;
      let timeoutId: undefined | NodeJS.Timeout;
      const doRefetch = () => {
        currentPollingInterval = Math.floor(currentPollingInterval * 1.125);
        refetch();
        timeoutId = setTimeout(doRefetch, currentPollingInterval);
      };
      timeoutId = setTimeout(doRefetch, currentPollingInterval);

      return () => clearTimeout(timeoutId);
    }
  }, [isProcessingNow, refetch]);

  return { dashboard: dashboardModel, isLoading, isError, refetch };
}

type UseLazyDashboardQueryReturnType = ReturnType<typeof useLazyDashboardQuery>;
type DashboardQueryTriggerType = UseLazyDashboardQueryReturnType[0];
type DashboardQueryTriggerReturnType = Awaited<ReturnType<DashboardQueryTriggerType>>;

export function useLazyDashboardData(): [
  getDashboard: (preferCacheValue?: boolean) => Promise<
    Omit<DashboardQueryTriggerReturnType, 'data'> & {
      dashboard: null | DashboardModel;
    }
  >,
  result: UseLazyDashboardQueryReturnType[1],
  lastPromiseInfo: UseLazyDashboardQueryReturnType[2],
] {
  const dashboardRequest = useDashboardRequest();
  const [getDashboard, result, lastPromiseInfo] = useLazyDashboardQuery();

  const getDashboardModel = useCallback(
    async (preferCacheValue?: undefined | boolean) => {
      const { data: dashboard, ...otherValues } = await getDashboard(
        dashboardRequest,
        preferCacheValue
      );

      const dashboardModel = dashboard ? new DashboardModel(dashboard) : null;

      return { dashboard: dashboardModel, ...otherValues };
    },
    [dashboardRequest, getDashboard]
  );

  return [getDashboardModel, result, lastPromiseInfo];
}
