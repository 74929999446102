import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';
import {
  Accordion,
  AccordionItem,
  AccordionStyles,
  HeaderStyles,
  IconStyles,
  hover,
  lightenDarkenColor,
  mobileScreen,
  pxToRem,
} from '@pointdotcom/pds';

export const BannerContentStyle = styled(AccordionItem).attrs({
  className: 'BannerContentStyle',
})``;

export const ANIM_SPEED_MS = 200;
export const BannerCollapsableStyle = styled(Accordion).attrs({
  className: 'BannerCollapsableStyle',
})<{ isExpanded?: boolean; isVisible?: boolean; animationStatus: TransitionStatus }>`
  --borderRadius: 3px;
  transition: all ${ANIM_SPEED_MS}ms ease-in-out;
  opacity: ${({ isVisible, animationStatus }) =>
    isVisible && animationStatus === 'entered' ? 1 : 0};
  position: fixed;
  top: 66px;
  left: 50%;
  width: 100%;
  max-width: 1000px;
  transform: translateX(-50%)
    translateY(
      ${({ isVisible, animationStatus }) =>
        isVisible && animationStatus === 'entered' ? '10px' : '0'}
    );
  z-index: 5;
  --accordionLeftRightPadding: 1.2rem;
  --accordionTopBottomPadding: 0.8rem;
  background: ${({ theme, isExpanded }) =>
    isExpanded ? theme.Color.PurpleBlue6 : theme.Color.Gray4};
  color: white;
  border-radius: var(--borderRadius);
  button {
    border-radius: var(--borderRadius);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
    overflow: hidden;
    ${hover(css<{ isExpanded?: boolean }>`
      background: ${({ theme, isExpanded }) =>
        isExpanded
          ? lightenDarkenColor(theme.Color.PurpleBlue6, -0.03)
          : lightenDarkenColor(theme.Color.Gray4, -0.04)};
    `)}
    &:before {
      position: absolute;
      content: '';
      display: ${({ isExpanded }) => (isExpanded ? 'block' : 'none')};
      top: 0;
      left: 0;
      width: 100px;
      height: 100px;
      background: ${({ theme }) => theme.Color.PurpleBlue6};
      filter: blur(20px);
      z-index: 0;
    }
    &:after {
      content: '';
      display: ${({ isExpanded }) => (isExpanded ? 'block' : 'none')};
      position: absolute;
      bottom: 0;
      right: 0;
      width: calc(100% - var(--accordionLeftRightPadding));
      height: 1px;
      background: ${({ theme }) => theme.line.color.invertedSubtle};
    }
  }
  button,
  ${AccordionStyles.AccordionItemContainerStyle} {
    color: inherit;
  }
  ${AccordionStyles.AccordionItemHeaderStyle} {
    > span {
      display: block;
      max-width: 90%;
    }
    > ${IconStyles.IconStyle} {
      flex-shrink: 0;
    }
  }
  @media (max-width: 1200px) {
    width: 98%;
  }
`;

export const BannerHeaderStyle = styled.div.attrs({
  className: 'BannerHeaderStyle',
})<{ isExpanded?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1rem;
  position: relative;
  z-index: 1;
  ${IconStyles.IconStyle} {
    flex-shrink: 0;
    width: ${pxToRem(19)};
    path {
      fill: ${({ theme }) => theme.line.color.invertedSubtle};
    }
  }
  ${HeaderStyles.HeaderStyle} {
    flex-shrink: 0;
    > * {
      font-size: ${pxToRem(12)};
    }
  }
  > span {
    transition: all 250ms ease-in-out;
    flex: 1;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
    margin-left: 2rem;
    opacity: ${({ isExpanded }) => (isExpanded ? 0 : 0.6)};
  }
  ${mobileScreen} {
    > span {
      display: none;
    }
  }
`;

export const BannerExpandedContentStyle = styled.div.attrs({
  className: 'BannerExpandedContentStyle',
})`
  margin-top: var(--accordionTopBottomPadding);

  & + & {
    padding-top: var(--accordionTopBottomPadding);
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + var(--accordionLeftRightPadding));
      height: 1px;
      background: ${({ theme }) => theme.line.color.invertedSubtle};
    }
  }
`;
