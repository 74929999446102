export default {
  zillowIsAn:
    '*Zillow is an online real estate database company that gathers and analyzes publicly available information about real estate values.',
  theValueYouZillow:
    'The value you entered is about %amount% %higherLower% than the value Zillow* estimates. When we calculate your initial estimated offer, weʼll use the home value you entered.',
  theValueYou:
    'The value you entered is about %amount% %higherLower% than the value we estimated.* When we calculate your initial estimated offer, weʼll use the home value you entered.',
  adjustValue: 'Adjust value',
  useMyValue: 'Use my value',
  ourEstimate:
    '*Our estimate is based on publicly available information about real estate values. It does not take into account the condition of your home or other private information.',
  laterIn:
    'Later in the process, your property value will be determined using independent third-party reports including an in-person appraisal of your home. So itʼs possible youʼll see your offer change.',
};
