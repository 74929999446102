import { RefObject, useEffect, useState } from 'react';

export const useIsTextTruncated = (ref: RefObject<HTMLElement>) => {
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      if (ref.current) {
        setIsTruncated(ref.current.scrollWidth > ref.current.clientWidth);
      }
    };

    checkTruncation();
    window.addEventListener('resize', checkTruncation);

    return () => window.removeEventListener('resize', checkTruncation);
  }, [ref]);

  return isTruncated;
};
