import styled from 'styled-components';
import { ContainerStyles, mobileScreen, pxToRem } from '@pointdotcom/pds';
import { BreadCrumbsStyle } from 'components/Breadcrumbs/styles';
import { SubNavBaseStyle, SubNavStyle } from 'components/SubNav/styles';
import { SubHeaderStyleAlign } from '../index';

export const FollowUpSubNavStyle = styled.div.attrs({ className: 'FollowUpSubNavStyle' })`
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    text-align: center;
  }
`;

export const TextContentStyle = styled.span.attrs({ className: 'TextContentStyle' })`
  flex: 1;
`;

export const SubHeaderStyle = styled.div.attrs({ className: 'SubHeaderStyle' })<{
  styleAlign: SubHeaderStyleAlign;
  hideOnMobile?: boolean;
}>`
  background: white;
  text-align: ${(props) => props.styleAlign};
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.line.color.default};
  color: ${(props) => props.theme.Color.GrayLightestAccessible};
  font-size: ${pxToRem(14)};
  a {
    text-decoration: none;
    display: inline-block;
    color: inherit;
    &:hover {
      color: ${(props) => props.theme.Color.Gray4};
    }
  }
  ${SubNavBaseStyle} {
    a {
      ${({ theme }) => theme.globals.linkInvertedCSS}
    }
  }
  ${TextContentStyle} {
    display: inline-block;
    width: 100%;
    text-align: right;
  }
  ${ContainerStyles.ContainerStyle} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: ${pxToRem(59)};

    > *:only-child:not(${BreadCrumbsStyle}) {
      margin-left: auto;
    }
  }
  ${BreadCrumbsStyle} {
    overflow-x: auto;
  }
  ${mobileScreen} {
    font-size: ${pxToRem(13)};
    text-align: center;
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'block')};
    a {
      color: ${(props) => props.theme.link.color.default};
    }
    ${ContainerStyles.ContainerStyle} {
      min-height: ${pxToRem(45)};
      justify-content: center;
      > * {
        margin: 0 auto;
      }
    }
    ${TextContentStyle} {
      text-align: center;
    }
    ${BreadCrumbsStyle} {
      width: 100%;
      margin: 0 auto;
      justify-content: flex-start;
    }
    ${SubNavStyle} {
      display: none;
    }
  }
  @media only print {
    position: absolute;
    border: none;
    text-align: right;
    top: 10px;
  }
`;
