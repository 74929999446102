import React from 'react';
import Helmet from 'react-helmet';
import { Container, Header, Size, SplashText, TemplatedText } from '@pointdotcom/pds';
import DashboardMainHeader from 'components/DashboardMainHeader';
import { Hr } from 'components/HrVr';
import MainFooter from 'components/MainFooter';
import DashboardModel from 'models/DashboardModel';
import { TaskStatus } from 'services/apiTypes/taskTypes';
import DashboardTasks from '../../DashboardTasks';
import i18n from './i18n';
import * as styles from './styles';

function NoTasksInSection({ taskStatus }: { taskStatus: TaskStatus }) {
  return (
    <styles.NoTasksInSectionStyle>
      <TemplatedText values={{ taskStatus: taskStatus.toLowerCase() }}>
        {i18n.thereAreCurrently}
      </TemplatedText>
    </styles.NoTasksInSectionStyle>
  );
}

function NoTasksAtAll() {
  return (
    <styles.NoActiveTasksStyle>
      <Container>
        <SplashText>{i18n.weDontNeed}</SplashText>
      </Container>
    </styles.NoActiveTasksStyle>
  );
}

const RevisedDashboardTasksListPage = ({ dashboard }: { dashboard: DashboardModel }) => {
  const openTasks = dashboard.getTasks({
    status: [TaskStatus.Active, TaskStatus.SubmissionInProgress],
  });
  const upcomingTasks = dashboard.getTasks({ status: TaskStatus.Upcoming });
  const completedTasks = dashboard.getTasks({ status: TaskStatus.Submitted });
  const hasOpenItems = openTasks.length > 0;
  const hasUpcomingItems = upcomingTasks.length > 0;
  const hasCompletedItems = completedTasks.length > 0;
  const hasAnyItems = hasOpenItems || hasCompletedItems || hasUpcomingItems;
  const pageGutters = Size.Small;

  return (
    <>
      <styles.DashboardTasksPageStyle>
        <Helmet title={i18n.pageTitle} />
        <DashboardMainHeader
          dashboard={dashboard}
          crumbProps={{ currentCrumb: i18n.subheaderBreadcrumb }}
        />
        <styles.TaskPageHeroStyle>
          <Container>
            <Header styleSize={Size.Large} noMargin>
              {i18n.yourTasks}
            </Header>
          </Container>
        </styles.TaskPageHeroStyle>

        <styles.PageContentStyle>
          {!hasAnyItems && <NoTasksAtAll />}
          <Container styleGutterSize={pageGutters}>
            {/* Open tasks */}
            <styles.TasksSectionStyle headerText={i18n.openTasks}>
              {hasOpenItems ? (
                <DashboardTasks tasks={openTasks} />
              ) : (
                <NoTasksInSection taskStatus={TaskStatus.Active} />
              )}
            </styles.TasksSectionStyle>
            <Hr />

            {/* Upcoming tasks */}
            <styles.TasksSectionStyle headerText={i18n.upcomingTasks}>
              {hasUpcomingItems ? (
                <DashboardTasks tasks={upcomingTasks} />
              ) : (
                <NoTasksInSection taskStatus={TaskStatus.Upcoming} />
              )}
            </styles.TasksSectionStyle>

            <Hr />

            {/* Submitted tasks */}
            <styles.TasksSectionStyle headerText={i18n.submittedTasks}>
              {hasCompletedItems ? (
                <DashboardTasks tasks={completedTasks} mobileShadowed={false} />
              ) : (
                <NoTasksInSection taskStatus={TaskStatus.Submitted} />
              )}
            </styles.TasksSectionStyle>
          </Container>
        </styles.PageContentStyle>
      </styles.DashboardTasksPageStyle>
      <MainFooter />
    </>
  );
};

export default RevisedDashboardTasksListPage;
