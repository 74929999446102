export default {
  yourInitial: 'Your Initial Offer',
  yourEstimated: 'Your %estimated% funding%nbsp%date:',
  estimated: 'estimated',
  thisIsAnEstimateOnly:
    '%thisIsAnEstimateOnlyHighlight% and the exact date may change based on your appraisal, any legal or ownership issues that may be found, or other factors specific to your home and finances.',
  asWeLearn:
    'As we learn more, we will notify you of any changes and update your funding timeline here.',
  thisIsAnEstimateOnlyHighlight: 'This is an estimate only',
  close: 'Close',
};
