import * as z from "zod";
import * as Underwrite_Core_Dockets from "./Underwrite.Core.Dockets.types";
import * as Common from "./Common.types";
import * as Underwrite_Core_FollowUps from "./Underwrite.Core.FollowUps.types";
import * as Underwrite from "./Underwrite.types";

export const completedTaskSchema = z
  .object({
    id: z.number().int(),
    description: z.string(),
    date: z.string().datetime({ offset: true }).nullable(),
  })
  .catchall(z.unknown() as z.ZodType);
export type CompletedTask = z.infer<typeof completedTaskSchema>;

export const docketStageStepSchema = z
  .object({
    id: z.string(),
    description: z.string(),
  })
  .catchall(z.unknown() as z.ZodType);
export type DocketStageStep = z.infer<typeof docketStageStepSchema>;

export const docketStageItemSchema = z
  .object({
    name: Underwrite_Core_Dockets.docketStageSchema,
    title: z.string(),
    description: z.string(),
    steps: z.array(docketStageStepSchema),
    completed_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable(),
  })
  .catchall(z.unknown() as z.ZodType);
export type DocketStageItem = z.infer<typeof docketStageItemSchema>;

export const dashboardPersonSchema = z
  .object({
    first_name: z.string(),
    last_name: z.string(),
    primary_email: z.string(),
  })
  .catchall(z.unknown() as z.ZodType);
export type DashboardPerson = z.infer<typeof dashboardPersonSchema>;

export const docketOfferSchema = z
  .object({
    cap_percentage: z.string(),
    appraised_property_value: z.string(),
    option_investment_amount: z.string(),
    option_percentage: z.string(),
    origination_fee_rate: z.string(),
    risk_adjustment: z.string(),
    term: z.number(),
  })
  .catchall(z.unknown() as z.ZodType);
export type DocketOffer = z.infer<typeof docketOfferSchema>;

export const docketSchema = z
  .object({
    id: z.number().nullable().optional(),
    status: z.string().nullable().optional(),
    funded: z.boolean().optional(),
    latest_offer: docketOfferSchema.nullable().optional(),
    accepted_offer: docketOfferSchema.nullable().optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type Docket = z.infer<typeof docketSchema>;

export const dashboardApplicationSchema = z
  .object({
    status: Common.applicationStatusSchema.optional(),
    estimateKey: z.string().optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type DashboardApplication = z.infer<typeof dashboardApplicationSchema>;

export const dashboardErrorResponseSchema = z
  .object({
    success: z.literal(false),
    message: z.string(),
    authenticate: z.boolean(),
    read_only: z.boolean(),
    docket: docketSchema,
  })
  .catchall(z.unknown() as z.ZodType);
export type DashboardErrorResponse = z.infer<
  typeof dashboardErrorResponseSchema
>;

export const docketsListSchema = z
  .object({
    dockets: z.array(z.number()),
  })
  .catchall(z.unknown() as z.ZodType);
export type DocketsList = z.infer<typeof docketsListSchema>;

export const getDocketByEmailResponseSchema = z
  .object({
    success: z.boolean().optional(),
    docketKey: z.string(),
    status: Underwrite_Core_Dockets.docketStatusSchema,
    stage: Underwrite_Core_Dockets.docketStageSchema,
    isFunded: z.boolean(),
  })
  .catchall(z.unknown() as z.ZodType);
export type GetDocketByEmailResponse = z.infer<
  typeof getDocketByEmailResponseSchema
>;

export const submitResponseSchema = z
  .object({
    success: z.boolean(),
    invalid_fields: z.array(z.array(z.string())).optional(),
    validation_errors: z.record(z.string(), z.string()).optional(),
    message: z.string().optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type SubmitResponse = z.infer<typeof submitResponseSchema>;

export const docketTimelineRawDataSchema = z
  .object({
    application_opened_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    application_submitted_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    adq_passed_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    docket_marked_ready_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    product_call_scheduled_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    product_call_completed_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    identity_delivered_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    estimate_sent_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    estimate_accepted_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    closing_costs_worksheet_finalized_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    has_mortgage: z.boolean().nullable().optional(),
    mortgage_fact_sheet_finalized_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    appraisal_ordered_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    appraisal_reviewed_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    title_ordered_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    title_cleared_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    title_grade: z.number().int().nullable().optional(),
    preliminary_property_eoi_follow_up_opened_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    preliminary_property_eoi_reviewed_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    flood_eoi_required: z.boolean().nullable().optional(),
    flood_eoi_follow_up_opened_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    flood_eoi_reviewed_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    final_offer_issued_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    final_offer_verified_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    docket_cleared_to_close_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    property_eoi_delivered_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    property_eoi_reviewed_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    closing_disclosure_issued_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    closing_disclosure_accepted_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    closing_call_scheduled_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    closing_call_completed_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    signing_appointment_scheduled_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    signing_appointment_completed_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    funds_disbursed_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    signing_documents_uploaded_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    docket_closed_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
    qa_review_completed_at: z
      .union([z.string().date(), z.string().datetime({ offset: true })])
      .nullable()
      .optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type DocketTimelineRawData = z.infer<typeof docketTimelineRawDataSchema>;

export const activeTaskSchema = z
  .object({
    id: z.number().int(),
    title: z.string(),
    name: z.string(),
    href: z.string().url().optional(),
    order: z.number().int().optional(),
    key: z.string().optional(),
    category: z
      .union([Underwrite_Core_FollowUps.followUpCategorySchema, z.string()])
      .optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string().optional(),
    phoneNumber: z.string().optional(),
    callWith: z.string().optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type ActiveTask = z.infer<typeof activeTaskSchema>;

export const propertyAddressSchema = z
  .object({
    street_address: z.string(),
    city: z.string(),
    county: z.string().nullable().optional(),
    state: z.string(),
    zip: z.string(),
  })
  .catchall(z.unknown() as z.ZodType);
export type PropertyAddress = z.infer<typeof propertyAddressSchema>;

export const dashboardPropertySchema = z
  .object({
    address: propertyAddressSchema,
  })
  .catchall(z.unknown() as z.ZodType);
export type DashboardProperty = z.infer<typeof dashboardPropertySchema>;

export const dashboardSuccessResponseSchema = z
  .object({
    success: z.literal(true),
    active_stage: Underwrite_Core_Dockets.docketStageSchema,
    recent_activity: z.array(completedTaskSchema),
    initial_offer: z.number().nullable(),
    stages: z.array(docketStageItemSchema),
    timeline: docketTimelineRawDataSchema.optional(),
    person: dashboardPersonSchema,
    docket: docketSchema,
    property: dashboardPropertySchema.optional(),
    account_manager: z.string().nullable(),
    application: dashboardApplicationSchema.optional(),
    authenticate: z.boolean(),
    read_only: z.boolean(),
  })
  .catchall(z.unknown() as z.ZodType);
export type DashboardSuccessResponse = z.infer<
  typeof dashboardSuccessResponseSchema
>;

export const validateCredentialsResponseBodySchema = z
  .object({
    isValid: z.boolean(),
    personId: z.number().optional(),
    personType: z.enum(["Applicant", "ApplicantSpouse"]).optional(),
    expiresOn: z.string().optional(),
    salesforceAccountId: z.string().nullable().optional(),
    salesforceOpportunityId: z.string().nullable().optional(),
    docketId: z.number().optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type ValidateCredentialsResponseBody = z.infer<
  typeof validateCredentialsResponseBodySchema
>;

export const activeDocketsResponseSchema = z
  .object({
    active: z.boolean(),
  })
  .catchall(z.unknown() as z.ZodType);
export type ActiveDocketsResponse = z.infer<typeof activeDocketsResponseSchema>;

export const getMagicLinkSuccessResponseSchema = z
  .object({
    success: z.literal(true),
    magic_link: z.string(),
  })
  .catchall(z.unknown() as z.ZodType);
export type GetMagicLinkSuccessResponse = z.infer<
  typeof getMagicLinkSuccessResponseSchema
>;

export const docketInfoResponseSchema = z
  .object({
    id: z.number(),
    key: z.string(),
    status: z.string(),
    stage: z.string(),
    applicant: z
      .object({
        first_name: z.string(),
        middle_name: z.string().nullable().optional(),
        last_name: z.string(),
        primary_email: z.string(),
      })
      .catchall(z.unknown() as z.ZodType),
    terms: z
      .object({
        term: z.number(),
        option_investment_amount: z.string(),
        created_at: z.string(),
      })
      .catchall(z.unknown() as z.ZodType)
      .optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type DocketInfoResponse = z.infer<typeof docketInfoResponseSchema>;

export const getDocketInfoSuccessResponseSchema = z
  .object({
    success: z.literal(true),
    docket: docketInfoResponseSchema,
  })
  .catchall(z.unknown() as z.ZodType);
export type GetDocketInfoSuccessResponse = z.infer<
  typeof getDocketInfoSuccessResponseSchema
>;

export const dashboardLogoutResponseSchema = z
  .object({
    success: z.boolean(),
    message: z.string().optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type DashboardLogoutResponse = z.infer<
  typeof dashboardLogoutResponseSchema
>;

export const acceptFollowupResponseSchema = Underwrite.successResponseSchema;
export type AcceptFollowupResponse = z.infer<
  typeof acceptFollowupResponseSchema
>;

export const rejectFollowupResponseSchema = Underwrite.successResponseSchema;
export type RejectFollowupResponse = z.infer<
  typeof rejectFollowupResponseSchema
>;

export const magicLinkResponseSchema = Underwrite.successResponseSchema;
export type MagicLinkResponse = z.infer<typeof magicLinkResponseSchema>;

export const dashboardResponseSchema = z.discriminatedUnion("success", [
  dashboardSuccessResponseSchema,
  dashboardErrorResponseSchema,
]);
export type DashboardResponse = z.infer<typeof dashboardResponseSchema>;

export const getMagicLinkResponseSchema = z.discriminatedUnion("success", [
  Underwrite.errorResponseSchema,
  getMagicLinkSuccessResponseSchema,
]);
export type GetMagicLinkResponse = z.infer<typeof getMagicLinkResponseSchema>;

export const getDocketInfoResponseSchema = z.discriminatedUnion("success", [
  Underwrite.errorResponseSchema,
  getDocketInfoSuccessResponseSchema,
]);
export type GetDocketInfoResponse = z.infer<typeof getDocketInfoResponseSchema>;
