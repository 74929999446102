import styled from 'styled-components';
import {
  ContainerStyles,
  Header,
  SplashTextStyles,
  mixins,
  mobileScreen,
  pxToRem,
} from '@pointdotcom/pds';
import { SubHeaderStyle } from 'components/SubHeader/styles';
import DashboardSection from 'containers/DashboardPage/TimelineDashboard/DashboardSection';
import { BorderedSectionStyle } from 'styles';
import backgroundCirclesImage from './images/backgroundcircles.png';
import heroImg from './images/herographic.png';

export const TaskPageHeroStyle = styled.header.attrs({ className: 'TaskPageHeroStyle' })`
  background: ${({ theme }) => theme.Color.GrayLight1};
  --imageWidth: 246px;

  ${ContainerStyles.ContainerStyle} {
    position: relative;
    padding-block: clamp(1em, 5vh, 2em);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:before {
      content: '';
      background-image: url('${backgroundCirclesImage}');
      background-size: 42%;
      background-repeat: no-repeat;
      background-position: 135px -65px;
      position: absolute;
      width: var(--imageWidth);
      height: 100%;
      z-index: 1;
      right: 0;
      top: 0;
    }
    &:after {
      content: '';
      background-image: url('${heroImg}');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: bottom right;
      position: absolute;
      width: var(--imageWidth);
      height: calc(var(--imageWidth) * 0.54);
      z-index: 1;
      right: 0;
      bottom: -30%;
    }
  }

  ${mobileScreen} {
    ${ContainerStyles.ContainerStyle} {
      &:before {
        background-position: 155px -45px;
        background-size: 32%;
      }
      &:after {
        --imageWidth: 180px;
      }
    }
  }
`;

export const DashboardTasksPageStyle = styled.div.attrs({
  className: 'DashboardTasksPageStyle',
})`
  background: white;

  ${SubHeaderStyle} {
    border: none;
  }
`;

export const PageContentStyle = styled.section.attrs({ className: 'PageContentStyle' })`
  padding-block: 8vh 12vh;
  > ${ContainerStyles.ContainerStyle} {
    display: flex;
    flex-flow: column nowrap;
    gap: clamp(2rem, 6vh, 4rem);
  }
`;

export const TaskGraphicStyle = styled.div.attrs({ className: 'TaskGraphicStyle' })<{
  hasCompletedItems: boolean;
}>`
  align-self: center;
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    align-self: revert;
  }

  img {
    position: relative;
    bottom: ${({ hasCompletedItems }) => (hasCompletedItems ? '-2.1rem' : '-0.5rem')};
    max-width: 100%;
    @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
      max-width: 400px;
      width: 70%;
      left: -1rem;
      margin: 0;
      bottom: 0;
    }
  }
`;

export const TaskSubHeaderStyle = styled(Header).attrs({ className: 'TaskSubHeaderStyle' })`
  > * {
    line-height: 1.2em;
  }
  a {
    margin-top: 1em;
    display: inline-block;
    font-family: inherit;
    text-decoration: none;
    ${mixins.desktopHoverCondition()} {
      &:hover {
        text-decoration: revert;
      }
    }
  }
`;

export const CompletedDateStyle = styled.span.attrs({ className: 'CompletedDateStyle' })`
  color: ${({ theme }) => theme.Color.Gray3};
  justify-self: end;

  ${mobileScreen} {
    grid-column: 2;
    justify-self: start;
    font-size: ${pxToRem(14)};
  }
`;

export const TasksSectionStyle = styled(DashboardSection).attrs({
  className: 'TasksSectionStyle',
})`
  border: none;
`;

export const NoTasksInSectionStyle = styled.div.attrs({ className: 'NoTasksInSectionStyle' })`
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  ${mobileScreen} {
    font-size: ${pxToRem(14)};
  }
`;

export const NoActiveTasksStyle = styled(BorderedSectionStyle).attrs({
  className: 'NoActiveTasksStyle',
})`
  border-bottom-width: 1px;
  border-top-width: 0;
  margin-bottom: 3rem;
  padding-top: 0;
  ${SplashTextStyles.SplashCopySerifStyle} {
    max-width: 40%;
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  }
  ${mobileScreen} {
    ${SplashTextStyles.SplashCopySerifStyle} {
      max-width: none;
    }
  }
`;
