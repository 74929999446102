import styled from 'styled-components';
import {
  Button,
  ButtonStyles,
  CloseButtonStyles,
  HeaderStyles,
  IconStyles,
  SplashTextStyles,
  TooltipStyles,
  mixins,
} from '@pointdotcom/pds';
import { singleColumnBreakpoint } from '../constants';

export const OfferAmountSectionStyle = styled.div.attrs({ className: 'OfferAmountSectionStyle' })`
  z-index: 1;
  position: sticky;
  top: 10rem;
  height: fit-content;
  z-index: 1;
  @media (max-width: ${singleColumnBreakpoint}) {
    position: static;
  }
`;

export const ContentWrapperStyle = styled.div.attrs({ className: 'ContentWrapperStyle' })`
  margin-inline: ${mixins.pxToRem(36)};
  text-align: center;

  @media (max-width: ${singleColumnBreakpoint}) {
    margin-inline: var(--inlinePadding);
  }

  ${HeaderStyles.HeaderStyle} {
    > * {
      text-align: center;
      color: ${({ theme }) => theme.Color.GrayLightestAccessible};

      @media (max-width: ${singleColumnBreakpoint}) {
        text-align: left;
      }
    }
  }
`;

export const FundingDateStyle = styled.div.attrs({ className: 'FundingDateStyle' })`
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
  padding-block: ${mixins.pxToRem(40)} ${mixins.pxToRem(50)};
  margin-bottom: 4rem;
  border-bottom: 1px solid ${({ theme }) => theme.line.color.default};
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};

  @media (max-width: ${singleColumnBreakpoint}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0;
    padding-block: ${mixins.pxToRem(28)};
    border-bottom: none;
    font-size: ${mixins.pxToRem(14)};
  }
`;

export const OfferAmountStyle = styled.div.attrs({ className: 'OfferAmountStyle' })`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.line.color.default};

  ${SplashTextStyles.SplashCopySerifStyle} {
    padding-bottom: 2rem;
    font-size: ${mixins.pxToRem(70)};
  }

  img {
    display: block;
    margin: auto;
    height: ${mixins.pxToRem(34)};
    width: auto;
  }

  @media (max-width: ${singleColumnBreakpoint}) {
    ${SplashTextStyles.SplashCopySerifStyle} {
      font-size: ${mixins.pxToRem(60)};
      text-align: left;
    }

    img {
      position: absolute;
      right: 1.5rem;
      bottom: 0;

      height: ${mixins.pxToRem(40)};
      transform: scaleX(-1);
    }
  }
`;

export const DateRangeButtonStyle = styled(Button).attrs({
  className: 'DateRangeButtonStyle',
  block: true,
})`
  place-content: center;
  ${IconStyles.IconStyle} {
    position: static;
    display: inline-block;
    transform: none;
    width: 16px;
    height: 16px;
    path {
      fill: currentColor;
    }
  }
  ${ButtonStyles.ButtonContentStyle} {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  button {
    white-space: nowrap;
  }
  @media (max-width: ${singleColumnBreakpoint}) {
    display: inline-block;
    button {
      font-size: ${mixins.pxToRem(14)};
    }
  }
`;

export const DateRangeContainerStyle = styled.div.attrs({ className: 'DateRangeContainerStyle' })`
  position: relative;
  ${TooltipStyles.TooltipStyle} {
    width: clamp(300px, 40vw, 420px);
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(0.5rem, -50%);
    padding: 5rem 3.5rem 4rem;
    ${CloseButtonStyles.CloseButtonStyle} {
      width: 1.5rem;
      height: 1.5rem;
    }
    ${SplashTextStyles.SplashCopySerifStyle} {
      color: ${({ theme }) => theme.Color.PointBlack};
      font-size: ${mixins.pxToRem(16)};
    }
    p {
      margin: 0;
      text-align: left;
    }
    > * {
      display: flex;
      flex-flow: column nowrap;
      gap: 1em;
      align-items: flex-start;
    }
    &:after {
      left: -${TooltipStyles.arrowSizePx * 2}px;
      transform: translate(1px, 0);
    }
  }

  ${mixins.mobileScreen} {
    ${TooltipStyles.TooltipStyle} {
      top: 0;
      left: auto;
      right: -1rem;
      transform: translate(0, 15%);
      width: calc(100vw - 2rem);
      padding: 4.5rem 3rem 3rem;
      &:after {
        left: unset;
        right: ${TooltipStyles.arrowSizePx * 3}px;
        transform: translate(0, 1px);
      }
    }
  }
`;
