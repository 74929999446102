import { z } from 'zod';
import { InputMask, InputMaskType, messagingI18n, templatedString } from '@pointdotcom/pds';
import { heiApplicationSchema } from 'services/apiTypes/homeownerTypes';
import { FormStructureField, ValidationFunc, ValidationProps } from '../constants';
import i18n from '../i18n';

export const getItemFromSchemaByPath = ({ path }: { path: string }) => {
  const segments = path.split('.') || [];
  let currentObject = { ...heiApplicationSchema.shape } as TSFixMe;
  segments.every((segment) => {
    const key = segment as keyof typeof currentObject;
    try {
      if (currentObject[key]) {
        currentObject = currentObject[key];
      } else if (currentObject?.shape) {
        currentObject = currentObject?.shape[key];
      }
    } catch (_) {
      return false;
    }
    return true;
  });

  return currentObject as z.ZodAny;
};

export const baseFieldValidation = ({ fieldValue, field }: ValidationProps) => {
  if (!field) {
    return true;
  }
  const schemaItem = getItemFromSchemaByPath({ path: field.path });
  const parsed = schemaItem?.safeParse?.(fieldValue);
  if (parsed?.success) {
    return true;
  }

  return parsed?.error;
};

export const validateConditionalFields =
  (schema: z.ZodSchema<unknown>): ValidationFunc =>
  ({ getFieldValueByPath, field } = {}) => {
    const pathArr = (field?.path || '').split('.');
    const basePath = field?.parentPath || '';
    const value = getFieldValueByPath?.(basePath);
    const result = schema.safeParse(value);
    const schemaItem = field?.path ? getItemFromSchemaByPath({ path: field.path }) : null;

    if (result.success) {
      return true;
    }

    const relatedPaths = field?.relatedPaths || [];
    const parentIssues = result.error.issues.filter((issue) => {
      const issuePath = issue.path.join('.');
      const path = `${basePath}.${issuePath}`;
      return field?.path.includes(path) && field?.path !== path;
    });

    const relatedIssues = result.error.issues.filter((issue) => {
      const issuePath = issue.path.join('.');
      const path = `${basePath}.${issuePath}`;
      return relatedPaths.some(
        (relatedPath) => relatedPath.includes(path) || path.includes(relatedPath)
      );
    });

    const thisError = result.error.issues.find((issue) => {
      const issuePath = issue.path.join('.');
      return `${basePath}.${issuePath}` === field?.path;
    });

    if (thisError) {
      return new z.ZodError([thisError]);
    }

    // HACK: handle top level parent level errors on the field if its not optional
    const [firstError] = result.error.issues;

    if (!firstError.path.length) {
      if (schemaItem instanceof z.ZodOptional) {
        return new z.ZodError([
          {
            code: z.ZodIssueCode.custom,
            path: pathArr,
            message: '',
            params: { associated: true },
          },
        ]);
      }

      // Handle unions
      if (firstError.code === z.ZodIssueCode.invalid_union) {
        // HACK: second error seems to have the relevant issues listed
        const [, baseUnionError] = firstError.unionErrors;

        // find the relevant issue from the path
        const relevantUnionIssue = baseUnionError.issues.find((issue) => {
          return pathArr.join('.').includes(`${basePath}.${issue.path.join('.')}`);
        });

        // if there is an issue with the union, and its not on this field, assume its a related error
        if (!relevantUnionIssue) {
          return new z.ZodError([
            {
              code: z.ZodIssueCode.custom,
              path: pathArr,
              message: '',
              params: { associated: true },
            },
          ]);
        }
        return new z.ZodError([relevantUnionIssue]);
      }

      return result.error;
    }

    // handle nested object parent issues
    if (parentIssues.length) {
      return result.error;
    }

    if (relatedIssues.length) {
      return new z.ZodError([
        {
          code: z.ZodIssueCode.custom,
          path: pathArr,
          message: '',
          params: { associated: true },
        },
      ]);
    }

    return true;
  };

export const getHelpTextFromZodIssue = (issue: z.ZodIssue, field?: FormStructureField) => {
  if (issue.code === z.ZodIssueCode.too_small && issue.minimum && issue.type === 'number') {
    const minValue = field?.props?.mask
      ? new InputMask({
          type: field.props.mask as InputMaskType,
        }).getFormatted(Number(issue.minimum))
      : Number(issue.minimum);
    return templatedString({ template: i18n.valueMustBeGreater, values: { num: minValue } });
  }

  if (
    (issue.code === z.ZodIssueCode.too_small && issue.type === 'string') ||
    issue.code === z.ZodIssueCode.invalid_type ||
    issue.code === z.ZodIssueCode.invalid_union ||
    issue.code === z.ZodIssueCode.invalid_union_discriminator
  ) {
    if (field?.path === 'consents') {
      return i18n.theAboveConsents;
    }

    return messagingI18n.errors.fieldRequired;
  }
  return issue.message;
};

export const getZodIssueFromBEResponse = (
  issueParams: Partial<z.ZodCustomIssue> | undefined = {}
) => {
  return new z.ZodError([
    {
      code: z.ZodIssueCode.custom,
      path: [],
      message: i18n.invalidInput,
      ...issueParams,
    },
  ]);
};
