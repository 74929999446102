import * as z from "zod";
import * as Underwrite from "./Underwrite.types";

export const steppedPricingItemSchema = z
  .object({
    option_investment_amount: z.number(),
    option_percentage: z.number(),
    cash_to_close: z.number(),
    is_eligible: z.boolean().optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type SteppedPricingItem = z.infer<typeof steppedPricingItemSchema>;

export const steppedPricingSchema = z.array(steppedPricingItemSchema);
export type SteppedPricing = z.infer<typeof steppedPricingSchema>;

export const steppedPricingSuccessResponseSchema = z
  .object({
    success: z.literal(true),
    stepped_pricing: steppedPricingSchema,
  })
  .catchall(z.unknown() as z.ZodType);
export type SteppedPricingSuccessResponse = z.infer<
  typeof steppedPricingSuccessResponseSchema
>;

export const steppedPricingResponseSchema = z.discriminatedUnion("success", [
  steppedPricingSuccessResponseSchema,
  Underwrite.errorResponseSchema,
]);
export type SteppedPricingResponse = z.infer<
  typeof steppedPricingResponseSchema
>;
