import React, { useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import {
  AutoCompleteChangeEvent,
  AutoCompleteProps,
  Autocomplete,
  AutocompleteSelectionType,
  Size,
  messagingI18n,
} from '@pointdotcom/pds';
import {
  StructuredAddress,
  formatSuggestionAsString,
  getReshapedAddressStructureFromAutocompleteAPI,
} from 'lib/smartyStreets';
import {
  SmartyStreetsUsAutocompleteProSuggestion,
  useLazyGetLookupQuery,
} from 'services/api/smartyStreetsApi';

const THROTTLE_MILLISECONDS = 275;
const MIN_CHARS_BEFORE_SEARCH = 3;

type SmartyStreetsAutocompleteSelectEvent = (props: {
  value: string;
  structured: null | StructuredAddress;
  unformatted: SmartyStreetsUsAutocompleteProSuggestion;
  selectionType: AutocompleteSelectionType;
}) => void;

type SmartyStreetsAutocompleteProps = Pick<
  AutoCompleteProps,
  | 'aria-label'
  | 'value'
  | 'styleSize'
  | 'inputProps'
  | 'placeholder'
  | 'error'
  | 'helptext'
  | 'onFocus'
  | 'onBlur'
  | 'onChange'
  | 'onSubmit'
> & {
  onSelect?: SmartyStreetsAutocompleteSelectEvent;
};

const SmartyStreetsAutocomplete = ({
  'aria-label': ariaLabel,
  value: valueFromProps = '',
  styleSize = Size.Default,
  inputProps,
  placeholder,
  error: errorFromProps = false,
  helptext: helpTextFromProps,
  onFocus = () => {},
  onBlur = () => {},
  onSelect = () => {},
  onChange = () => {},
  onSubmit,
}: SmartyStreetsAutocompleteProps) => {
  const abortRef = useRef<null | (() => void)>(null);
  const [typed, setTyped] = useState(valueFromProps);
  const [suggestions, setSuggestions] = useState<SmartyStreetsUsAutocompleteProSuggestion[]>([]);
  const [manualErrorText, setManualErrorText] = useState<null | string>(null);
  const [getLookup] = useLazyGetLookupQuery();

  // See guidance in https://kyleshevlin.com/debounce-and-throttle-callbacks-with-react-hooks
  const requestAndSetResultsDebounced = useMemo(() => {
    const requestAndSetResults = async (userTyped: string) => {
      if (abortRef.current) {
        abortRef.current();
      }
      const promise = getLookup(
        {
          typedAddress: userTyped,
        },
        true /* preferCacheValue */
      );
      abortRef.current = () => promise.abort();
      let results;
      try {
        results = await promise;
      } catch (error: unknown) {
        if (error instanceof Error && error.name === 'AbortError') {
          return;
        } else {
          throw error;
        }
      }
      const { data, error } = results;
      if (data != null) {
        setSuggestions(data.suggestions);
      } else if (error != null) {
        if (!('name' in error && error.name === 'AbortError')) {
          setManualErrorText(messagingI18n.errors.unknownError);
        }
      }
    };

    return debounce(requestAndSetResults, THROTTLE_MILLISECONDS);
  }, [getLookup]);

  const prevTypedRef = useRef(typed);

  useEffect(() => {
    if (typed !== prevTypedRef.current) {
      prevTypedRef.current = typed;
      setManualErrorText(null);
    }
    const smartyStreetRequest = async () => {
      const typedTrimmed = typed.trim();
      if (!typedTrimmed.length || typedTrimmed.length < MIN_CHARS_BEFORE_SEARCH) {
        setSuggestions([]);
        return;
      }
      await requestAndSetResultsDebounced(typed);
    };

    smartyStreetRequest();

    return () => abortRef.current?.();
  }, [typed, requestAndSetResultsDebounced]);

  const handleChange: AutoCompleteChangeEvent = async (e, { value }) => {
    setTyped(value);
    onChange(e, { value });
  };

  const handleSelect: AutoCompleteProps['onSelect'] = ({ value, unformatted, type }) => {
    onSelect({
      value,
      structured: getReshapedAddressStructureFromAutocompleteAPI(
        unformatted satisfies SmartyStreetsUsAutocompleteProSuggestion
      ),
      unformatted,
      selectionType: type,
    });
  };

  // filter out duplicates that are present when an address has multiple units
  // not doing so shows multiple results of an apartment like so -
  // [ 123 test st, APT (3 entries), some city CA, 12345 ]
  // [ 123 test st, some city CA, 12345 ]
  const filter: AutoCompleteProps['filter'] = ({ item }) => item.entries === 0;

  const helptext = manualErrorText || helpTextFromProps;
  const hasError = !!errorFromProps || !!manualErrorText;

  return (
    <Autocomplete
      aria-label={ariaLabel}
      error={hasError}
      helptext={helptext}
      data={suggestions}
      placeholder={placeholder}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      itemFormatter={formatSuggestionAsString}
      value={typed}
      onSelect={handleSelect}
      onSubmit={onSubmit}
      inputProps={inputProps}
      filter={filter}
      styleSize={styleSize}
    />
  );
};

export default SmartyStreetsAutocomplete;
