import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Header, SplashText, Style, useScrollSpy } from '@pointdotcom/pds';
import ChatlioButton from 'components/ChatlioButton';
import { ANIM_SPEED_MS } from 'components/ChatlioButton/styles';
import { useAccountManagerContactInfo } from 'containers/hooks/useAccountManagerContactInfo';
import { DashboardAccountManager } from 'models/DashboardModel';
import i18n from './i18n';
import * as styles from './styles';

function Divider({ phrase }: { phrase: string }) {
  return (
    <styles.DividerStyle>
      <styles.LineStyle />
      <Header styleType={Style.Uppercase} noMargin>
        {phrase}
      </Header>
      <styles.LineStyle />
    </styles.DividerStyle>
  );
}

export interface SupportOptionsProps {
  accountManager: DashboardAccountManager;
}

export function SupportOptions({ accountManager }: SupportOptionsProps) {
  const staticChatBtnRef = React.useRef<HTMLDivElement>(null);
  const { spyIsShown } = useScrollSpy({ spyOn: staticChatBtnRef });
  const chatOnline = window._chatlio?.isOnline && window._chatlio?.isOnline();
  const amContactInfo = useAccountManagerContactInfo(accountManager);

  if (!amContactInfo) return null;
  const { firstName, image, phoneNumber, email } = amContactInfo;

  return (
    <>
      <styles.SupportOptionsStyle>
        <styles.AccountManagerStyle>
          <styles.AMIntroStyle>
            {image ? <img src={image} alt="" /> : null}
            <span>{i18n.reachOutTo}</span>
          </styles.AMIntroStyle>
          <styles.AMDetailStyle>
            <SplashText italic noMargin>
              {firstName}
            </SplashText>
            <styles.ContactMethodsDividedStyle>
              <a href={`mailto:${email}`}>{email}</a>
              <div />
              <a href={`tel:${phoneNumber?.replace(/\s/g, '')}`}>{phoneNumber}</a>
            </styles.ContactMethodsDividedStyle>
          </styles.AMDetailStyle>
        </styles.AccountManagerStyle>
        {chatOnline && (
          <>
            <Divider phrase="or" />
            <ChatlioButton ref={staticChatBtnRef} mode="static-light" />
          </>
        )}
      </styles.SupportOptionsStyle>
      {chatOnline && (
        <CSSTransition classNames="slideout" in={spyIsShown} timeout={ANIM_SPEED_MS}>
          <ChatlioButton mode="floating" />
        </CSSTransition>
      )}
    </>
  );
}
