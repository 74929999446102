import styled, { css } from 'styled-components';
import { ButtonStyles, ContainerStyles, mobileScreen, pxToRem, rgba } from '@pointdotcom/pds';
import {
  DashboardTaskContentRowStyle,
  DashboardTaskTitleStyle,
  TaskButtonBaseStyle,
} from './DashboardTask/styles';
import { DashboardTasksProps } from './constants';

// TODO: integrate compact prop after dashboard experiment is complete
// (https://pointdf.atlassian.net/browse/CE-2952)
const compactCss = css<{
  mobileShadowed?: boolean;
}>`
  :where(${DashboardTaskContentRowStyle} ${DashboardTaskTitleStyle}) {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${mobileScreen} {
    box-shadow: ${({ theme, mobileShadowed }) => {
      if (mobileShadowed) {
        return `0px 0px 20px 0px ${rgba(theme.Color.Gray4, 0.11)}`;
      }
      return 'none';
    }};
    border-radius: 3px;
    overflow: hidden;
    ${TaskButtonBaseStyle} {
      &:first-child {
        > * {
          border: none;
        }
      }
      > * {
        padding: ${pxToRem(16)} ${pxToRem(20)};
        padding-right: calc(${pxToRem(16)} + 1em);
        font-size: ${pxToRem(14)};
      }
    }
  }
`;

export const DashboardTasksStyle = styled.div.attrs({
  className: 'DashboardTasksStyle',
})<
  Pick<DashboardTasksProps, 'compact'> & {
    mobileShadowed?: boolean;
  }
>`
  > ${ContainerStyles.ContainerStyle} {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.6rem;
  }
  ${ButtonStyles.ButtonContainerStyle} {
    margin-top: 0; // TODO: fix this in PDS so we don't need the !important
  }
  ${mobileScreen} {
    > ${ContainerStyles.ContainerStyle} {
      gap: 0;
    }
  }
  ${({ compact }) => compact && compactCss}
`;
