import { directory } from '@pointdotcom/pds';
import { useFeatureFlag } from 'lib/featureFlags';
import { FeatureFlag } from 'lib/featureFlags';
import { DashboardAccountManager } from 'models/DashboardModel';

export function useAccountManagerContactInfo(accountManager: DashboardAccountManager) {
  const showCommonContactInfo = !!useFeatureFlag(FeatureFlag.DashboardCommonContactInfo);

  if (!accountManager || typeof accountManager === 'string') return null;

  const { firstName, name, image, jobTitle } = accountManager;
  let phoneNumber = accountManager.phoneNumber;
  let email = accountManager.email;

  if (showCommonContactInfo) {
    email = 'assist@point.com';
    phoneNumber = directory.PointNumber.Support;
  }

  return { firstName, name, image, jobTitle, phoneNumber, email };
}
