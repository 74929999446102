export default {
  completed: 'Completed',
  currentStage: 'Current Stage',
  upcoming: 'Upcoming',
  completedOnDate: 'Completed on %date%',
  estimated15Minutes: 'Estimated 15 minutes',
  estimatedLess: 'Estimated less than a week',
  estimatedOneWeek: 'Estimated up to a week',
  estimatedMultipleWeeks: 'Estimated %minWeeks%-%maxWeeks% weeks',
  yourFundingTimeline: 'Your Funding Timeline',
  complete: 'Complete',
  inProgress: 'In Progress',
  notStarted: 'Not Started',
};
