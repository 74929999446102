import styled, { createGlobalStyle, css } from 'styled-components';
import { Button, ButtonStyles, IconStyles, SplashTextStyles, mixins } from '@pointdotcom/pds';
import { ChatIconStyle } from 'components/ChatIcon/styles';
import { pxToRem } from 'styleHelpers';

export const ChatlioWidgetGlobalStyle = createGlobalStyle<{ show: boolean }>`
  #chatlio-widget {
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;

export const ANIM_SPEED_MS = 250;

const chatButtonDarkCss = css`
  button {
    color: white;
    background-color: ${({ theme }) => theme.Color.PurpleBlue5};
  }
`;

const chatButtonFloatingCss = css`
  ${chatButtonDarkCss}
  position: fixed;
  bottom: 0;
  right: 10px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
`;

const chatButtonLightCss = css`
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  button {
    align-self: stretch;
    color: inherit;
    ${mixins.desktopHoverCondition()} {
      &:hover,
      &:focus,
      &:active {
        color: inherit;
      }
    }
  }
`;

export const ChatButtonStyle = styled(Button).attrs({ className: 'ChatButtonStyle' })<{
  mode: string;
}>`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;

  ${ButtonStyles.ButtonContentStyle} {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  ${({ mode }) => {
    if (mode === 'floating') {
      return chatButtonFloatingCss;
    } else if (mode === 'static-light') {
      return chatButtonLightCss;
    } else {
      return chatButtonDarkCss;
    }
  }}

  transition:
    background-color 180ms ease-in-out,
    transform ${ANIM_SPEED_MS}ms ease-in-out;
  &.slideout-enter,
  &.slideout-enter-active,
  &.slideout-enter-done {
    transform: translateY(125%);
  }

  ${ChatIconStyle} {
    --size: 2em;
    width: var(--size);
    height: var(--size);
    fill: ${({ mode, theme }) =>
      mode === 'static-light' ? theme.Color.GrayLightestAccessible : 'white'};
  }
`;

export const ButtonLabelStyle = styled.div.attrs({ className: 'ButtonLabelStyle' })<{
  mode: string;
}>`
  display: flex;
  flex-direction: column;
  text-align: left;

  ${SplashTextStyles.SplashCopySerifStyle} {
    font-size: ${pxToRem(16)};
  }

  span {
    font-size: ${({ mode }) => (mode === 'static-light' ? pxToRem(15) : pxToRem(18))};
  }
`;

export const CloseButtonStyle = styled.span.attrs({
  className: 'CloseButtonStyle',
  role: 'button',
})`
  position: absolute;
  background-color: transparent;
  border: none;
  padding: 0;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  display: block;
  ${IconStyles.IconStyle} {
    position: static;
    transform: none;
  }
`;
