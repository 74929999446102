import styled from 'styled-components';
import { HeaderStyles } from '@pointdotcom/pds';
import { pxToRem } from 'styleHelpers';
import DashboardSection from '../DashboardSection';
import { DetailSectionsStyle } from '../styles';

export const RecentActivitySectionStyle = styled(DashboardSection).attrs({
  className: 'RecentActivitySectionStyle',
})`
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${DetailSectionsStyle} > & {
      padding-bottom: 0;
      padding-inline: 0;
    }
  }

  ${HeaderStyles.HeaderStyle} {
    > * {
      @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
        padding-left: var(--inlinePadding);
      }
    }
  }
`;

export const ActivitiesWrapperStyle = styled.div.attrs({ className: 'ActivitiesWrapperStyle' })`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    flex-direction: row;
    width: 100vw;
    overflow-x: auto;
    padding-inline: var(--inlinePadding);
  }
`;

export const ActivityItemStyle = styled.div.attrs({ className: 'ActivityItemStyle' })`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  // Note: Desktop styles here (don't miss the "not")
  @media not (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.line.color.default};
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  // Mobile
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    min-width: clamp(${pxToRem(200)}, 65vw, ${pxToRem(400)});
    padding: 1.25rem 1.5rem 2.5rem;
    border: 1px solid ${({ theme }) => theme.line.color.default};
    border-bottom: none;
  }
`;

export const ActivityItemDateStyle = styled.span.attrs({ className: 'ActivityItemDateStyle' })`
  color: ${({ theme }) => theme.Color.Gray3};
  font-size: ${pxToRem(14)};

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    font-size: ${pxToRem(13)};
  }
`;

export const ActivityItemDescriptionStyle = styled.p.attrs({
  className: 'ActivityItemDescriptionStyle',
})`
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  font-family: ${({ theme }) => theme.fonts.serif.name};
  font-size: ${pxToRem(18)};
  margin-bottom: 0;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    font-size: ${pxToRem(16)};
  }
`;
