import styled from 'styled-components';
import DashboardSection from '../DashboardSection';

export const NoOpenTasksContentStyle = styled.div.attrs({
  className: 'NoOpenTasksContentStyle',
})`
  display: flex;
  flex-flow: column nowrap;
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  a {
    ${({ theme }) => theme.globals.linkInvertedCSS}
  }
`;

export const TaskListSectionStyle = styled(DashboardSection).attrs({
  className: 'TaskListSectionStyle',
})``;
