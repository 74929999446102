import {
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError, AxiosRequestConfig, Method, RawAxiosRequestHeaders } from 'axios';
import { z } from 'zod';
import bugsnagClient from 'lib/bugsnagClient';

const fetchBaseQueryErrorSchema = z.union([
  z.object({
    status: z.number(),
    data: z.unknown(),
  }),
  z.object({
    status: z.literal('FETCH_ERROR'),
    data: z.undefined().optional(),
    error: z.string(),
  }),
  z.object({
    status: z.literal('PARSING_ERROR'),
    originalStatus: z.number(),
    data: z.string(),
    error: z.string(),
  }),
  z.object({
    status: z.literal('CUSTOM_ERROR'),
    data: z.unknown().optional(),
    error: z.string(),
  }),
]);

function parseQueryError(error: unknown): null | FetchBaseQueryError {
  const { success, data } = fetchBaseQueryErrorSchema.safeParse(error);

  if (success) {
    return data;
  } else {
    return null;
  }
}

type FetchBaseQueryArgs = Parameters<typeof fetchBaseQuery>[0];

const axiosBaseQuery =
  ({ baseUrl }: FetchBaseQueryArgs = { baseUrl: '' }) =>
  async ({
    url,
    method,
    headers,
    withCredentials = true,
    data,
    params,
    onUploadProgress,
  }: AxiosRequestConfig): Promise<
    | { data: unknown; error?: undefined }
    | { error: FetchBaseQueryError & z.infer<typeof fetchBaseQueryErrorSchema>; data?: undefined }
  > => {
    try {
      const result = await axios({
        url: `${baseUrl}${url}`,
        method,
        headers,
        withCredentials,
        data,
        params,
        onUploadProgress,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      if (err.response) {
        return {
          error: {
            status: err.response.status,
            data: err.response.data,
          },
        };
      } else {
        return { error: { status: 'FETCH_ERROR', error: err.message } };
      }
    }
  };

type LosAxiosOptions = {
  method: Method;
  headers: RawAxiosRequestHeaders & {
    'X-CSRF-Token'?: string;
  };
  withCredentials: boolean;
};

const losAxiosOptions = (
  method: Method = 'GET',
  csrfToken: null | string = null,
  additionalHeaders: RawAxiosRequestHeaders = {}
): LosAxiosOptions => {
  const requestOptions: LosAxiosOptions = {
    method,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      ...additionalHeaders,
    },
    withCredentials: true,
  };

  if (csrfToken) {
    requestOptions.headers['X-CSRF-Token'] = csrfToken;
  }

  return requestOptions;
};

type FetchWithFallbackArgs = FetchArgs & {
  fallbackUrl?: null | string;
};

const fetchWithFallbackBaseQuery = (
  baseQueryArgs: FetchBaseQueryArgs
): BaseQueryFn<
  string | FetchWithFallbackArgs,
  unknown,
  FetchBaseQueryError,
  object,
  FetchBaseQueryMeta
> => {
  const fetchBaseQueryInstance = fetchBaseQuery(baseQueryArgs);

  return async (args, api, extraOptions) => {
    let fallbackUrl = undefined;
    let fetchArgs;
    if (typeof args === 'object') {
      ({ fallbackUrl, ...fetchArgs } = args);
    } else {
      fetchArgs = args;
    }

    let response = await fetchBaseQueryInstance(fetchArgs, api, extraOptions);
    if (response.error?.status === 401 && fallbackUrl != null) {
      response = await fetchBaseQueryInstance(
        { ...fetchArgs, url: fallbackUrl },
        api,
        extraOptions
      );
    }

    return response;
  };
};

function getUrlWithSearchParams(
  baseUrl: URL | string,
  searchParams?: Record<string, undefined | null | string>
): string;

function getUrlWithSearchParams(
  baseUrl: undefined | null | URL | string,
  searchParams?: Record<string, undefined | null | string>
): undefined | null | string;

function getUrlWithSearchParams(
  baseUrl: undefined | null | URL | string,
  searchParams?: Record<string, null | undefined | string>
): undefined | null | string {
  if (baseUrl == null) {
    return baseUrl;
  }

  const url = new URL(baseUrl);
  if (searchParams != null) {
    for (const [key, value] of Object.entries(searchParams)) {
      if (value != null) {
        url.searchParams.append(key, value);
      }
    }
  }
  return url.toString();
}

function parseResponse<TSchema extends z.ZodTypeAny>(
  response: unknown,
  schema: TSchema,
  location?: string
): z.output<TSchema> | z.input<TSchema> {
  const result = schema.safeParse(response);
  if (!result.success) {
    const message = `API validation parse failure${location ? ` in ${location}` : ''}: ${
      result.error
    }`;
    bugsnagClient.notify(message, (event) => {
      event.addMetadata('Raw Response', { response: JSON.stringify(response) });
    });
    console.error(message, { response });
    if (process.env.REACT_APP_ENV === 'test' || process.env.REACT_APP_ENV === 'dev') {
      throw new Error(message);
    }
    return response as z.input<TSchema>;
  }
  return result.data;
}

export {
  axios,
  axiosBaseQuery,
  losAxiosOptions,
  fetchWithFallbackBaseQuery,
  getUrlWithSearchParams,
  parseQueryError,
  parseResponse,
};
