import styled from 'styled-components';
import {
  Container,
  HeaderStyles,
  LinkButtonStyles,
  ModalStyles,
  ProfileImageStyles,
  SplashTextStyles,
} from '@pointdotcom/pds';
import { ButtonScheduleNextAvailableStyle } from 'components/ButtonScheduleNextAvailable/styles';
import { ScheduleCalendarStyle } from 'components/ScheduleCalendar/styles';
import { pxToRem } from 'styleHelpers';

export const ScheduleCallContainerStyle = styled(Container).attrs({
  className: 'ScheduleCallContainerStyle',
})`
  --modal-padding: ${ModalStyles.desktopPadding};
  --header-width: 80%;
  padding: 0 var(--modal-padding) var(--modal-padding) var(--modal-padding);

  ${HeaderStyles.HeaderStyle} {
    > * {
      width: var(--header-width);
    }
  }
  ${ScheduleCalendarStyle} {
    margin-top: 0.5rem;
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    --modal-padding: 2.5rem;
    --header-width: 100%;
  }
`;

export const AppointmentTimeStyle = styled.span.attrs({
  className: 'AppointmentTimeStyle',
})``;

export const ModifiedScheduleButtonStyle = styled.div.attrs({
  className: 'ModifiedScheduleButtonStyle',
})`
  --lightBorder: ${({ theme }) => `1px solid ${theme.line.color.default}`};

  ${SplashTextStyles.SplashCopySerifStyle} {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: ${pxToRem(16)};
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
    margin-bottom: 1.5rem;

    &::before {
      content: '';
      border-top: var(--lightBorder);
      flex-basis: 2rem;
    }

    &::after {
      content: '';
      border-top: var(--lightBorder);
      flex-grow: 1;
    }
  }

  aside {
    margin-left: 1rem;
  }

  ${ButtonScheduleNextAvailableStyle} {
    --descW: 1fr;
    --ctaW: 200px;
    display: grid;
    grid-template-areas: 'picture desc cta';
    grid-template-columns: 85px var(--descW) var(--ctaW);
    gap: 1rem;
    align-items: center;
    padding-bottom: 2.25rem;
    border-bottom: var(--lightBorder);

    &.noImg {
      grid-template-areas: 'desc cta';
      grid-template-columns: var(--descW) var(--ctaW);
      ${ProfileImageStyles.ProfileImageStyle} {
        display: none;
      }
    }

    &.singleCol {
      grid-template-areas: 'cta';
      grid-template-columns: 1fr;
      border-bottom: none;
    }

    @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
      grid-template-columns: 85px 1fr;
      grid-template-areas:
        'picture desc'
        'cta cta';
      &.noImg {
        grid-template-areas:
          'desc desc'
          'cta cta';
      }
    }

    .ButtonLinkStyle {
      /* choose a different time link */
      display: none;
    }
  }

  .ButtonAndLinkContainerStyle {
    display: contents;
    > div {
      display: contents;
    }
  }

  ${ProfileImageStyles.ProfileImageStyle} {
    position: relative;
    grid-area: picture;
    align-self: start;
  }

  ${AppointmentTimeStyle} {
    grid-area: desc;
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  }

  .ButtonContainerStyle {
    grid-area: cta;
  }

  ${LinkButtonStyles.ButtonLinkStyle} {
    text-decoration: none;
  }
`;

export const ChooseLinkStyle = styled.div.attrs({
  className: 'ChooseLinkStyle',
})`
  margin-top: 1.375rem;
  display: flex;
  justify-content: flex-end;
`;
