import addressParser from 'parse-address';
import { SmartyStreetsUsAutocompleteProSuggestion } from 'services/smartStreetsUsAutocompleteProApi';
import { SmartyStreetsStreetAddress } from 'services/smartyStreetsStreetAddressApi';

// fields that the store expects
export enum AddressField {
  STREET = 'streetAddress',
  CITY = 'city',
  STATE = 'state',
  ZIP = 'zip',
  CONFIRMATION_REQUIRED = 'confirmationRequired',
  HAS_UNIT = 'hasUnit',
}

export type StructuredAddress = {
  [AddressField.STREET]?: string;
  [AddressField.CITY]?: string;
  [AddressField.STATE]?: string;
  [AddressField.ZIP]?: string;
  [AddressField.CONFIRMATION_REQUIRED]?: boolean;
  [AddressField.HAS_UNIT]?: boolean;
};

// Taken from https://www.smarty.com/docs/cloud/us-autocomplete-pro-api#pro-preference
export const formatSuggestionAsString = (
  suggestion: SmartyStreetsUsAutocompleteProSuggestion
): string => {
  if (!suggestion) return '';
  const suggestionCopy = { ...suggestion };
  let whiteSpace = '';
  if (suggestionCopy.secondary) {
    if (suggestionCopy.entries > 1) {
      suggestionCopy.secondary += ` (${suggestionCopy.entries} entries)`;
    }
    whiteSpace = ' ';
  }
  return `${suggestionCopy.street_line + whiteSpace + suggestionCopy.secondary}, ${
    suggestionCopy.city
  }, ${suggestionCopy.state} ${suggestionCopy.zipcode}`;
};

// address response from address api
export const addressHasUnit = (addressObjectFromQuery: SmartyStreetsStreetAddress): boolean => {
  // dpv_match_code value of D indicates a missing unit number, Y indicates that an address was found
  // presence of a secondary number indicates unit number present in address
  // https://www.smarty.com/docs/cloud/us-street-api
  const matchCode = addressObjectFromQuery?.analysis?.dpv_match_code;
  return (
    matchCode === 'D' ||
    (matchCode === 'Y' && !!addressObjectFromQuery?.components?.secondary_number)
  );
};

// reshapes smarty street structure returned from the
// auotcomplete API to the desired structure of the BE
export function getReshapedAddressStructureFromAutocompleteAPI(
  ssAddressShape: null | undefined
): null;
// eslint-disable-next-line no-redeclare
export function getReshapedAddressStructureFromAutocompleteAPI(
  ssAddressShape: SmartyStreetsUsAutocompleteProSuggestion
): StructuredAddress;

// eslint-disable-next-line no-redeclare
export function getReshapedAddressStructureFromAutocompleteAPI(
  ssAddressShape: null | undefined | SmartyStreetsUsAutocompleteProSuggestion
): null | StructuredAddress {
  if (!ssAddressShape) return null;

  const prequalAddressShape: StructuredAddress = {
    [AddressField.STREET]: ssAddressShape.street_line,
    [AddressField.CITY]: ssAddressShape.city,
    [AddressField.STATE]: ssAddressShape.state,
    [AddressField.ZIP]: ssAddressShape.zipcode,
  };

  if (ssAddressShape.entries > 0) {
    // usually means there is a unit number, unclear if that is always the case
    prequalAddressShape[AddressField.CONFIRMATION_REQUIRED] = true;
  }

  return prequalAddressShape;
}

// reshapes smarty street structure returned from the
// address API to the desired structure of the BE
export const getReshapedAddressStructureFromAddressAPI = (
  ssAddressShape: SmartyStreetsStreetAddress
): null | StructuredAddress => {
  if (!ssAddressShape?.components) return null;

  const prequalAddressShape: StructuredAddress = {
    [AddressField.STREET]: ssAddressShape.delivery_line_1,
    [AddressField.CITY]: ssAddressShape.components.city_name,
    [AddressField.STATE]: ssAddressShape.components.state_abbreviation,
    [AddressField.ZIP]: ssAddressShape.components.zipcode,
  };

  if (addressHasUnit(ssAddressShape)) {
    /* eslint-disable camelcase */
    const {
      primary_number = '',
      street_predirection = '',
      street_name = '',
      street_suffix = '',
    } = ssAddressShape.components;
    const street = `${street_predirection} ${street_name} ${street_suffix}`.trim();

    prequalAddressShape[AddressField.STREET] = `${primary_number} ${street}`.trim();
    prequalAddressShape[AddressField.HAS_UNIT] = true;
    /* eslint-enable camelcase */
  }

  return prequalAddressShape;
};

// if smarty streets doesnt recognize an address at all, use a parser to get a
// structured address in its place
export const getAddressStructureFromParser = (addressString: string): StructuredAddress => {
  const {
    number = '',
    prefix = '',
    street = '',
    suffix = '',
    type = '',
    city = '',
    state = '',
    zip = '',
  } = addressParser.parseLocation(addressString);

  const streetAddress = [number, prefix, street, type, suffix].filter(Boolean).join(' ');

  const prequalAddressShape: StructuredAddress = {
    [AddressField.STREET]: streetAddress,
    [AddressField.CITY]: city,
    [AddressField.STATE]: state,
    [AddressField.ZIP]: zip,
  };

  return prequalAddressShape;
};
