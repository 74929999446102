import React from 'react';
import dayjs from 'dayjs';
import DashboardModel from 'models/DashboardModel';
import i18n from './i18n';
import {
  ActivitiesWrapperStyle,
  ActivityItemDateStyle,
  ActivityItemDescriptionStyle,
  ActivityItemStyle,
  RecentActivitySectionStyle,
} from './styles';

const MAX_ACTIVITY_ITEMS = 2;

interface ActivityItemProps {
  isoDate: string;
  description: string;
}

function ActivityItem({ isoDate, description }: ActivityItemProps) {
  const date = dayjs(isoDate).format('MMM D, YYYY');
  return (
    <ActivityItemStyle>
      <ActivityItemDateStyle>{date}</ActivityItemDateStyle>
      <ActivityItemDescriptionStyle>{description}</ActivityItemDescriptionStyle>
    </ActivityItemStyle>
  );
}

interface RecentActivitySectionProps {
  dashboard: DashboardModel;
}

export function RecentActivitySection({ dashboard }: RecentActivitySectionProps) {
  if (!dashboard?.recentActivity?.length) return null;

  const activities = dashboard.recentActivity.slice(0, MAX_ACTIVITY_ITEMS);

  return (
    <RecentActivitySectionStyle headerText={i18n.recentActivity}>
      <ActivitiesWrapperStyle>
        {activities.map(({ id, date, description }) => (
          <ActivityItem key={id} isoDate={date} description={description} />
        ))}
      </ActivitiesWrapperStyle>
    </RecentActivitySectionStyle>
  );
}
