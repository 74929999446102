import { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import z from 'zod';
import { StringStorageItem } from 'lib/StorageItem';

export function useStringStorageItem(
  key: string,
  initialValue?: undefined | string | (() => undefined | string)
): [undefined | string, (newValue: SetStateAction<undefined | string>) => void] {
  const storageItem = useMemo(() => new StringStorageItem(key), [key]);

  const [state, setState] = useState<undefined | string>(
    () => storageItem.get() ?? (typeof initialValue === 'function' ? initialValue() : initialValue)
  );

  const setter = useCallback(
    (newValueOrFn: SetStateAction<undefined | string>) => {
      const newValue =
        typeof newValueOrFn === 'function' ? newValueOrFn(storageItem.get()) : newValueOrFn;

      if (newValue != null) {
        storageItem.set(newValue);
      } else {
        storageItem.remove();
      }
      setState(newValue);
    },
    [storageItem]
  );

  useEffect(() => {
    const unsubscribe = storageItem.subscribe((event) => {
      setState(event.newValue);
    });
    return unsubscribe;
  }, [storageItem]);

  return [state, setter];
}

export function useZodStorageItem<TValue extends null | string | number | boolean | object>(
  key: string,
  schema: z.ZodType<TValue>,
  getInitialValue?: undefined | (() => undefined | TValue)
): [undefined | TValue, (newValue: SetStateAction<undefined | TValue>) => void] {
  const [stringStorageValue, setStringStorageValue] = useStringStorageItem(key, () =>
    getInitialValue == null ? undefined : JSON.stringify(getInitialValue())
  );

  const parseValue = useCallback(
    (stringValue: undefined | string) => {
      try {
        if (stringValue) {
          const rawData = JSON.parse(stringValue);
          const { success, data: value } = schema.safeParse(rawData);
          if (success) {
            return value;
          }
        }
      } catch (error) {
        // Ignore any errors and return `undefined`
      }

      return undefined;
    },
    [schema]
  );

  const parsedValue = useMemo(
    () => parseValue(stringStorageValue),
    [parseValue, stringStorageValue]
  );

  const setter = useCallback(
    (newValueOrFn: SetStateAction<undefined | TValue>) => {
      setStringStorageValue((oldStringValue) => {
        const newValue =
          typeof newValueOrFn === 'function'
            ? newValueOrFn(parseValue(oldStringValue))
            : newValueOrFn;

        return newValue === undefined ? undefined : JSON.stringify(newValue);
      });
    },
    [parseValue, setStringStorageValue]
  );

  return [parsedValue, setter];
}
