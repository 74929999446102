import * as React from 'react';
import { BreadCrumbProps } from 'components/Breadcrumbs';
import { DashboardCrumbs } from 'components/DashboardTasksCrumbs';
import { MainHeaderProps } from 'components/MainHeader';
import { NavItem } from 'components/MainHeader/nav';
import ReadOnlyFlag from 'components/ReadOnlyFlag';
import { Page, generateUrlFromPage, getPathFromPage } from 'containers/helpers';
import { useDashboardLogout } from 'containers/prequal/hooks';
import { useFeatureFlag } from 'lib/featureFlags';
import { FeatureFlag } from 'lib/featureFlags';
import DashboardModel from 'models/DashboardModel';
import i18n from './i18n';
import { DashboardMainHeaderStyle } from './styles';

interface DashboardMainHeaderProps
  extends Pick<MainHeaderProps, 'navProps' | 'subHeaderProps' | 'showSubHeader' | 'hidden'> {
  dashboard: DashboardModel;
  crumbProps?: BreadCrumbProps;
}

export default function DashboardMainHeader({
  crumbProps,
  subHeaderProps,
  dashboard,
  navProps = {},
  showSubHeader = true,
  hidden,
}: DashboardMainHeaderProps): JSX.Element {
  const { dashboardLogout } = useDashboardLogout();
  const dashboardTimelineEnabled = useFeatureFlag(FeatureFlag.DashboardTimeline) === 'enabled';

  const dashboardNavItem: NavItem = {
    text: i18n.dashboard,
    path: getPathFromPage(Page.DASHBOARD),
    href: generateUrlFromPage(Page.DASHBOARD),
  };

  const tasksNavItem: NavItem = {
    text: i18n.tasks,
    path: getPathFromPage(Page.DASHBOARD_TASKS_LIST),
    href: generateUrlFromPage(Page.DASHBOARD_TASKS_LIST),
  };

  const processOverviewNavItem: NavItem = {
    text: i18n.processOverview,
    path: getPathFromPage(Page.DASHBOARD_PROCESS_OVERVIEW),
    href: generateUrlFromPage(Page.DASHBOARD_PROCESS_OVERVIEW),
  };

  const costEstimatorNavItem: NavItem = {
    text: i18n.costEstimator,
    path: getPathFromPage(Page.DASHBOARD_ESTIMATOR),
    href: generateUrlFromPage(Page.DASHBOARD_ESTIMATOR),
  };

  const logOutNavItem: NavItem = {
    text: i18n.logOut,
    action: () => {
      dashboardLogout();
    },
  };

  const navItems: Array<NavItem> = dashboardTimelineEnabled
    ? [dashboardNavItem, tasksNavItem, costEstimatorNavItem, logOutNavItem]
    : [dashboardNavItem, tasksNavItem, processOverviewNavItem, costEstimatorNavItem, logOutNavItem];

  return (
    <>
      {dashboard.readOnly && <ReadOnlyFlag />}
      <DashboardMainHeaderStyle
        hidden={hidden}
        navItems={navItems}
        navProps={{
          ...navProps,
          showAsPopup: true,
          hidePopupTitle: true,
        }}
        showSubHeader={showSubHeader}
        subHeaderProps={{
          leftContent: <DashboardCrumbs {...crumbProps} />,
          hideCrumbs: !crumbProps?.currentCrumb,
          ...subHeaderProps,
        }}
      />
    </>
  );
}
