import styled, { css } from 'styled-components';
import { HelpTextStyles, LoaderStyles, ProgressLoaderStyles, mixins } from '@pointdotcom/pds';
import { pxToRem } from 'styleHelpers';
import { FileIconStyle, TrashCanIconStyle, primaryFileUploadColor } from './iconStyles';

export const UploadedFileNameStyle = styled.div.attrs({ className: 'UploadedFileNameStyle' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: var(--content-opacity);
`;

export const UploadedFileItemRowStyle = styled.li.attrs({ className: 'UploadedFileItemRowStyle' })<{
  isError: boolean;
  isDeleting?: boolean;
}>`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  gap: 0.75rem;
  --content-opacity: 1;

  ${({ isError, theme }) =>
    isError &&
    css`
      color: ${theme.Color.Red};
    `}
  ${({ isDeleting }) =>
    isDeleting &&
    css`
      --content-opacity: 0.33;
    `}

  --border-width: 2px;
  padding: 1.5rem 0 calc(1.5rem + var(--border-width));
  position: relative;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    --border-width: 1px;

    padding: 1.2rem ${({ theme }) => theme.container.gutter.default}
      calc(1.2rem + var(--border-width));

    &:first-child {
      border-top: var(--border-width) solid ${({ theme }) => theme.line.color.default};
    }
  }

  ${HelpTextStyles.HelpTextStyle} {
    color: ${({ theme }) => theme.Color.Red};
    opacity: var(--content-opacity);
    grid-column: 1 / 4;
  }
`;

export const UploadedFileRowProgressBar = styled.div.attrs({
  className: 'UploadedFileRowProgressBar',
})`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  height: var(--border-width);
  background: ${({ theme }) => theme.line.color.default};

  > div {
    background: ${primaryFileUploadColor};
    height: 100%;
  }
`;

export const UploadedFilesContainerStyle = styled.ul.attrs({
  className: 'UploadedFilesContainerStyle',
})`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const UploadedFileRowsContainerStyle = styled(UploadedFilesContainerStyle).attrs({
  className: 'UploadedFileRowsContainerStyle',
})``;

export const UploadedFilePreviewsScrollerStyle = styled.div.attrs({
  className: 'UploadedFilePreviewsContainerStyle',
})`
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    display: grid;
    overflow-x: auto;
    padding: var(--gap) 0;
    border-top: 1px solid ${({ theme }) => theme.line.color.default};
    border-bottom: 1px solid ${({ theme }) => theme.line.color.default};
  }
`;

export const UploadedFilePreviewItemStyle = styled.li.attrs({
  className: 'UploadedFilePreviewItemStyle',
})<{
  hasError: boolean;
  isDeleting?: boolean;
}>`
  --primary-file-upload-color: ${({ theme }) => theme.Color.Yorange};

  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 140px;
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};

  ${({ hasError, theme }) =>
    hasError &&
    css`
      color: ${theme.Color.Red};
    `}
  ${({ isDeleting }) =>
    isDeleting &&
    css`
      --content-opacity: 0.33;
    `};

  ${TrashCanIconStyle} {
    width: 18px;
  }

  ${ProgressLoaderStyles.ProgressLoaderSvgStyle} {
    & circle[stroke-dasharray] {
      stroke: ${primaryFileUploadColor};
    }
  }
`;

export const UploadedFilePreviewsContainerStyle = styled(UploadedFilesContainerStyle).attrs({
  className: 'UploadedFilePreviewsContainerStyle',
})`
  gap: 0.875rem;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    display: flex;
    flex-wrap: nowrap;
  }

  @media not (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    @media not (max-width: ${({ theme }) => theme.responsive.largestSmallMonitor}) {
      grid-template-columns: repeat(6, 1fr);
    }

    > ${UploadedFilePreviewItemStyle} {
      width: auto;
      min-width: 0;
    }
  }

  > * {
    flex-shrink: 0;
  }
`;

export const UploadedFilePreviewSpaceStyle = styled.div.attrs({
  className: 'UploadedFilePreviewSpaceStyle',
})`
  &::before {
    content: '';
    display: block;
    padding-top: 120%;
  }

  position: relative;
`;

export const UploadedFilePreviewStyle = styled.button.attrs({
  className: 'UploadedFilePreviewStyle',
})<{ hasError?: boolean; isClickable: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  --border-color: ${({ theme, hasError }) =>
    hasError ? mixins.rgba(theme.Color.Red, 0.1) : theme.Color.GrayLight1};
  background: var(--border-color);

  ${({ theme, isClickable }) =>
    isClickable &&
    css`
      transition: all 180ms ease-in-out;
      cursor: pointer;

      &:focus {
        background: ${theme.line.color.default};
      }
      ${mixins.desktopHoverCondition()} {
        &:hover {
          transform: scale(1.01);
          background: ${theme.line.color.default};};
        }
      }
    `}
`;

export const UploadedFilePreviewImageStyle = styled.div.attrs({
  className: 'UploadedFilePreviewImageStyle',
})`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid transparent;

  background-color: ${({ theme }) => theme.Color.White};
  background-clip: content-box;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;
  justify-content: center;

  ${FileIconStyle} {
    width: 3rem;
    height: 3rem;
    path {
      fill: ${({ theme }) => theme.Color.Gray1};
    }
  }
`;

export const UploadedFilePreviewNameStyle = styled.div.attrs({
  className: 'UploadedFilePreviewNameStyle',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: ${pxToRem(14)};
  gap: 1em;
  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const UploadedFilePreviewErrorStyle = styled.div.attrs({
  className: 'UploadedFilePreviewErrorStyle',
})`
  margin: 1em;
  text-align: center;
  color: ${({ theme }) => theme.Color.Red};
`;

export const UploadedFilePreviewButtonRowStyle = styled.div.attrs({
  className: 'UploadedFilePreviewButtonRowStyle',
})`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const IconButtonStyle = styled.button.attrs({ className: 'IconButtonStyle' })<{
  isLoading?: boolean;
}>`
  position: relative;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  ${({ isLoading = false }) =>
    !isLoading &&
    css`
      cursor: pointer;
    `}

  ${LoaderStyles.LoaderStyle} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  ${LoaderStyles.LoaderBorderStyle} {
    border-color: ${({ theme }) => theme.line.color.default};
    border-top-color: ${primaryFileUploadColor};
  }
`;
