import React from 'react';
import { generateUrlFromPage, pages } from 'containers/helpers';
import useLinkProps from 'containers/hooks/useLinkProps';
import DashboardTasks from 'containers/tasks/DashboardTasks';
import DashboardModel from 'models/DashboardModel';
import { TaskStatus } from 'services/apiTypes/taskTypes';
import i18n from './i18n';
import { NoOpenTasksContentStyle, TaskListSectionStyle } from './styles';

interface TaskListSectionProps {
  dashboard: DashboardModel;
}

export function TaskListSection({ dashboard }: TaskListSectionProps) {
  const linkProps = useLinkProps(generateUrlFromPage(pages.DASHBOARD_TASKS_LIST));
  if (!dashboard) return null;

  const openTasks = dashboard?.getTasks({
    status: [TaskStatus.Active, TaskStatus.SubmissionInProgress],
  });
  const completedTasks = dashboard?.getTasks({ status: TaskStatus.Submitted });
  const hasOpenTasks = !!openTasks?.length;
  const hasCompletedTasks = !!completedTasks?.length;

  let content = (
    <NoOpenTasksContentStyle>
      <p>{i18n.wereWorkingHard}</p>
      {hasCompletedTasks && <a {...linkProps}>{i18n.viewSubmitted}</a>}
    </NoOpenTasksContentStyle>
  );

  if (hasOpenTasks) {
    content = <DashboardTasks tasks={openTasks} limit={3} />;
  }

  return <TaskListSectionStyle headerText={i18n.yourTasks}>{content}</TaskListSectionStyle>;
}
