import styled, { css } from 'styled-components';
import { HeaderStyles, IconStyles, mixins } from '@pointdotcom/pds';
import { PlayIconStyle } from 'components/VideoPlayer/PlayButton';
import { pxToRem } from 'styleHelpers';
import { LifeSaverIconStyle } from '../../styles';

export const FundingResourcesSectionStyle = styled.section.attrs({
  className: 'FundingResourcesSectionStyle',
})`
  padding: calc(var(--blockPadding) + 1rem) 0 calc(var(--blockPadding) + 3rem);
  justify-content: center;
  display: flex;
  flex-flow: column nowrap;
  gap: clamp(2rem, 12vh, 5rem);
  & ${LifeSaverIconStyle} {
    height: ${pxToRem(48)};
  }
`;

export const SectionHeaderStyle = styled.div.attrs({ className: 'SectionHeaderStyle' })`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  ${HeaderStyles.HeaderStyle} {
    > * {
      color: ${({ theme }) => theme.Color.GrayLightestAccessible};
    }
  }
`;

export const ResourceItemsStyle = styled.div.attrs({ className: 'ResourceItemsStyle' })`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  // Handle case where Estimator is not present
  @media not (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    &:not(:has(:nth-child(3))) {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    grid-template-columns: 1fr;
  }
`;

export const ResourceItemStyle = styled.div.attrs({ className: 'ResourceItemStyle' })`
  // Make buttons and links look the same
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: none;
  background: transparent;
  text-align: start;
  cursor: pointer;
  text-decoration: none;
  white-space: normal;
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};

  ${IconStyles.IconStyle} path, span, ${PlayIconStyle}, ${PlayIconStyle}:after {
    transition: all 220ms ease-in-out;
  }

  ${mixins.hover(css`
    color: ${({ theme }) => theme.Color.PointBlack};
    ${PlayIconStyle} {
      --color: ${({ theme }) => theme.Color.PointBlue};
    }
    ${IconStyles.IconStyle} {
      path {
        fill: ${({ theme }) => theme.Color.PointBlue};
      }
    }
  `)}

  span {
    font-family: ${({ theme }) => theme.fonts.sansSerif.name};
    font-size: ${pxToRem(14)};
  }

  // Desktop
  @media not (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    padding-inline: 1.75rem;
    border-right: 1px solid ${({ theme }) => theme.line.color.default};

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    border-bottom: 1px solid ${({ theme }) => theme.line.color.default};
    padding-right: var(--inlinePadding);
    margin-left: var(--inlinePadding);

    padding-block: 1.5rem;
    &:first-child {
      padding-top: 0;
    }
  }
`;

export const ItemTitleStyle = styled.div.attrs({ className: 'ItemTitleStyle' })`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  > * {
    &:last-child {
      flex: 0 0 auto;
    }
  }
  ${HeaderStyles.HeaderStyle} {
    > * {
      color: ${({ theme }) => theme.Color.PointBlue};
      font-size: ${pxToRem(16)};
      ${css`
        text-wrap: balance;
      `}

      @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
        font-size: ${pxToRem(15)};
      }
    }
  }
`;
