import React from 'react';
import { DirectionAndPlacement, Header, HeaderProps, Size, Style } from '@pointdotcom/pds';
import * as styles from './styles';

interface HeaderUpperLowerProps extends HeaderProps {
  capsText?: string;
  boldText?: string;
  capsOnTop?: boolean;
}

const HeaderUpperLower = ({
  styleAlign = DirectionAndPlacement.Left,
  styleAlignMobile = DirectionAndPlacement.Left,
  capsText = '',
  styleSize = Size.Small,
  boldText = '',
  capsOnTop = true,
}: HeaderUpperLowerProps) => {
  const CapsHeader = (
    <styles.HeaderCapsStyle
      styleAlign={styleAlign}
      styleAlignMobile={styleAlignMobile}
      styleType={Style.Uppercase}
    >
      {capsText}
    </styles.HeaderCapsStyle>
  );
  const BoldHeader = (
    <Header
      styleAlign={styleAlign}
      styleAlignMobile={styleAlignMobile}
      noMargin
      styleSize={styleSize}
    >
      {boldText}
    </Header>
  );

  return (
    <styles.HeaderUpperLowerStyle>
      {capsOnTop ? CapsHeader : BoldHeader}
      {capsOnTop ? BoldHeader : CapsHeader}
    </styles.HeaderUpperLowerStyle>
  );
};

export default HeaderUpperLower;
