import { getMiddlewareHost } from 'lib/hosts';
import { YCBMCal } from './constants';

export const getCalendarFromYCBMCalendarURL = (url: string) => {
  return url ? new URL(url).hostname.split('.')[0] : null;
};

export const getListOfCalendars = () => {
  return Object.values(YCBMCal);
};

export const getYCBMAPIDomain = () => {
  return `${getMiddlewareHost()}/api/youcanbook`;
};

export const getYCBMCalendarURLFromCalendar = (calendar: string | YCBMCal) => {
  return `https://${calendar}.youcanbook.me`;
};

export const getStateCalendar = (state: string) => {
  return `mypoint-${state}`;
};
