import { useEffect, useRef } from 'react';
import { useBlocker } from 'react-router';

export function usePageLeaveConfirmation(shouldConfirm: () => void | false | string) {
  const shouldConfirmRef = useRef(shouldConfirm);
  useEffect(() => {
    shouldConfirmRef.current = shouldConfirm;
  }, [shouldConfirm]);

  useBlocker(() => {
    const confirmationMessage = shouldConfirmRef.current();
    if (typeof confirmationMessage == 'string') {
      const shouldBlock = !confirm(confirmationMessage);
      return shouldBlock;
    } else {
      return false;
    }
  });

  useEffect(() => {
    const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
      const confirmationMessage = shouldConfirmRef.current();
      if (typeof confirmationMessage == 'string') {
        // Call preventDefault() to show confirmation to user:
        e.preventDefault();
        // Support older browsers too:
        return '';
      }
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => window.removeEventListener('beforeunload', beforeUnloadHandler);
  }, []);
}
