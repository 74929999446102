import styled, { css } from 'styled-components';
import { Container, SplashTextStyles, mixins } from '@pointdotcom/pds';
import { ChatButtonStyle } from 'components/ChatlioButton/styles';
import { pxToRem } from 'styleHelpers';

export const SupportOptionsStyle = styled(Container).attrs({
  className: 'SupportOptionsStyle',
})`
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  padding-inline: 5rem;
  display: flex;
  justify-content: center;
  gap: 2.5rem;

  & ${ChatButtonStyle} {
    width: 20rem;
    @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
      width: 100%;
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.25rem;
    padding-inline: var(--inlinePadding);
  }
`;

export const ContactMethodsDividedStyle = styled.div.attrs({
  className: 'ContactMethodsDividedStyle',
})`
  display: flex;
  gap: 0.5rem;

  div {
    border-right: ${({ theme }) => `1px solid ${theme.line.color.default}`};
  }

  @media (max-width: ${({ theme }) => theme.responsive.mobileMidBreakpoint}) {
    flex-direction: column;
    text-align: center;

    div {
      width: 100%;
      align-self: center;
      border-bottom: ${({ theme }) => `1px solid ${theme.line.color.default}`};
    }
  }
`;

export const AccountManagerStyle = styled.div.attrs({ className: 'AccountManagerStyle' })`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: ${pxToRem(15)};

  ${SplashTextStyles.SplashCopySerifStyle} {
    font-size: ${pxToRem(15)};
  }

  ${mixins.hover(css`
    img {
      filter: none;
    }
  `)}
`;

const ContactRowStyle = styled.div.attrs({ className: 'ContactRowStyle' })`
  display: grid;
  grid-template-columns: 5rem 1fr;
  align-items: center;
  gap: 1rem;
  padding-inline: 1rem;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    display: flex;
    flex-direction: column;
    border: none;
    padding-inline: 0;
  }
`;

export const AMIntroStyle = styled(ContactRowStyle).attrs({ className: 'AMIntroStyle' })`
  padding-bottom: 1rem;
  border-bottom: ${({ theme }) => `1px solid ${theme.line.color.default}`};
  span {
    font-weight: bold;
  }

  // Handle missing AM image
  &:not(:has(:nth-child(2))) {
    grid-template-columns: 1fr;
    justify-items: end;
  }

  img {
    justify-self: center;
    height: ${pxToRem(50)};
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    object-fit: cover;
    filter: grayscale(100%);
    transition: filter 0.15s ease-in;
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    padding-bottom: 0;
    border: none;
  }
`;

export const AMDetailStyle = styled(ContactRowStyle).attrs({ className: 'AMDetailStyle' })`
  text-align: center;
`;

export const DividerStyle = styled.div.attrs({ className: 'DividerStyle' })`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    flex-direction: row;
  }
`;

export const LineStyle = styled.div.attrs({ className: 'LineStyle' })`
  background-color: ${({ theme }) => theme.line.color.default};
  width: 1px;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    width: 100%;
    height: 1px;
  }
`;
