import styled, { css } from 'styled-components';
import { HeaderStyles, SplashTextStyles, mixins } from '@pointdotcom/pds';
import { HeaderUpperLowerStyle } from 'components/HeaderUpperLower/styles';
import { Table2ColStyle } from 'components/Table2Col/styles';
import { PaddedSectionStyle } from 'styles/';

const HeroSectionStyle = styled.section.attrs({ className: 'HeroSectionStyle' })`
  ${HeaderStyles.HeaderStyle} {
    &.splash {
      margin-bottom: 2%;
      @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
        h1 {
          font-size: ${({ theme }) => mixins.getRemFromPx(27, theme.globals.baseFontSize.mobile)};
        }
      }
    }
    &.massive {
      @media only print {
        h1 {
          font-size: ${({ theme }) => mixins.getRemFromPx(55, theme.globals.baseFontSize.default)};
        }
      }
    }
  }
`;

export const PreviewOfferAmountHeroHeading = styled.div.attrs({
  className: 'PreviewOfferAmountHeroHeading',
})`
  text-align: center;
`;

export const PreviewOfferAmountHeroAmount = styled.div.attrs({
  className: 'PreviewOfferAmountHeroAmount',
})`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 2rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: unset;
  }
  aside {
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
    max-width: 26em;
  }
`;

export const PreviewOfferAmountHeroSelfServe = styled.div.attrs({
  className: 'PreviewOfferAmountHeroSelfServe',
})`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    margin: 0;
  }
`;

export const PreviewOfferAmountHero = styled.div.attrs({ className: 'PreviewOfferAmountHero' })<{
  hasSelfServeOffer: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  > * {
    text-align: center;
    h1 {
      text-align: unset;
    }
  }

  ${PreviewOfferAmountHeroHeading} {
    grid-column: 1 / span 2;
  }
  ${PreviewOfferAmountHeroAmount} {
    ${({ hasSelfServeOffer }) =>
      !hasSelfServeOffer &&
      css`
        grid-column: 1 / span 2;
      `}
  }
  @media not (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    ${PreviewOfferAmountHeroAmount} + ${PreviewOfferAmountHeroSelfServe} {
      border-color: ${({ theme }) => theme.line.color.default};
      border-style: solid;
      border-width: 0;
      border-left-width: 1px;
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    grid-template-columns: 1fr;
    --top-bottom-weight: 8vh;

    ${PreviewOfferAmountHeroHeading} {
      grid-column: unset; /* Span across two columns */
    }
    ${PreviewOfferAmountHeroAmount} {
      padding-inline: 12vw;
      padding-top: calc(var(--top-bottom-weight) - 1rem);
      ${HeaderStyles.HeaderStyle} > * {
        font-size: 16vw;
      }
    }
    ${PreviewOfferAmountHeroAmount}, ${PreviewOfferAmountHeroSelfServe} {
      aside,
      ${SplashTextStyles.SplashCopySerifStyle} {
        font-family: ${({ theme }) => theme.fonts.sansSerif.name};
        font-size: inherit;
        color: ${({ theme }) => theme.Color.GrayLightestAccessible};
      }
    }
    ${PreviewOfferAmountHeroSelfServe} {
      position: relative;
      padding-bottom: var(--top-bottom-weight);
      &:before {
        content: '';
        display: block;
        width: 10vw;
        height: 1px;
        background-color: ${({ theme }) => theme.line.color.default};
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      border-bottom: 1px solid ${({ theme }) => theme.line.color.default};
    }
  }
`;

const tablePadding = '5%';
const tablePaddingMobile = '11%';
const tablePaddingPrint = ' 20px';

const TableHeaderLeftRightStyle = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: ${tablePadding};
  align-items: center;
  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    padding: 0;
  }
  @media only print {
    padding: ${tablePaddingPrint};
  }
`;

const TableHeaderLeftStyle = styled(TableHeaderLeftRightStyle).attrs({
  className: 'TableHeaderLeftStyle',
})`
  flex: 1;
  min-width: 250px;
  border-width: 0;
  border-right-width: 1px;
  border-style: solid;
  justify-content: center;
  padding-left: 0;
  ${HeaderUpperLowerStyle}, ${HeaderStyles.HeaderStyle} {
    color: inherit;
  }
  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    border-right-width: 0;
    border-bottom-width: 1px;
    min-width: 0;
    padding-bottom: ${tablePaddingMobile};
    justify-content: left;
  }
  @media only print {
    flex: 25%;
    width: 25%;
    min-width: auto;
    justify-content: flex-start;
    padding-left: 0;
  }
`;

const TableHeaderRightStyle = styled(TableHeaderLeftRightStyle).attrs({
  className: 'TableHeaderRight',
})`
  color: inherit;
  flex: 3;
  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    padding-top: ${tablePaddingMobile};
  }
  @media only print {
    flex: 75%;
    width: 75%;
  }
`;

const TableContainerStyle = styled.div.attrs({ className: 'TableContainerStyle' })<{
  highlighted?: boolean;
  last?: boolean;
}>`
  padding: ${tablePadding};
  background-color: ${({ theme, highlighted }) =>
    highlighted ? theme.Color.DarkYorange1 : theme.Color.GrayLight1};
  color: ${({ theme, highlighted }) =>
    highlighted ? theme.Color.White : theme.globals.baseFontColor};
  margin-bottom: ${({ last }) => (last ? '0' : '1.5rem')};
  ${TableHeaderLeftStyle} {
    border-color: ${({ theme, highlighted }) =>
      highlighted ? mixins.rgba(theme.line.color.inverted, 0.23) : theme.line.color.default};
  }
  ${Table2ColStyle} {
    margin-top: ${tablePadding};
  }
  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    padding: ${tablePaddingMobile};
    button {
      white-space: normal;
    }
  }
  @media only print {
    padding: ${tablePaddingPrint};
    margin-bottom: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.line.color.default};
    &:last-child {
      border-bottom: none;
    }
    ${Table2ColStyle} {
      margin: 0;
    }
  }
`;

const TableHeaderStyle = styled.div.attrs({ className: 'TableHeaderStyle' })`
  display: flex;
  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    flex-flow: column;
    padding: ${tablePadding};
  }
`;

const OfferContentStyle = styled(PaddedSectionStyle).attrs({ className: 'OfferContentStyle' })`
  padding-top: 0 !important;
`;

const DownloadPDFSectionStyle = styled.div.attrs({ className: 'DownloadPDFSectionStyle' })<{
  noMargin?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ noMargin }) => (noMargin ? '0' : '150px')};
`;

export {
  DownloadPDFSectionStyle,
  HeroSectionStyle,
  OfferContentStyle,
  TableContainerStyle,
  TableHeaderStyle,
  TableHeaderLeftStyle,
  TableHeaderRightStyle,
};
