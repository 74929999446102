import * as z from "zod";

export const contactSchema = z
  .object({
    email: z.string(),
    firstName: z.string(),
    lastName: z.string().optional(),
    phone: z.string().optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type Contact = z.infer<typeof contactSchema>;

export const contractExitDetailsSchema = z
  .object({
    contact: contactSchema,
    optionId: z.string(),
    dateFunded: z.string(),
    homeValue: z.number(),
    payoffEstimate: z.number(),
    payoffDate: z.string(),
    quarterlyPayoffDate: z.string(),
    capped: z.boolean(),
    investmentPayment: z.number(),
    optionPercentage: z.number(),
    originalAgreedValue: z.number(),
    appreciation: z.number(),
    pointShare: z.number(),
    capAmount: z.number(),
    capPercentage: z.number(),
    uncappedAmount: z.number(),
    contractTerm: z.number(),
    contractEndDate: z.string(),
    subservicerId: z.string(),
  })
  .catchall(z.unknown() as z.ZodType);
export type ContractExitDetails = z.infer<typeof contractExitDetailsSchema>;

export const payoffProjectionSchema = z
  .object({
    payoffDate: z.string(),
    payoffEstimate: z.number(),
    capped: z.boolean(),
  })
  .catchall(z.unknown() as z.ZodType);
export type PayoffProjection = z.infer<typeof payoffProjectionSchema>;

export const contractProjectionsSchema = z
  .object({
    optionId: z.string(),
    projectedAppreciationRate: z.number(),
    payoffProjections: z.array(payoffProjectionSchema),
  })
  .catchall(z.unknown() as z.ZodType);
export type ContractProjections = z.infer<typeof contractProjectionsSchema>;

export enum ContractErrorCode {
  OutOfRange = "OutOfRange",
  InvalidFormat = "InvalidFormat",
  Unknown = "Unknown",
}
export const contractErrorCodeSchema = z.nativeEnum(ContractErrorCode);

export const contractErrorsSchema = z
  .object({
    homeValue: contractErrorCodeSchema.optional(),
    payoffDate: contractErrorCodeSchema.optional(),
    projectedAppreciationRate: contractErrorCodeSchema.optional(),
    unknown: contractErrorCodeSchema.optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type ContractErrors = z.infer<typeof contractErrorsSchema>;
