import styled, { css } from 'styled-components';
import { Button, Size, Style, mixins } from '@pointdotcom/pds';

export const VerificationErrorStyle = styled.div.attrs({ className: 'VerificationErrorStyle' })`
  --padding-inline: ${({ theme }) => theme.container.gutter.default};
  --padding-block: 2rem;
  background-color: ${({ theme }) => mixins.rgba(theme.Color.Red, 0.1)};
  color: ${({ theme }) => theme.Color.Red};
  margin-bottom: 2rem;
  > div {
    padding: var(--padding-block) var(--padding-inline);
    display: grid;
    gap: 1rem;
    > * {
      display: grid;
      gap: 1rem;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  hr {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${({ theme }) => mixins.rgba(theme.Color.Red, 0.15)};
  }
  h5 {
    font-weight: bold;
    font-size: inherit;
    margin: 0;
  }
  aside {
    background-color: ${({ theme }) => mixins.rgba(theme.Color.Red, 0.08)};
    padding: calc(var(--padding-block) / 2) var(--padding-inline);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1em;
  }
  ul {
    margin: 0;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    --padding-block: var(--padding-inline);
    margin-bottom: ${({ theme }) => theme.container.gutter.default};
    aside {
      flex-flow: column nowrap;
      gap: 0.5em;
      padding-block: var(--padding-block);
    }
  }
`;

export const ErrorButtonStyle = styled(Button).attrs({
  className: 'ErrorButtonStyle',
  styleSize: Size.Small,
  styleType: Style.Error,
})`
  button {
    color: white;
    background: transparent;
    font-size: inherit;
    border-color: ${({ theme }) => theme.Color.Red};
    background-color: ${({ theme }) => theme.Color.Red};
    padding: 0.8em 1em;
    ${mixins.hover(css`
      background-color: ${({ theme }) => mixins.lightenDarkenColor(theme.Color.Red, -0.05)};
      border-color: transparent;
    `)}
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    width: 100%;
    button {
      padding-block: ${({ theme }) => theme.button.padding.small.topBottom};
    }
  }
`;
