import styled from 'styled-components';
import { Device, Size } from '@pointdotcom/pds';
import { WarningIconProps } from './constants';

const warningIconSizeMap = {
  [Size.Default]: {
    [Device.Default]: '1.8rem',
    [Device.Mobile]: '1.5rem',
  },
  [Size.Small]: {
    [Device.Default]: '1.5rem',
    [Device.Mobile]: '1.2rem',
  },
};

const warningIconFontSizeMap = {
  [Size.Default]: {
    [Device.Default]: '1.2rem',
    [Device.Mobile]: '0.9rem',
  },
  [Size.Small]: {
    [Device.Default]: '0.9rem',
    [Device.Mobile]: '0.7rem',
  },
};

export const WarningIconStyle = styled.div.attrs({
  className: 'WarningIconStyle',
  role: 'img',
  'aria-hidden': 'true',
})<WarningIconProps>`
  --size: ${({ styleSize }) => warningIconSizeMap[styleSize ?? Size.Default][Device.Default]};
  --fontSize: ${({ styleSize }) =>
    warningIconFontSizeMap[styleSize ?? Size.Default][Device.Default]};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  background: ${({ theme, strongWarning }) =>
    strongWarning ? theme.Color.Red : theme.Color.Yorange};
  font-weight: bold;
  color: white;
  font-size: var(--fontSize);
  border-radius: 50%;
  user-select: none;
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    --size: ${({ styleSize }) => warningIconSizeMap[styleSize ?? Size.Default][Device.Mobile]};
    --fontSize: ${({ styleSize }) =>
      warningIconFontSizeMap[styleSize ?? Size.Default][Device.Mobile]};
  }
`;
