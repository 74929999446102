export default {
  attachDocumentsOptional: 'Attach documents (optional)',
  attachSupportingDocumentsOptional: 'Attach supporting documents (optional)',
  documentRequirements: 'Document requirements',
  documentRequirementsList: [
    'The document(s) must be valid',
    'The document(s) must be fully legible',
    'The document(s) must be complete  (including all pages, even those with fine print)',
    'The name and address on the document(s) must match the name on your Point application',
  ],
  genericQuestionTitle: 'Follow-up',
  haveAQuestion: 'Have a question?',
  helpWithThisDocument: 'Help with this document',
  notesAboutAttachments: 'Notes about the attachments (optional):',
  responsePlaceholder: 'Response:',
  submit: 'Submit',
  submitting: 'Submitting',
  uploadDocument: 'Upload document',
  verify: 'Verify',
  verifyingYourDocument: 'Verifying your document',
  yourDocumentsHaventBeenSubmitted:
    'Heads up! Your documents haven’t been submitted yet. Are you sure you want to leave without submitting?',
};
