import { Page, pages } from 'containers/helpers';
import bugsnagClient from 'lib/bugsnagClient';
import { Products } from 'store/constants';
import { MORE_INFO_REQUIRED, PQ_APPLICATION_STATUS } from 'types';

const prequalRejectionUrl = process.env.REACT_APP_REJECTION_REDIRECT_URL;
const redirectExemptSources = (process.env.REACT_APP_REJECTION_EXEMPT_SOURCECODES || '')
  .replaceAll(' ', '')
  .split(',');

export const nextPageFromResponse = (
  product: Products,
  response: TSFixMe,
  utmSource: string
): Page | URL => {
  const { status, problems } = response.products[product];

  let nextPage = pages.PREQUAL_INELIGIBLE;
  if (status === PQ_APPLICATION_STATUS.NEEDS_MORE_INFORMATION && problems?.length) {
    // figure out which page is next to get missing info
    const missingData = problems[0].code;
    if (missingData === MORE_INFO_REQUIRED.SSN) {
      nextPage = pages.PREQUAL_SSN;
    } else if (missingData === MORE_INFO_REQUIRED.FICO) {
      nextPage = pages.PREQUAL_CREDIT_SCORE;
    } else if (missingData === MORE_INFO_REQUIRED.MORTGAGE) {
      nextPage = pages.PREQUAL_MORTGAGE_BALANCE;
    }
  } else if (status === PQ_APPLICATION_STATUS.WAITLIST) {
    nextPage = pages.PREQUAL_WAITLIST_SIGNUP;
  }

  // Most rejections should proceed to a marketing page but some UTM sources cannot be further marketed to
  if (
    nextPage === pages.PREQUAL_INELIGIBLE &&
    prequalRejectionUrl &&
    !redirectExemptSources.includes(utmSource)
  ) {
    try {
      return new URL(prequalRejectionUrl);
    } catch {
      bugsnagClient.notify(`Invalid rejection redirect URL: ${prequalRejectionUrl}`);
    }
  }

  return nextPage;
};

export class NotInServiceAreaError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'NotInServiceAreaError';
  }
}

export function verifyServiceArea(product: Products, availableProducts: Products[]) {
  const productFound = availableProducts.includes(product);

  // we want to explicitly throw an error here if the product is not available
  if (!productFound) {
    throw new NotInServiceAreaError();
  }
}
