import { DirectionAndPlacement } from '@pointdotcom/pds';
import { OfferStatus } from 'services/apiTypes/prequalTypes';

export type BuiltInPosthogEventName = `$${string}`;

export enum CustomPosthogEventName {
  ApplicationCreated = 'application created',
  ApplicationSubmitted = 'application submitted',
  CarouselItemViewed = 'carousel item viewed',
  DashboardLoggedIn = 'dashboard logged in',
  DashboardTimelineStageExpanded = 'dashboard timeline stage expanded',
  DashboardTimelineStepExpanded = 'dashboard timeline step expanded',
  DocketCreated = 'docket created',
  EstimateCreated = 'estimate created',
  FinalOfferAccepted = 'final offer accepted',
  InitialEstimateAccpeted = 'initial estimate accepted',
  ModalClosed = 'modal closed',
  ModalOpened = 'modal opened',
  PayoffEstimatorLoaded = 'payoff estimator loaded',
  PricingCarouselNavigation = 'pricing carousel navigation',
  PricingCarouselStarted = 'pricing carousel started',
  ScrolledIntoView = 'scrolled into view',
}

export type PosthogEventName = BuiltInPosthogEventName | CustomPosthogEventName;

export interface MetadataProperty {
  date_estimate_expires?: string | null | undefined;
  email?: string | null | undefined;
  estimate_max_option_amount?: number | null | undefined;
  first_name?: string | null | undefined;
  home_value_input?: number | null | undefined;
  last_name?: string | null | undefined;
  phone?: string | null | undefined;
  status?: OfferStatus | null | undefined;
}

export enum PaginationMethod {
  Swipe = 'Swipe',
  LeftRightButton = 'LeftRightButton',
  PrevNextButton = 'PrevNextButton',
}

export type PosthogProperties = {
  // Custom properties
  button?: string | null | undefined;
  carousel_item_title?: string | null | undefined;
  carousel_scenario?: string | null | undefined;
  dashboard_timeline_stage?: string | null | undefined;
  dashboard_timeline_step_status?: string | null | undefined;
  dashboard_timeline_step?: string | null | undefined;
  date_estimate_expires?: string | null | undefined;
  direction?: DirectionAndPlacement.Left | DirectionAndPlacement.Right | null | undefined;
  docket_id?: string | null | undefined;
  element?: string | null | undefined;
  email?: string | null | undefined;
  estimate_key?: string | null | undefined;
  estimate_max_option_amount?: number | null | undefined;
  first_name?: string | null | undefined;
  home_value_input?: number | null | undefined;
  item?: number | null | undefined;
  key?: string | null | undefined;
  last_name?: string | null | undefined;
  metadata?: MetadataProperty;
  method?: PaginationMethod | null | undefined;
  object?: string | null | undefined;
  option_id?: string | null | undefined;
  overlay?: string | null | undefined;
  page?: string | null | undefined;
  phone?: string | null | undefined;
  status?: OfferStatus | null | undefined;
  table_row?: string | null | undefined;
  workflow?: string | null | undefined;

  // Built-in properties
  [key: `$${string}`]: string | number | null | undefined;
};
