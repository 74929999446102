/* eslint-disable @typescript-eslint/no-explicit-any */
// We need no-explicit-any here because the videojs types are bad and we need to use any to get around it
import React, { RefObject, memo, useCallback, useEffect, useMemo } from 'react';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
import 'video.js/dist/video-js.css';
import { Icon, IconName, useIsMobile } from '@pointdotcom/pds';
import { logError, logInfo } from 'lib/logger';
import { usePostVideoProgressMutation } from 'services/api/homeownerApi';
import { VideoApiResponse, VideoChapters } from 'services/apiTypes/videoTypes';
import { getHEIEstimateModel } from 'store/estimates';
import { useSelector } from 'store/hooks';
import { getVideoReferencePosition } from 'store/productQuiz';
import i18n from '../../containers/ProductQuizPage/i18n';
import { imgThumbnail } from './VideoButton';
import * as styles from './styles';

videojs.registerComponent('QuizButton', videojs.getComponent('Button'));

interface VideoJSPlayerProps {
  skipTo: number;
  options: any;
  onReady?: (player: any) => void;
  onHandleFullScreenVideoControls?: (player: any) => void;
}

const VideoJSPlayer = memo(
  ({
    options: _options,
    skipTo = 0,
    onReady,
    onHandleFullScreenVideoControls,
  }: VideoJSPlayerProps) => {
    const videoRef = React.useRef<HTMLDivElement>(null);
    const playerRef = React.useRef<any>(null);
    const { isMobile, isLandscape } = useIsMobile();

    const options = useMemo(() => {
      return {
        debug: true,
        autoplay: true,
        poster: imgThumbnail,
        muted: false,
        controls: true,
        responsive: true,
        playsinline: true,
        fluid: true,
        errorDisplay: process.env.REACT_APP_ENV !== 'test',
        suppressNotSupportedError: process.env.REACT_APP_ENV === 'test',
        ..._options,
      };
    }, [_options]);

    // For simplicity, this function is defined inside the component to access any props or state we need.
    const handleVideoControls = useCallback(
      (player?: Player) => {
        // depending on wether the video is shown before or after the quiz started,
        // we need to customize the controls accordingly
        if (!player) {
          return;
        }

        const controlBar = player.getChild('ControlBar');
        if (!controlBar) {
          return;
        }
        // const playButton = controlBar.getChild('PlayToggle');
        // const volumeControl = controlBar.getChild('VolumePanel');
        // const progressControl = controlBar.getChild('ProgressControl')!;
        const remainingTimeDisplay = controlBar.getChild('RemainingTimeDisplay');
        remainingTimeDisplay?.hide();
        const chapterButton = controlBar.getChild('ChaptersButton');
        const chapterButtonPosition = controlBar.children().indexOf(chapterButton);
        const isFullscreen = player.isFullscreen();

        controlBar.addChild(
          'spacer',
          {
            className: 'vjs-spacer vjs-spacer-fill',
          },
          chapterButtonPosition
        );

        const chapterMenuTitle = chapterButton?.$('.vjs-menu-title');
        if (chapterMenuTitle) {
          chapterMenuTitle.innerHTML = i18n.jumpToSection;
        }

        if (isFullscreen) {
          if (isMobile) {
            controlBar.hide();
          }

          onHandleFullScreenVideoControls?.({ player });
        } else {
          controlBar.show();
          controlBar.getChild('QuizButton')?.dispose();
        }
      },
      [isMobile, onHandleFullScreenVideoControls]
    );

    useEffect(() => {
      // Make sure Video.js player is only initialized once
      if (!videoRef.current) {
        return;
      }
      if (!playerRef.current) {
        // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
        const videoElement = document.createElement('video-js');

        videoElement.classList.add('vjs-big-play-centered');
        videoRef.current.appendChild(videoElement);

        const player = videojs(videoElement, options, () => {
          if (onReady) {
            onReady(player);
          }
          player.currentTime(skipTo);
        });
        player.on('fullscreenchange', () => {
          handleVideoControls(player);
        });
        player.on('error', () => {
          logError({
            eventType: 'quizVideoError',
            detail: {
              message: player.error()?.message,
              code: player.error()?.code,
            },
          });
        });

        player.addClass('vjs-point-theme');
        playerRef.current = player;
        player.on('touchstart', (e: any) => {
          if (e.target.nodeName === 'VIDEO') {
            if (player.paused()) {
              player.play();
            } else {
              player.pause();
            }
          }
        });

        logInfo({
          eventType: 'video',
          detail: {
            action: 'play',
            location: window.location.href,
          },
        });
      } else {
        // You could update an existing player in the `else` block here
        // on prop change, for example:
        const player = playerRef.current;
        player.autoplay(options.autoplay);
        player.src(options.sources);
      }
      // the other deps are not needed here, because we only want to initialize the player once
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, videoRef]);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
      const player = playerRef.current;

      return () => {
        if (player && !player.isDisposed()) {
          player.dispose();
          playerRef.current = null;
        }
      };
    }, [playerRef]);

    useEffect(() => {
      if (!playerRef.current) {
        return;
      }
      // State changed and the player may need
      // to be updated to reflect the new state
      setTimeout(() => {
        // Chapters are populated not immediately, so we need to wait a bit
        handleVideoControls(playerRef.current);
      }, 50);
    }, [handleVideoControls, playerRef]);

    return (
      <styles.VideoPlayerStyle className="videoplayer_style" isLandscape={isLandscape}>
        <div data-vjs-player>
          <div ref={videoRef} />
        </div>
      </styles.VideoPlayerStyle>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.options.sources[0].src === nextProps.options.sources[0].src;
  }
);

const formatTime = (time: number) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);
  const milliseconds = Math.round((time % 1) * 1000);

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}.${milliseconds.toString().padStart(3, '0')}`;
};

const generateChapterTrack = (chapters: VideoChapters) => {
  const cues = Object.values(chapters).map(
    (chapter, i) =>
      `${i}\n${formatTime(chapter.from)} --> ${formatTime(chapter.to)}\n${chapter.description}\n`
  );

  const vtt = `WEBVTT\n\n${cues.join('\n')}`;

  return `data:text/vtt;base64,${btoa(vtt)}`;
};

interface MobileChaptersProps {
  chapters: VideoChapters;
  player: RefObject<Player>;
  currentTime: number;
  title?: string;
}

const MobileChapters = memo(
  ({ chapters, player, currentTime, title = i18n.jumpToSection }: MobileChaptersProps) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const chapterProgress = (chapter: VideoChapters[string]) => {
      const duration = chapter.to - chapter.from;
      const progress = (currentTime - chapter.from) / duration;
      return progress > 1 ? 1 : progress;
    };

    return (
      <styles.MobileChapterContainerStyle>
        <styles.MobileChapterTitleStyle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <div>{title}</div>
          <Icon name={IconName.ChevronDown} />
        </styles.MobileChapterTitleStyle>
        {isOpen && (
          <styles.MobileChapterListStyle isOpen={isOpen}>
            {Object.values(chapters).map((chapter) => (
              <styles.ButtonStyleModifiedStyle
                key={chapter.label}
                onClick={() => {
                  player?.current?.currentTime(chapter.from);
                  player?.current?.play();
                }}
              >
                <div>{chapter.description}</div>
                <styles.CircleProgressStyle progress={chapterProgress(chapter)} />
              </styles.ButtonStyleModifiedStyle>
            ))}
          </styles.MobileChapterListStyle>
        )}
      </styles.MobileChapterContainerStyle>
    );
  },
  (prevProps, nextProps) => {
    const diff = Math.abs(prevProps.currentTime - nextProps.currentTime);
    return diff < 1;
  }
);

export interface VideoPlayerProps {
  options?: any;
  video: VideoApiResponse;
  onReady?: () => void;
  mobileChapterTitle?: string;
  onHandleFullScreenVideoControls?: (player: any) => void;
}

const VideoPlayer = ({
  options,
  video,
  onReady,
  mobileChapterTitle,
  onHandleFullScreenVideoControls,
}: VideoPlayerProps) => {
  const playerRef = React.useRef<any>(null);
  const { isMobile, isLandscape } = useIsMobile();
  const quizVideoRefPosition = useSelector(getVideoReferencePosition);
  const estimateModel = useSelector(getHEIEstimateModel);
  const estimateKey = estimateModel?.key;

  const [lastTimeUploaded, setLastTimeUploaded] = React.useState(new Date().getTime());
  const [postVideoProgress] = usePostVideoProgressMutation();

  const [currentVideoTime, setCurrentVideoTime] = React.useState(0);

  const handlePlayerPause = () => {
    // Keeping these stubs here intentionally
  };
  const handlePlayerWaiting = () => {
    // Keeping these stubs here intentionally
  };
  const handlePlayerDispose = () => {
    // Keeping these stubs here intentionally
  };

  const handlePlayerTimeUpdate = () => {
    const player = playerRef.current;
    // eslint-disable-next-line no-underscore-dangle
    setCurrentVideoTime(player.currentTime());
  };

  useEffect(() => {
    const player = playerRef.current;
    if (isLandscape) {
      player?.requestFullscreen().catch(() => {
        // ignoring this error:
        // This API can fail if the browser does not have permission via user activation
        // See https://developer.mozilla.org/en-US/docs/Web/Security/User_activation
        // Worse case is user will have to manually enter fullscreen
      });
    } else if (player?.isFullscreen()) {
      // Known issue that the video pauses when exiting fullscreen on ios
      // https://stackoverflow.com/questions/55626518/video-js-is-getting-paused-when-switching-from-full-screen-to-normal-mode-in-ios
      player?.exitFullscreen().catch(() => {
        // ignoring this error:
        // This API can fail if the browser does not have permission via user activation
        // See https://developer.mozilla.org/en-US/docs/Web/Security/User_activation
        // Worse case is user will have to manually exit fullscreen
      });
    }
  }, [isLandscape]);

  useEffect(() => {
    const player = playerRef.current;
    const played = player?.played() || 0;
    const ranges: [number, number][] = [];
    for (let i = 0; i < played.length; i += 1) {
      ranges.push([played.start(i), played.end(i)]);
    }
    const videoTags = estimateKey ? { tags: { estimateKey } } : {};

    const currentTime = new Date().getTime();
    if (currentTime - lastTimeUploaded > 5000) {
      setLastTimeUploaded(currentTime);
      try {
        postVideoProgress({ ranges, label: video.label, ...videoTags });
      } catch (e) {
        // nothing, we don't care
      }
    }
  }, [currentVideoTime, estimateKey, lastTimeUploaded, postVideoProgress, video.label]);

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    player.addRemoteTextTrack({
      kind: 'chapters',
      label: i18n.chapters,
      language: 'en',
      src: generateChapterTrack(video.chapters),
    });

    // You can handle player events here, for example:
    player.on('pause', handlePlayerPause);

    player.on('waiting', handlePlayerWaiting);

    player.on('dispose', handlePlayerDispose);

    player.on('timeupdate', handlePlayerTimeUpdate);

    onReady?.();
  };

  return (
    <>
      <VideoJSPlayer
        skipTo={quizVideoRefPosition}
        options={{
          sources: [
            {
              src: video.url,
              type: 'video/mp4',
            },
          ],
          ...options,
        }}
        onReady={(player) => handlePlayerReady(player)}
        onHandleFullScreenVideoControls={onHandleFullScreenVideoControls}
      />
      {isMobile && (
        <MobileChapters
          player={playerRef}
          chapters={video.chapters}
          currentTime={currentVideoTime}
          title={mobileChapterTitle}
        />
      )}
    </>
  );
};

export default VideoPlayer;
