import React, { useState } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import {
  ButtonClickFunc,
  Container,
  DirectionAndPlacement,
  Header,
  Modal,
  Size,
  TemplatedText,
  directory,
  templatedString,
} from '@pointdotcom/pds';
import ConfettiBackground from 'components/ConfettiBackground';
import DownloadPDFLink from 'components/DownloadPDFLink';
import { DownloadPDFSectionStyle } from 'containers/FollowUpOfferPage/styles';
import { useTaskCompletionModal } from 'containers/hooks/useTaskCompletionModal';
import { useDashboardData, useFollowUpFetch } from 'containers/prequal/hooks';
import { dayjs } from 'lib/dayjs';
import { usePostHogEvents } from 'lib/posthogEvents';
import FollowUpModel from 'models/FollowUpModel';
import { CompletedTask } from 'store/general';
import {
  BodyCopyStyle,
  BorderedSectionStyle,
  HeroContainerStyle,
  SplashCopySerifStyle,
} from 'styles/';
import AcceptForm from './AcceptForm';
import RejectReasonForm from './RejectReasonForm';
import WhatsNextList from './WhatsNextList';
import i18n from './i18n';
import {
  AnimatedSplashCopySerifStyle,
  ChoiceResultComponentStyle,
  DecisionFlagStyle,
  DecisionPageInnerContentStyle,
  DisclaimerTextStyle,
  HeroSectionLeftStyle,
  HeroSectionRightStyle,
} from './styles';

function whatsNextItems(
  isClosingDisclosure: boolean = false,
  isPreliminaryEstimate: boolean = false,
  isAppraised: boolean = false
): string[] {
  if (isClosingDisclosure) {
    return [i18n.whatsNextClosing1, i18n.whatsNextClosing2, i18n.whatsNextClosing3];
  }

  if (isPreliminaryEstimate) {
    return [i18n.whatsNextPrelim1, i18n.whatsNextPrelim2, i18n.whatsNextPrelim3];
  }

  if (isAppraised) {
    return [
      i18n.whatsNext1Appraised,
      i18n.whatsNext2Appraised,
      i18n.whatsNext3Appraised,
      i18n.whatsNext4Appraised,
    ];
  }

  return [i18n.whatsNext1, i18n.whatsNext2];
}

interface ChoiceResultComponentProps {
  header: string;
  subHeader: React.ReactNode;
  bodyComponent?: React.ReactNode;
}

const ChoiceResultComponent = ({
  header,
  subHeader,
  bodyComponent,
}: ChoiceResultComponentProps) => (
  <ChoiceResultComponentStyle>
    <header>
      <SplashCopySerifStyle>{header}</SplashCopySerifStyle>
      <BodyCopyStyle>{subHeader}</BodyCopyStyle>
    </header>
    {bodyComponent}
  </ChoiceResultComponentStyle>
);

interface RejectFormComponentProps {
  onAfterReject: (text: string) => void;
  followUp: FollowUpModel | null;
  followUpId: string;
}

const RejectFormComponent = ({ onAfterReject, followUp, followUpId }: RejectFormComponentProps) => {
  const header = i18n.wereSorry;
  const subHeader = i18n.pleaseLetUs;
  const rejectForm = (
    <RejectReasonForm followUpId={followUpId} followUp={followUp} onAfterReject={onAfterReject} />
  );
  return <ChoiceResultComponent header={header} subHeader={subHeader} bodyComponent={rejectForm} />;
};

interface FollowUpDecisionPageProps {
  followUpFetchResults: ReturnType<typeof useFollowUpFetch>;
}

const FollowUpDecisionPage = ({ followUpFetchResults }: FollowUpDecisionPageProps) => {
  const [rejectModalIn, setRejectModalIn] = useState(false);
  const [thankYou, setThankYou] = useState(false);
  const { followUp, followUpId } = followUpFetchResults;
  const posthogEvents = usePostHogEvents();
  const { dashboard } = useDashboardData();
  const completeTask = useTaskCompletionModal();
  const estimate = followUp?.getEstimate();
  const expDate = dayjs(estimate?.expires).format('MMMM Do, YYYY');
  const showDocumentDisclaimer =
    estimate?.preliminary && followUp?.displayBorrowerInfoDocAcknowledgment;

  // Followup attributes
  const isClosingDisclosure = followUp?.isClosingDisclosure();
  const statusClosed = followUp?.status === 'closed';
  const wasDeclined = followUp?.rejected ?? false;

  const closeRejectModal = () => {
    setRejectModalIn(false);
  };

  const promptRejectModal: ButtonClickFunc = (e) => {
    e.preventDefault();
    setRejectModalIn(true);
  };

  const onAfterAccept = (nextSteps: string[]) => {
    // Trigger offer acceptance event
    if (followUp?.isEstimateCalculator() && dashboard?.application?.estimateKey) {
      const phFunction = followUp.isPreliminary()
        ? posthogEvents.captureInitialEstimateAccepted
        : posthogEvents.captureFinalOfferAccepted;
      phFunction({
        email: dashboard.person.primary_email,
        estimateKey: dashboard.application.estimateKey,
      });
    }

    // Send them back to dashboard
    const task: CompletedTask = {
      title: i18n.reviewYourOffer,
      summary: templatedString({
        values: { offerAmount: followUp?.getFormattedOfferAmount() },
        template: i18n.youveChosen,
      }),
      detailList: {
        ordered: false,
        items: nextSteps,
      },
    };

    completeTask(task);
  };

  const onAfterReject = (text: string) => {
    const ty = `${text}`.replace(/\s/g, '').length > 0;
    setThankYou(ty);
    closeRejectModal();
  };

  const phone = (
    <a href={`tel: ${directory.PointNumber.Support}`}>{directory.PointNumber.Support}</a>
  );
  let rejectHeader = wasDeclined ? i18n.wereSorry : i18n.whatsNext;
  let rejectSubHeader = (
    <TemplatedText values={{ expDate, phone }}>{i18n.youCanCall}</TemplatedText>
  );

  if (thankYou) {
    rejectHeader = i18n.thankYou;
    rejectSubHeader = (
      <>
        {i18n.wereSorry}
        {rejectSubHeader}
      </>
    );
  }

  if (!followUpId) {
    return null;
  }

  const whatsNextItemList = whatsNextItems(
    isClosingDisclosure,
    estimate?.preliminary,
    followUp?.appraised
  );

  return (
    <>
      <ConfettiBackground run={statusClosed && !wasDeclined} recycle={false} />

      <Modal isOpen={rejectModalIn} onModalClose={closeRejectModal}>
        <RejectFormComponent
          followUp={followUp}
          followUpId={followUpId}
          onAfterReject={onAfterReject}
        />
      </Modal>

      <HeroContainerStyle>
        <Container>
          <DecisionPageInnerContentStyle>
            <HeroSectionLeftStyle closed={statusClosed}>
              <Header styleSize={Size.Splash} styleAlign={DirectionAndPlacement.Center}>
                {i18n.yourDecision}
                <TransitionGroup>
                  {statusClosed && (
                    <Transition timeout={{ enter: 0, exit: 500 }} in={statusClosed}>
                      {(status) => (
                        <DecisionFlagStyle animationStatus={status} decline={wasDeclined}>
                          {wasDeclined ? i18n.decline : i18n.proceed}
                        </DecisionFlagStyle>
                      )}
                    </Transition>
                  )}
                </TransitionGroup>
              </Header>

              <Transition timeout={{ enter: 0, exit: 500 }} in={!statusClosed}>
                {(status) => (
                  <AnimatedSplashCopySerifStyle animationStatus={status}>
                    {estimate?.preliminary ? i18n.ifYouWouldPoint : i18n.ifYouWould}
                  </AnimatedSplashCopySerifStyle>
                )}
              </Transition>
            </HeroSectionLeftStyle>
            <HeroSectionRightStyle>
              {!statusClosed && (
                <>
                  <AcceptForm
                    followUp={followUp}
                    followUpId={followUpId}
                    onAfterAccept={() => onAfterAccept(whatsNextItemList)}
                    handleRejectClick={promptRejectModal}
                  />
                  {showDocumentDisclaimer && (
                    <DisclaimerTextStyle>{i18n.byProceeding}</DisclaimerTextStyle>
                  )}
                </>
              )}
              {statusClosed && (
                <ChoiceResultComponent
                  header={rejectHeader}
                  subHeader={
                    wasDeclined ? rejectSubHeader : <WhatsNextList listItems={whatsNextItemList} />
                  }
                />
              )}
            </HeroSectionRightStyle>
          </DecisionPageInnerContentStyle>
        </Container>
      </HeroContainerStyle>
      {isClosingDisclosure ? null : (
        <BorderedSectionStyle>
          <Container>
            <DownloadPDFSectionStyle noMargin>
              <DownloadPDFLink followUpId={followUpId} />
            </DownloadPDFSectionStyle>
          </Container>
        </BorderedSectionStyle>
      )}
    </>
  );
};

export default FollowUpDecisionPage;
