import { useParams } from 'containers/routerHelpers';
import FollowUpModel from 'models/FollowUpModel';
import { useFollowupQuery } from 'services/api/underwriteApi';
import { FollowUpResponse } from 'services/apiTypes/generated/Underwrite.Core.FollowUps.types';

function getFollowUpModel(followUp?: FollowUpResponse) {
  if (!followUp) return null;

  return new FollowUpModel(followUp);
}

export const useFollowUpFetch = () => {
  const { followUpId } = useParams();

  const { data: followUp, isLoading, isError, refetch } = useFollowupQuery(followUpId!);
  const followUpModel = getFollowUpModel(followUp);

  return {
    followUp: followUpModel,
    followUpId,
    followUpLoading: isLoading,
    followUpHasError: isError,
    refetch,
  };
};
