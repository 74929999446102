import * as z from "zod";

/**
 * GET /quiz/questions typings
 */
export const answerSchema = z
  .object({
    /**
     * id of the answer in the databse
     */
    id: z.number(),
    /**
     * content of the answer (could be correct or not)
     */
    text: z.string(),
  })
  .catchall(z.unknown() as z.ZodType);
export type Answer = z.infer<typeof answerSchema>;

export const questionSchema = z
  .object({
    /**
     * id of the question in the database
     */
    id: z.number(),
    /**
     * content of the question
     */
    text: z.string(),
    /**
     * possible answers to the question one of which is correct
     */
    answers: z.array(answerSchema),
    /**
     * reference to the video which contains answer to the question
     */
    videoReferenceLabel: z.string(),
    /**
     * position in seconds of the video which contains answer to the question
     */
    videoReferencePosition: z.number(),
  })
  .catchall(z.unknown() as z.ZodType);
export type Question = z.infer<typeof questionSchema>;

export const questionsApiResponseSchema = z
  .object({
    /**
     * version of the quiz for which questions are returned
     */
    quizVersion: z.number(),
    /**
     * shuffled quiz questions
     */
    questions: z.array(questionSchema),
  })
  .catchall(z.unknown() as z.ZodType);
export type QuestionsApiResponse = z.infer<typeof questionsApiResponseSchema>;

/**
 * GET /quiz/answers typings
 */
export const quizItemResponseSchema = z
  .object({
    /**
     * id of the response in the database
     */
    id: z.number(),
    /**
     * question on which this response was given
     */
    question: questionSchema,
    /**
     * answer id given by the customer (could be looked up in qusetion.answers)
     */
    givenAnswerId: z.number(),
    /**
     * id of the correct answer (could be looked up in qusetion.answers)
     */
    correctAnswerId: z.number(),
    /**
     * explanation to the client considering the chosen answer
     */
    explanation: z.string().nullable(),
  })
  .catchall(z.unknown() as z.ZodType);
export type QuizItemResponse = z.infer<typeof quizItemResponseSchema>;

export enum ApplicantStatus {
  Passed = "passed",
  Failed = "failed",
  NeverAttempted = "neverAttempted",
  ResubmissionRequired = "resubmissionRequired",
}
export const applicantStatusSchema = z.nativeEnum(ApplicantStatus);

export const applicantWithoutResultSchema = z
  .object({
    /**
     * status of the applicant across all attempts
     */
    applicantStatus: z.enum([
      ApplicantStatus.NeverAttempted,
      ApplicantStatus.ResubmissionRequired,
    ]),
  })
  .catchall(z.unknown() as z.ZodType);
export type ApplicantWithoutResult = z.infer<
  typeof applicantWithoutResultSchema
>;

export enum AttemptStatus {
  Passed = "passed",
  Failed = "failed",
}
export const attemptStatusSchema = z.nativeEnum(AttemptStatus);

export const answersApiResponseSchema = z
  .object({
    /**
     * status of the applicant across all attempts
     */
    applicantStatus: z.enum([ApplicantStatus.Passed, ApplicantStatus.Failed]),
    /**
     * status of the last attempt
     */
    attemptStatus: attemptStatusSchema,
    /**
     * version of the quiz taken at the last attempt
     */
    quizVersion: z.number(),
    /**
     * date and time of the last quiz attempt
     */
    attemptedAt: z.string().datetime({ offset: true }),
    /**
     * number of correctly answered questions at the last quiz attempt
     */
    score: z.number(),
    /**
     * total number of questions at the last quiz attempt
     */
    maxScore: z.number(),
    /**
     * arbitarry data in JSON format associated with the attempt
     */
    metadata: z.unknown().optional(),
    /**
     * array of quesion responses responses at the last attempt. Each item corresponds to a question
     */
    responses: z.array(quizItemResponseSchema),
  })
  .catchall(z.unknown() as z.ZodType);
export type AnswersApiResponse = z.infer<typeof answersApiResponseSchema>;

/**
 * POST /quiz/answers typings
 */
export const postAnswersSchema = z
  .object({
    metadata: z.unknown().optional(),
    quizVersion: z.number(),
    answers: z.array(
      z
        .object({
          answerId: z.number(),
        })
        .catchall(z.unknown() as z.ZodType),
    ),
  })
  .catchall(z.unknown() as z.ZodType);
export type PostAnswers = z.infer<typeof postAnswersSchema>;

export const getQuizResultsResponseSchema = z.discriminatedUnion(
  "applicantStatus",
  [answersApiResponseSchema, applicantWithoutResultSchema],
);
export type GetQuizResultsResponse = z.infer<
  typeof getQuizResultsResponseSchema
>;
