// This file contains general types
import { z } from 'zod';
import ClosingCostsModel from 'models/ClosingCostsModel';
import { Pricing as PricingApiType } from 'services/apiTypes/prequalTypes';
import { OfferStatuses, Products, SourceChannel, SourceOrigin } from 'store/constants';

// expands object types
// this helps vscode display the individual props of a type instead of the typename
// So instead of function(animal: Dog) you get function(animal: {barks: boolean, name: string, size: Size})
// expand all will do a deep display of each prop
// https://stackoverflow.com/a/57683652/20394008
export type Expand<T> = T extends infer O ? { [K in keyof O]: O[K] } : never;

export type ExpandAll<T> =
  T extends Record<string, unknown>
    ? T extends infer O
      ? { [K in keyof O]: ExpandAll<O[K]> }
      : never
    : T;

export interface Contact {
  title?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  image?: string;
  phone?: string;
  email?: string;
  suffix?: string;
}

export const addressSchema = z.object({
  streetAddress: z.string(),
  unit: z.string().nullable().optional(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
});
export type Address = z.infer<typeof addressSchema>;

export interface Applicant extends Contact {
  authorizeCreditPull?: string; // TODO Add a type guard for ISO 8601 Date time
  acknowledgeContact?: string; // TODO Add a type guard for ISO 8601 Date time
  ssnLastFour?: string;
  ficoRange?: string;
  isNameConfirmed?: boolean;
  address?: Address;
  householdIncome?: string;
  contactPolicyConsentDate?: null | string;
}

export interface Pricing {
  acceptable?: boolean;
  capPercentage?: number;
  closingCosts?: ClosingCostsModel;
  homeValue?: number;
  optionInvestmentAmount?: number;
  optionPercentage?: number;
  originationFeeRate?: number;
  riskAdjustedHomeValue?: number;
  riskAdjustmentPercentage?: number | null;
  termYears?: number;

  // Inconsistencies from the BE
  riskAdjustment?: number;
  riskAdjustedValue?: number;
}

export interface Promotion {
  promotionTerm: number;
  capPercentage: number;
}

export interface Property {
  address?: Address;
  homeValue?: number;
  homeValueExplanation?: string;
  mortgageBalance?: number;
  firstMortgageBalance?: number;
  secondMortgageBalance?: number;
}

interface EstimateSource {
  channel?: null | SourceChannel | string;
  platform?: null | string;
  partnerAccountid?: string;
  origin: null | SourceOrigin | string;
  clientId?: string;
}

export interface Estimate {
  key: string;
  termYears?: number;
  productType?: Products.HEI;
  applicant?: Applicant;
  pricing?: Pricing | PricingApiType;
  pricingRangeEstimates?: Array<Estimate>;
  preliminary?: boolean;
  expires?: string;
  maxOptionAmount?: number;
  estimates?: [Estimate];
  status?: OfferStatuses;
  promotion?: Promotion;
  property?: Property;
  source?: EstimateSource;
  features?: Record<string, unknown>;
}

export interface UtmParameters {
  advertiserClickId?: string | null;
  irClickId?: string | null;
  utmSource?: string | null;
  utmMedium?: string | null;
  utmCampaign?: string | null;
  utmTerm?: string | null;
  utmContent?: string | null;
  utmKeyword?: string | null;
  queryString?: string;
}

// TODO: These constants should likely be moved into apiTypes/prequalTypes.ts
export const PREQUAL_FLOW_TYPE = {
  PREQUAL: 'prequal',
  FIRM_OFFER: 'firm_offer',
  ALT_FIRM_OFFER: 'alt_firm_offer',
  INVITATION_TO_APPLY: 'invitation_to_apply',
} as const;
export type PrequalFlowType = (typeof PREQUAL_FLOW_TYPE)[keyof typeof PREQUAL_FLOW_TYPE];

export const APPLICATION_VALIDATION_CODES = {
  INVALID_FIRST_NAME: 'invalid_first_name',
  INVALID_LAST_NAME: 'invalid_last_name',
  INVALID_PHONE_NUMBER: 'invalid_phone_number',
  INVALID_EMAIL: 'invalid_email',
  INVALID_ADDRESS: 'invalid_address',
} as const;
export type ApplicationValidationCode =
  (typeof APPLICATION_VALIDATION_CODES)[keyof typeof APPLICATION_VALIDATION_CODES];

export const MORE_INFO_REQUIRED = {
  FICO: 'fico_range_required',
  MORTGAGE: 'mortgage_balance_required',
  SSN: 'ssn_required',
} as const;
export type MoreInfoRequired = (typeof MORE_INFO_REQUIRED)[keyof typeof MORE_INFO_REQUIRED];

export const PQ_APPLICATION_STATUS = {
  ELIGIBLE: 'eligible',
  INELIGIBLE: 'ineligible',
  NEEDS_MORE_INFORMATION: 'needsMoreInformation',
  WAITLIST: 'waitlist',
} as const;
export type PrequalApplicationStatus =
  (typeof PQ_APPLICATION_STATUS)[keyof typeof PQ_APPLICATION_STATUS];
