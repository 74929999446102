import React from 'react';
import FullScreenLoading from 'components/FullScreenLoading';
import ErrorPage from 'containers/ErrorPage';
import ScheduleCompletePage from 'containers/ScheduleCompletePage';
import { useFollowUpFetch } from 'containers/prequal/hooks';
import { useProduct } from 'containers/prequal/hooks/useProduct';

const FollowupScheduleCompletePage = () => {
  const { product } = useProduct();
  const { followUp, followUpLoading, followUpHasError, followUpId } = useFollowUpFetch();
  const applicant = followUp?.getEstimate()?.getApplicant();

  // if the follow up is loading
  if (followUpLoading) {
    return <FullScreenLoading />;
  }

  // if the there is an error
  if (followUpHasError || !followUpId) {
    return <ErrorPage product={product} />;
  }

  return <ScheduleCompletePage applicant={applicant} product={product} />;
};

export default FollowupScheduleCompletePage;
