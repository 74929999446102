import * as React from 'react';
import { posthogFeatureFlagDefinitions } from './featureFlagDefinitions';

type FeatureFlagDefinitions = typeof posthogFeatureFlagDefinitions;

export type FeatureFlag = keyof FeatureFlagDefinitions;
// eslint-disable-next-line no-redeclare
export const FeatureFlag = Object.fromEntries(
  Object.keys(posthogFeatureFlagDefinitions).map((name) => [name, name])
) as { [TName in FeatureFlag]: TName };

export type FeatureFlagValue<TFlag extends FeatureFlag> =
  FeatureFlagDefinitions[TFlag]['values'][number];

export interface FeatureFlags {
  getValue<TFlag extends FeatureFlag>(flag: TFlag): undefined | FeatureFlagValue<TFlag>;
}

export const emptyFeatureFlags: FeatureFlags = {
  getValue: () => undefined,
};

export const FeatureFlagsContext = React.createContext<null | FeatureFlags>(emptyFeatureFlags);
