import * as z from "zod";

export enum DocumentCategory {
  AcknowledgmentOfHeir = "acknowledgment-of-heir",
  AffidavitOfDeath = "affidavit_of_death",
  AmcRejectionReason = "amc_rejection_reason",
  Appraisal = "appraisal",
  AvmReportNotification = "avm_report_notification",
  BuildingPermits = "building_permits",
  CertificateOfFinancialCounseling = "certificate_of_financial_counseling",
  ClearToCloseLetter = "clear_to_close_letter",
  ClosingProtectionLetter = "closing_protection_letter",
  CollateralUnderwriter = "collateral_underwriter",
  Commitment = "commitment",
  ConcealedPublicRecords = "concealed_public_records",
  CorelogicLiens = "corelogic_liens",
  CorelogicPropertyDetail = "corelogic_property_detail",
  CorelogicTransactions = "corelogic_transactions",
  CreditReport = "credit_report",
  DeathCertificate = "death_certificate",
  Deed = "deed",
  DisbursementLedger = "disbursement_ledger",
  DivorceDecree = "divorce_decree",
  EscrowDisclosureAuthorization = "escrow-disclosure-authorization",
  ExecutedClosingPackage = "executed_closing_package",
  FinalSettlementStatement = "final_settlement_statement",
  FinalTitlePolicy = "final_title_policy",
  FinancialAssets = "financial_assets",
  FinancialLiabilities = "financial_liabilities",
  FloodCertification = "flood_certification",
  FloodEoi = "flood_eoi",
  FollowUp = "follow_up",
  ForbearanceLetterFor1stMortgage = "forbearance_letter_for_1st_mortgage",
  ForbearanceLetterFor2ndMortgage = "forbearance_letter_for_2nd_mortgage",
  ForbearanceLetterFor3rdMortgage = "forbearance_letter_for_3rd_mortgage",
  GrantOrWarrantyDeed = "grant_or_warranty_deed",
  Heloc = "HELOC",
  HoaBillingStatement = "hoa_billing_statement",
  HoaDocuments = "hoa_documents",
  HoaLitigation = "hoa_litigation",
  HoaEoi = "hoa_eoi",
  HoaQuestionnaire = "hoa_questionnaire",
  Identity = "identity",
  ImmigrationStatus = "immigration_status",
  LatestStatementFor1stMortgage = "latest_statement_for_1st_mortgage",
  LatestStatementFor2ndMortgage = "latest_statement_for_2nd_mortgage",
  LatestStatementFor3rdMortgage = "latest_statement_for_3rd_mortgage",
  MarriageCertificate = "marriage_certificate",
  MiscDocument = "misc_document",
  Mortgage = "mortgage",
  MortgageModificationInformation = "mortgage_modification_information",
  Payoff = "payoff",
  PayoffDemandFor1stMortgage = "payoff_demand_for_1st_mortgage",
  PayoffDemandFor2ndMortgage = "payoff_demand_for_2nd_mortgage",
  PayoffDemandFor3rdMortgage = "payoff_demand_for_3rd_mortgage",
  PoaApproval = "poa_approval",
  PowerOfAttorney = "power_of_attorney",
  PreliminaryClosingDisclosure = "preliminary_closing_disclosure",
  PreliminaryPropertyEoi = "preliminary_property_eoi",
  PreliminarySettlementStatement = "preliminary_settlement_statement",
  ProofOfResidency = "proof_of_residency",
  ProofOfRoofRepair = "proof_of_roof_repair",
  PropertyEoi = "property_eoi",
  PropertyLawsuit = "property_lawsuit",
  RecordedMortgageDot = "recorded_mortgage_dot",
  RecordedPointMortgageDot = "recorded_point_mortgage_dot",
  RecordedRelease = "recorded_release",
  RecordingFeeEstimate = "recording_fee_estimate",
  ReleaseOfLien = "release_of_lien",
  RePointAbout = "about_my_re_points",
  SatisfactionLetter = "satisfaction_letter",
  SearchPackage = "search_package",
  SignedDocumentsExecClientClosing = "signed_documents_executed_client_closing_package",
  SocialSecurityCard = "social_security_card",
  StatementOfInformation = "statement_of_information",
  TaxBill = "tax_bill",
  TaxCertificate = "tax_certificate",
  TidelandSearch = "tideland_search",
  TitleBill = "title_bill",
  TitleReport = "title_report",
  TitleReportData = "title_report_data",
  Trust = "trust",
  TrustApproval = "trust_approval",
  UccFinancingStatement = "ucc_financing_statement",
  UccFinancingStatementSolar = "ucc_financing_statement_solar",
  UtilityBill = "utility_bill",
  WireInstructions = "wire_instructions",
  FreeFormRequest = "",
  Unknown = "unknown",
}
export const documentCategorySchema = z.nativeEnum(DocumentCategory);
