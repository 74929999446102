import {
  useDispatch as useUntypedDispatch,
  useSelector as useUntypedSelector,
  useStore as useUntypedStore,
} from 'react-redux';
import type { AppDispatch, AppStore, RootState } from 'store';

export const useDispatch = useUntypedDispatch.withTypes<AppDispatch>();
export const useSelector = useUntypedSelector.withTypes<RootState>();
export const useStore = useUntypedStore.withTypes<AppStore>();
