import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { Container, HeaderStyles, Modal, ModalStyles, SplashTextStyles } from '@pointdotcom/pds';
import { DashboardTasksStyle } from 'containers/tasks/DashboardTasks/styles';
import { pxToRem } from 'styleHelpers';

export const ANIM_SPEED_MS = 450;

export const HeaderContainerStyle = styled.div.attrs({ className: 'HeaderContainerStyle' })`
  display: flex;
  flex-flow: column;
  gap: 1rem;

  ${HeaderStyles.HeaderStyle} {
    > h2 {
      font-size: ${pxToRem(52)};
      @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
        font-size: ${pxToRem(36)};
      }
    }
  }

  ${SplashTextStyles.SplashCopySerifStyle} {
    margin: 0;
  }
`;

export const CheckMarkSvg = styled.svg.attrs({ className: 'CheckMarkSvg' })``;

const enteredCss = css`
  border-width: 0;
  background-color: ${({ theme }) => theme.Color.PointBlue}; // in case the blue image doesnt load
  transform: scale(1);
  img {
    opacity: 1;
  }
  ${CheckMarkSvg} {
    transform: scale(1);
  }
`;
const exitedCss = css`
  &::before {
    border-width: 5px;
  }
  background-color: white;
  transform: scale(0.75);
  img {
    opacity: 0;
  }
  ${CheckMarkSvg} {
    transform: scale(0);
  }
`;

export const CheckMarkImgContainerStyle = styled.div.attrs({
  className: 'CheckMarkImgContainerStyle',
})<{
  animationStatus: TransitionStatus;
}>`
  width: 15%;
  max-width: 80px;
  flex-shrink: 0;
  position: relative;
  transition: all ${ANIM_SPEED_MS}ms ease-out;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-color: ${({ theme }) => theme.line.color.default};
    border-style: solid;
  }
  &::after {
    content: '';
    display: block;
    padding-top: 100%; /* This creates the square aspect ratio */
    margin-top: -50%;
  }
  img {
    transition: all ${ANIM_SPEED_MS}ms ease-out;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &:after {
      content: '';
    }
  }
  ${CheckMarkSvg} {
    transition: all ${ANIM_SPEED_MS * 0.5}ms ease-out;
    transition-delay: ${ANIM_SPEED_MS * 0.3}ms;
    position: absolute;
    left: calc(50% - (35% / 2));
    top: calc(38%);
    width: 35%;
    polygon {
      fill: white;
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
    }
  }
  ${({ animationStatus }) => {
    if (animationStatus === 'entered') {
      return enteredCss;
    }
    return exitedCss;
  }}
`;

export const EnvelopeImgContainerStyle = styled.div.attrs({
  className: 'EnvelopeImgContainerStyle',
})`
  --size: 110px;

  width: var(--size);
  height: var(--size);
  margin-top: calc(0px - var(--size) / 2);
  display: grid;

  > img {
    display: block;
    width: 140%;
    margin: -20%;
  }
`;

export const EnvelopeImgAnimatedContainerStyle = styled.div.attrs({
  className: 'EnvelopeImgContainerStyle',
})`
  width: 200px;
  height: 280px;
  margin-top: -35%;
  margin-left: -6%;
  display: grid;
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    width: 45vw;
    height: 65vw;
    margin-top: -41vw;
  }
`;

// We need this wrapper to break the circular dependency between TaskCompleteModalStyle and HeaderImageWrapperStyle
export const PreHeaderImageWrapperStyle = styled.div``;

export const TaskCompleteModalStyle = styled(Modal).attrs({ className: 'TaskCompleteModalStyle' })<{
  subtle?: boolean;
}>`
  --padding: 15%;
  --paddingTop: clamp(1.5rem, 3vh, 3rem);
  ${ModalStyles.ModalContainerInnerStyle} {
    display: flex;
    flex-flow: column;
    padding: 0;
    padding-top: var(--paddingTop);
    padding-bottom: 5rem;
    > * {
      padding-left: var(--padding);
      padding-right: var(--padding);
    }
  }

  ${({ subtle, theme }) => {
    return subtle
      ? css`
          ${PreHeaderImageWrapperStyle} {
            display: none;
          }
        `
      : css`
          ${ModalStyles.ModalContentStyle} {
            background-color: transparent;
          }
          ${ModalStyles.UpperHeaderStyle} {
            border-bottom: 1px solid ${theme.line.color.default};
          }
        `;
  }}

  ${DashboardTasksStyle} {
    margin-bottom: 3rem;
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    --padding: ${(props) => props.theme.container.gutter.default};
  }
`;

export const HeaderImageWrapperStyle = styled(PreHeaderImageWrapperStyle).attrs({
  className: 'HeaderImageWrapperStyle',
})`
  ${TaskCompleteModalStyle} & {
    transform: translateY(calc(-1 * (var(--paddingTop))));
  }
`;

export const TaskTitleStyle = styled.div.attrs({
  className: 'TaskTitleStyle',
})`
  ${SplashTextStyles.SplashCopySerifStyle} {
    color: ${({ theme }) => theme.Color.PointBlue};
    font-style: italic;
    font-size: ${pxToRem(18)};
    margin-bottom: -0.5rem;

    @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
      font-size: ${pxToRem(16)};
    }
  }
`;

export const TaskSummaryStyle = styled.div.attrs({ className: 'TaskSummaryStyle' })`
  ${SplashTextStyles.SplashCopySerifStyle} {
    padding-right: 3rem;
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    ${SplashTextStyles.SplashCopySerifStyle} {
      padding-right: 1rem;
    }
  }
`;

export const SummaryListStyle = styled.ul.attrs({ className: 'SummaryListStyle' })<{
  ordered: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-block: 0;
  padding: 3rem 0 0 2rem;

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding-left: ${({ ordered }) => (ordered ? '2rem' : '1rem')};
  }
`;

export const ModalFooterStyle = styled(Container).attrs({ className: 'ModalFooterStyle' })`
  --padding: 15%;
  --borderPadding: 3.75rem;
  padding: var(--borderPadding) var(--padding) 5rem;
  border-top: 1px solid ${({ theme }) => theme.line.color.default};
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    --padding: ${({ theme }) => theme.container.gutter.default};
    --borderPadding: 3rem;
  }
`;

export const FooterHeaderStyle = styled.h4.attrs({ className: 'FooterHeaderStyle' })`
  font-family: ${({ theme }) => theme.fonts.serif.name};
  font-style: italic;
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  font-size: ${pxToRem(18)};
  font-weight: normal;
  margin: 0;
`;

export const LinkContainerStyle = styled.div.attrs({ className: 'LinkContainerStyle' })`
  > a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
