import React, { useEffect } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classNames from 'classnames';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import {
  Button,
  LinkButton,
  Loader,
  ProfileImage,
  Size,
  Style,
  TemplatedText,
  messagingI18n,
  saveToSessionStorage,
} from '@pointdotcom/pds';
import { getYCBMCalendarURLFromCalendar } from 'components/ScheduleCalendar/helpers';
import {
  BOOKING_CONFLICT_STATUS_CODE,
  defaultCal,
  useGetScheduleAvailabilityQuery,
  usePostScheduleMutation,
} from 'services/api/youCanBookMe';
import { ButtonScheduleNextAvailableProps, DescriptionProps } from './constants';
import i18n from './i18n';
import * as styles from './styles';

export const SCHEDULED_STORAGE_KEY = 'NEXT_AVAILABLE';

dayjs.extend(AdvancedFormat);

const DefaultDescription = ({ availability, error, hasConflict }: DescriptionProps) => {
  let text;
  if (hasConflict) {
    text = i18n.oops;
  } else if (availability) {
    const dateTime = dayjs(parseInt(availability.timestamp, 10));
    const textValues = {
      day: <styles.BoldTextStyle>{dateTime.format('dddd, MMMM Do')}</styles.BoldTextStyle>,
      time: <styles.BoldTextStyle>{dateTime.format('h:mm a')}</styles.BoldTextStyle>,
      person: <styles.BoldTextStyle>{availability.name || i18n.aPointRep}</styles.BoldTextStyle>,
    };
    text = (
      <>
        {i18n.stillHave}
        <TemplatedText values={textValues}>{i18n.ourNext}</TemplatedText>
      </>
    );
  } else if (error) {
    text = `${messagingI18n.errors.oops} ${i18n.tryClickingBelow}`;
  } else {
    text = i18n.stillHave;
  }
  return (
    <styles.DescriptionTextStyle error={error || hasConflict || false}>
      {text}
    </styles.DescriptionTextStyle>
  );
};

const LoadingState = () => (
  <styles.LoadingStateStyle>
    <Loader styleSize={Size.Small} />
  </styles.LoadingStateStyle>
);

const ButtonScheduleNextAvailable = ({
  onGetSchedule,
  onPostSchedule,
  onPostScheduleError,
  onDifferentTimeLinkClick,
  team,
  calendar = defaultCal,
  contact,
  Description = DefaultDescription,
  buttonProps = {
    styleType: Style.Quaternary,
  },
}: ButtonScheduleNextAvailableProps) => {
  const {
    data: getScheduleResponse,
    error: getScheduleError,
    isLoading: getScheduleLoading,
  } = useGetScheduleAvailabilityQuery({ calendar, team });

  const [
    postScheduleMutation,
    { isLoading: isPostScheduleLoading, isError: hasPostScheduleError, error: postScheduleError },
  ] = usePostScheduleMutation();

  const handlePostScheduleClick = async () => {
    if (!getScheduleResponse) {
      return;
    }
    try {
      const postScheduleResponse = await postScheduleMutation({
        scheduleAvailability: getScheduleResponse,
        contact,
        calendar,
      }).unwrap();
      saveToSessionStorage(SCHEDULED_STORAGE_KEY, getScheduleResponse);
      onPostSchedule?.({
        availability: getScheduleResponse,
        postScheduleResponse,
      });
    } catch (error) {
      onPostScheduleError?.(error as Error);
    }
  };

  const getHasConflict = () => {
    return (postScheduleError as FetchBaseQueryError)?.status === BOOKING_CONFLICT_STATUS_CODE;
  };

  const handleDifferentTimeLinkClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onDifferentTimeLinkClick?.(e, {
      calendar,
      calendarURL: getYCBMCalendarURLFromCalendar(calendar),
    });
  };

  // When the schedule is received
  useEffect(() => {
    if (getScheduleResponse) {
      onGetSchedule?.({ availability: getScheduleResponse });
    }
  }, [getScheduleResponse, onGetSchedule]);

  if (getScheduleLoading) {
    return <LoadingState />;
  }

  if (getScheduleError || !getScheduleResponse) {
    return (
      <styles.ButtonScheduleNextAvailableStyle
        className={classNames({
          noImg: !getScheduleResponse?.image,
          singleCol: !getScheduleResponse,
        })}
      >
        {getScheduleResponse && (
          <Description
            availability={getScheduleResponse}
            error={!!getScheduleError}
            hasConflict={getHasConflict()}
          />
        )}
        <Button
          {...buttonProps}
          block
          onClick={handleDifferentTimeLinkClick}
          error={!!getScheduleError}
        >
          {i18n.findAnAvailable}
        </Button>
      </styles.ButtonScheduleNextAvailableStyle>
    );
  }

  return (
    <styles.ButtonScheduleNextAvailableStyle
      className={classNames({ noImg: !getScheduleResponse.image, singleCol: !getScheduleResponse })}
    >
      <Description availability={getScheduleResponse} />
      <styles.ButtonAndLinkContainerStyle>
        <styles.ButtonPicWrapperStyle>
          <ProfileImage
            image={typeof getScheduleResponse.image === 'string' ? getScheduleResponse.image : ''}
            name={getScheduleResponse.name}
          />
          <Button
            block
            loading={isPostScheduleLoading}
            onClick={handlePostScheduleClick}
            error={hasPostScheduleError}
            {...buttonProps}
            gaTrackingId="BookThisTime"
          >
            <span>{i18n.bookThisTime}</span>
          </Button>
        </styles.ButtonPicWrapperStyle>
        <LinkButton
          onClick={handleDifferentTimeLinkClick}
          data-ga-tracking-id="ChooseDifferentTime"
        >
          {i18n.chooseA}
        </LinkButton>
      </styles.ButtonAndLinkContainerStyle>
    </styles.ButtonScheduleNextAvailableStyle>
  );
};

export default ButtonScheduleNextAvailable;
