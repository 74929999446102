import styled from 'styled-components';
import { HeaderStyles, Modal, ModalStyles, mobileScreen, pxToRem } from '@pointdotcom/pds';

export const ButtonRowStyle = styled.div.attrs({ className: 'ButtonRowStyle' })`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 3rem;
  > * {
    flex: 1;
  }
  button {
    height: 100%;
  }
`;

export const ZestimateFormLayoutStyle = styled.div.attrs({
  className: 'ZestimateFormLayoutStyle',
})``;
export const ModalUpperHeaderStyle = styled.div.attrs({ className: 'ModalUpperHeaderStyle' })`
  ${HeaderStyles.HeaderStyle} {
    > * {
      color: ${({ theme }) => theme.Color.GrayLightestAccessible};
      font-size: ${pxToRem(18)};
      display: flex;
      flex-flow: row nowrap;
      gap: 1rem;
      align-items: center;
      justify-content: center;
    }
  }
  ${mobileScreen} {
    ${HeaderStyles.HeaderStyle} {
      > * {
        font-size: ${pxToRem(16)};
      }
    }
  }
`;

export const ConfirmDescribeValueModalStyle = styled(Modal).attrs({
  className: 'ConfirmDescribeValueModalStyle',
})`
  sup {
    font-size: 0.5em;
  }
  mark {
    background: ${({ theme }) => theme.Color.GrayLight1};
  }
  --padding: ${pxToRem(80)};
  ${ModalStyles.UpperHeaderStyle} {
    padding-inline: var(--padding);
    border-bottom: 1px solid ${({ theme }) => theme.line.color.default};
    padding-block: calc(var(--padding) / 2);
  }
  ${ModalStyles.ModalContainerInnerStyle} {
    padding-inline: var(--padding);
    padding-top: calc(var(--padding) / 2);
    padding-bottom: var(--padding);
  }
  ${mobileScreen} {
    --padding: ${pxToRem(50)};
  }
`;
