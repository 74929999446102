/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import { SkipLink, useScrollSpy } from '@pointdotcom/pds';
import { GenericBannerProps } from 'components/GenericMessageBanner';
import MainHeaderNoNav from 'components/MainHeaderNoNav';
import MainHeaderWithProgress from 'components/MainHeaderWithProgress';
import MainHeaderWithSequence from 'components/MainHeaderWithSequence';
import SubHeader, { SubHeaderProps } from 'components/SubHeader';
import { Products } from 'store/constants';
import { MainHeaderRefs } from './constants';
import { NavItem, NavProps } from './nav';
import * as styles from './styles';

export enum HeaderType {
  Progress = 'progress',
  Sequence = 'sequence',
  NoNav = 'nonav',
}

export interface MainHeaderProps extends React.HTMLAttributes<HTMLHeadingElement> {
  showSubHeader?: boolean;
  subHeaderProps?: SubHeaderProps;
  headerType?: HeaderType;
  navItems?: Array<NavItem>;
  location?: string;
  navProps?: NavProps;
  hidden?: boolean;
  bannerProps?: GenericBannerProps;
  product?: Products;
  logoHref?: string;
}

const FixedAndAnimatedWrapper = ({
  hidden,
  children,
}: {
  hidden?: boolean;
  children: (props: { scrolled?: boolean }) => React.ReactElement;
}) => {
  const [ready, setReady] = useState(false);
  const { scrollY } = useScrollSpy();
  const scrolled = scrollY > 0;
  // delay animation on the body a so that header doesnt jump on load
  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 0);
  }, []);
  return (
    <>
      <styles.MainHeaderBodyStyle scrolled={scrolled} animationReady={ready} />
      <Transition in={!hidden} timeout={{ enter: 0, exit: styles.ANIM_SPEED_MS }}>
        {(animationStatus) => (
          <styles.MainHeaderWrapperStyle animationStatus={animationStatus} scrolled={scrolled}>
            {children({ scrolled })}
          </styles.MainHeaderWrapperStyle>
        )}
      </Transition>
    </>
  );
};

const MainHeader = forwardRef<MainHeaderRefs, MainHeaderProps>(
  (
    {
      navItems,
      navProps = {},
      hidden,
      headerType = HeaderType.Sequence,
      bannerProps,
      showSubHeader = true,
      subHeaderProps,
      product,
      logoHref,
      ...htmlProps
    },
    ref
  ) => {
    let UseHeader: JSX.Element;

    if (headerType === HeaderType.Progress) {
      UseHeader = (
        <MainHeaderWithProgress
          navItems={navItems}
          navProps={navProps}
          bannerProps={bannerProps}
          ref={ref}
        />
      );
    } else if (headerType === HeaderType.Sequence) {
      UseHeader = (
        <FixedAndAnimatedWrapper hidden={hidden}>
          {({ scrolled }) => (
            <MainHeaderWithSequence
              navItems={navItems}
              navProps={navProps}
              bannerProps={bannerProps}
              scrolled={scrolled}
              ref={ref}
            />
          )}
        </FixedAndAnimatedWrapper>
      );
    } else {
      UseHeader = (
        <FixedAndAnimatedWrapper hidden={hidden}>
          {({ scrolled }) => (
            <MainHeaderNoNav
              logoHref={logoHref}
              bannerProps={bannerProps}
              scrolled={scrolled}
              ref={ref}
            />
          )}
        </FixedAndAnimatedWrapper>
      );
    }

    return (
      <styles.MainHeaderStyle {...htmlProps}>
        <SkipLink />
        {UseHeader}
        {showSubHeader ? <SubHeader {...subHeaderProps} product={product} /> : null}
      </styles.MainHeaderStyle>
    );
  }
);

export default MainHeader;
