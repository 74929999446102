import { TransitionStatus } from 'react-transition-group';
import styled, { createGlobalStyle } from 'styled-components';
import { ContainerStyles } from '@pointdotcom/pds';
import { appTheme } from 'styles/';

export const ANIM_SPEED_MS = 200;
interface MainHeaderStyleProps {
  animationStatus: TransitionStatus;
  scrolled: boolean;
}

export const MainHeaderStyle = styled.header.attrs({ className: 'MainHeaderStyle' })``;

export const MainHeaderBodyStyle = createGlobalStyle<
  Pick<MainHeaderStyleProps, 'scrolled'> & { animationReady: boolean }
>`
  body {
    transition: ${({ animationReady }) =>
      animationReady ? `padding ${ANIM_SPEED_MS}ms ease-in-out` : 'none'};
    padding-top: ${({ scrolled }) =>
      scrolled ? appTheme.mainHeaders.height.mobile : appTheme.mainHeaders.height.default};
    @media only screen and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
      padding-top: ${appTheme.mainHeaders.height.mobile};
    }
  }
`;

export const MainHeaderWrapperStyle = styled.div.attrs({
  className: 'MainHeaderWrapperStyle' as string,
})<MainHeaderStyleProps>`
  box-shadow: ${({ scrolled, theme }) =>
    scrolled ? `0 0 15px ${theme.line.color.default}` : 'none'};
  z-index: 4;
  transition: all ${ANIM_SPEED_MS}ms ease-in-out;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  ${({ animationStatus }) => {
    let opacity = '0';
    let translateY = '-100%';

    if (animationStatus === 'entering' || animationStatus === 'entered') {
      translateY = '';
      opacity = '1';
    }

    return `
      transform: translateY(${translateY});
      opacity: ${opacity};
      `;
  }}
`;
export const MainHeaderContainerStyle = styled.div.attrs({
  className: 'MainHeaderContainerStyle',
})<{
  scrolled?: boolean;
}>`
  transition: all 320ms ease-in-out;
  background: ${({ theme }) => theme.Color.White};
  position: relative;
  display: flex;
  align-items: center;
  height: ${({ scrolled }) =>
    scrolled ? appTheme.mainHeaders.height.mobile : appTheme.mainHeaders.height.default};
  border-bottom: 1px solid
    ${({ scrolled, theme }) => (!scrolled ? theme.line.color.default : 'transparent')};
  ${ContainerStyles.ContainerStyle} {
    align-self: center;
    position: relative;
    flex: 1;
  }
  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    height: ${appTheme.mainHeaders.height.mobile};
  }
  @media only print {
    box-shadow: none;
  }
`;
