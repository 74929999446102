import styled from 'styled-components';
import { HeaderStyles } from '@pointdotcom/pds';

export const DashboardSectionStyle = styled.section.attrs({ className: 'DashboardSectionStyle' })`
  padding: var(--blockPadding) var(--inlinePadding);
  border-bottom: 1px solid ${({ theme }) => theme.line.color.default};

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    border-bottom: 1rem solid ${({ theme }) => theme.Color.GrayLight1};
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }
  ${HeaderStyles.HeaderStyle} {
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  }
`;
