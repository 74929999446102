import React from 'react';
import Helmet from 'react-helmet';
import { Container, SplashText, TemplatedText, useIsMobile } from '@pointdotcom/pds';
import crumbsI18n from 'components/Breadcrumbs/i18n';
import DashboardMainHeader from 'components/DashboardMainHeader';
import FullScreenLoading from 'components/FullScreenLoading';
import MainFooter from 'components/MainFooter';
import { TextContentStyle } from 'components/SubHeader/styles';
import { TagPage, Workflow } from 'components/TagPage';
import TaskCompleteModal from 'containers/DashboardPage/TaskCompleteModal';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { useDashboardOfferAmount } from 'containers/hooks/useDashboardOfferAmount';
import useLinkProps from 'containers/hooks/useLinkProps';
import DashboardTasks from 'containers/tasks/DashboardTasks';
import DashboardModel from 'models/DashboardModel';
import { TaskStatus } from 'services/apiTypes/taskTypes';
import { BoldCopyStyle } from 'styles';
import DashboardAccountManagerSection from './DashboardAccountManagerSection';
import DashboardFundingResourcesSection from './DashboardFundingResourcesSection';
import DashboardFundingStatusSection from './DashboardFundingStatusSection';
import i18n from './i18n';
import * as styles from './styles';
import { useApplicationRedirect } from './useApplicationRedirect';

const TaskSectionFooter = ({
  hasOpenTasks,
  hasCompletedTasks,
}: {
  hasOpenTasks: boolean;
  hasCompletedTasks: boolean;
}) => {
  let content;
  const linkProps = useLinkProps(generateUrlFromPage(pages.DASHBOARD_TASKS_LIST));
  const { isMobile } = useIsMobile();

  if (hasCompletedTasks) {
    if (!isMobile) {
      content = (
        <aside>
          {i18n.takeALook}
          <a {...linkProps}>{i18n.viewSubmitted}</a>
        </aside>
      );
    }

    if (!hasOpenTasks) {
      content = (
        <SplashText>
          {i18n.takeALook}
          <br />
          <a {...linkProps}>{i18n.viewSubmitted}</a>
        </SplashText>
      );
    }
  }

  return content ? (
    <styles.TasksSectionFooterStyle>{content}</styles.TasksSectionFooterStyle>
  ) : null;
};

const StandardDashboard = ({ dashboard }: { dashboard: DashboardModel | null }) => {
  const [pageLoading, setPageLoading] = React.useState(true);
  useApplicationRedirect(dashboard, setPageLoading);

  const { offerAmountFormatted } = useDashboardOfferAmount(dashboard);
  const openTasks = dashboard?.getTasks({
    status: [TaskStatus.Active, TaskStatus.SubmissionInProgress],
  });
  const completedTasks = dashboard?.getTasks({ status: TaskStatus.Submitted });
  const hasOpenTasks = !!openTasks?.length;
  const hasCompletedTasks = !!completedTasks?.length;

  if (!dashboard) {
    return null;
  }

  const customerName = dashboard.getContact()?.firstName;

  if (pageLoading) {
    return <FullScreenLoading />;
  }

  let headerText;
  if (hasOpenTasks) {
    headerText = i18n.someItemsInYourTaskListRequireAttention;
  } else {
    headerText = i18n.wereWorkingHard;
  }

  const offerText = offerAmountFormatted ? (
    <TextContentStyle>
      <TemplatedText
        values={{
          offerAmount: <BoldCopyStyle>{offerAmountFormatted}</BoldCopyStyle>,
        }}
      >
        {i18n.currentOffer}
      </TemplatedText>
    </TextContentStyle>
  ) : undefined;

  return (
    <styles.DashboardStyle data-ga-tracking-id="HODashboard">
      <Helmet title={i18n.yourDashboard} />
      <TagPage page="Dashboard" workflow={Workflow.Dashboard} />
      <DashboardMainHeader
        dashboard={dashboard}
        crumbProps={{ currentCrumb: crumbsI18n.dashboard }}
        subHeaderProps={{
          rightContent: offerText,
        }}
      />
      <TaskCompleteModal dashboard={dashboard} />
      <styles.TaskListSectionStyle hasOpenTasks={hasOpenTasks}>
        <styles.HeaderContainerStyle>
          <Container>
            <styles.GreetingHeaderStyle>
              {customerName ? (
                <TemplatedText values={{ name: customerName }}>
                  {i18n.helloWelcomeToYourDashboard}
                </TemplatedText>
              ) : (
                i18n.hello
              )}
            </styles.GreetingHeaderStyle>
            <styles.CTAHeaderStyle noMargin>{headerText}</styles.CTAHeaderStyle>
          </Container>
        </styles.HeaderContainerStyle>

        {hasOpenTasks && (
          <div>
            <Container>
              <styles.SectionHeaderStyle mobileShadowTop>
                {i18n.yourTaskList}
              </styles.SectionHeaderStyle>
            </Container>
            <DashboardTasks tasks={openTasks} compact={false} />
          </div>
        )}

        <TaskSectionFooter hasOpenTasks={hasOpenTasks} hasCompletedTasks={hasCompletedTasks} />
      </styles.TaskListSectionStyle>
      <DashboardFundingStatusSection dashboard={dashboard} />
      <DashboardFundingResourcesSection dashboard={dashboard} />
      <DashboardAccountManagerSection accountManager={dashboard.getAccountManager()} />
      <MainFooter noBackground />
    </styles.DashboardStyle>
  );
};

export default StandardDashboard;
