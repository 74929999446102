import { useMemo } from 'react';
import { To, useHref } from 'react-router';
import { useLinkClickHandler } from 'react-router';
import { isPointSource } from 'containers/helpers';

const ABSOLUTE_URL_REGEX = /^(?:[a-z][a-z0-9+.-]*:|\/\/)/i;

type UseLinkPropsOptions = Parameters<typeof useLinkClickHandler>[1] & {
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => unknown;
  newTab?: boolean;
};

function useOptionalHref(
  to: null | undefined | To,
  options?: Parameters<typeof useHref>[1]
): undefined | string {
  // Allow optional link target while avoiding conditional hook invocation
  const hrefFromHook = useHref(to ?? '', options);
  if (to == null) {
    return undefined;
  }
  return hrefFromHook;
}

function useOptionalLinkClickHandler<TElement extends Element = HTMLAnchorElement>(
  to: To,
  options?: Parameters<typeof useLinkClickHandler>[1]
): undefined | ((event: React.MouseEvent<TElement, MouseEvent>) => void) {
  // Allow optional link target while avoiding conditional hook invocation
  const linkClickHandlerFromHook = useLinkClickHandler<TElement>(to ?? '', options);
  if (to == null) {
    return undefined;
  }
  return linkClickHandlerFromHook;
}

export type LinkProps = {
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => unknown;
  target?: string;
  rel?: string;
};

export default function useLinkProps(
  to: null | undefined | To,
  options: UseLinkPropsOptions = {}
): LinkProps {
  // This is loosely based on how the <Link> component in React Router works:
  // https://github.com/remix-run/react-router/blob/c47464c7e51c38cdf21a9fc2db81248daee2d171/packages/react-router-dom/index.tsx#L879

  const { onClick: onClickFromProps, newTab, ...otherOptions } = options;

  const href = useOptionalHref(to, otherOptions);
  const linkClickHandler = useOptionalLinkClickHandler<HTMLElement>(to ?? '', otherOptions);

  // `onClick` is undefined if there is no `to` target and no click handler
  const onClick = useMemo(
    () =>
      onClickFromProps != null || linkClickHandler != null
        ? (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            onClickFromProps?.(event);
            if (!event.defaultPrevented) {
              linkClickHandler?.(event);
            }
          }
        : undefined,
    [linkClickHandler, onClickFromProps]
  );

  if (typeof to === 'string' && ABSOLUTE_URL_REGEX.test(to)) {
    // If the target is an absolute URL, return the original href and click handlers
    return {
      href: to,
      onClick: onClickFromProps,
      ...(!isPointSource(to) || newTab
        ? {
            target: '_blank',
            rel: 'noopener noreferrer',
          }
        : null),
    };
  }

  // If this is an internal link, return the `href` and click-handler from React Router
  return { href, onClick };
}
