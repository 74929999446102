import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';
import { z } from 'zod';
import {
  DirectionAndPlacement,
  Header,
  Icon,
  IconName,
  Style,
  useScrollSpy,
} from '@pointdotcom/pds';
import { useZodStorageItem } from 'hooks/useLocalStorageItem';
import {
  ANIM_SPEED_MS,
  BannerCollapsableStyle,
  BannerContentStyle,
  BannerExpandedContentStyle,
  BannerHeaderStyle,
} from './styles';

interface BannerCollapsableProps {
  id: string;
  title: string;
  content: string | string[];
  isOpen: boolean;
}

export default function BannerCollapsable({ title, content, isOpen, id }: BannerCollapsableProps) {
  // Get initial state from localStorage or default to false
  const storageKey = `banner-dismissed-${id}`;
  const scrollSpy = useScrollSpy();

  const [isDismissed, setIsDismissed] = useZodStorageItem(storageKey, z.boolean(), () => false);
  const [readyForScrollLogic, setReadyForScrollLogic] = useState(false);

  // Update localStorage when state changes
  const updateDismissedState = (dismissed: boolean) => {
    setIsDismissed(dismissed);
    try {
      localStorage.setItem(storageKey, String(dismissed));
    } catch (e) {
      // Silently fail if localStorage is not available
    }
  };

  // Toggle function
  const toggleCollapse = () => {
    updateDismissedState(!isDismissed);
  };

  const root = document.getElementById('root') ?? document.body;

  // Effect to handle the delay when isOpen changes to true
  // Needed so that if the user is in the middle of scrolling down,
  // and the banner opens, it wont immediately collapse
  useEffect(() => {
    if (isOpen) {
      const timeoutId = setTimeout(() => {
        setReadyForScrollLogic(true);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
    // No timeout set, no cleanup needed
  }, [isOpen]);

  const contentArray = [content].flat();

  // You might want to add props for banner content and ID overrides
  return ReactDOM.createPortal(
    <Transition in={isOpen} timeout={{ enter: 0, exit: ANIM_SPEED_MS }}>
      {(animationStatus) => {
        return (
          <BannerCollapsableStyle
            isExpanded={!isDismissed}
            isVisible={!readyForScrollLogic || !scrollSpy.scrollingDown || scrollSpy.scrollY < 50}
            animationStatus={animationStatus}
          >
            <BannerContentStyle
              onToggle={toggleCollapse}
              content={
                <>
                  {contentArray.map((item, index) => (
                    <BannerExpandedContentStyle key={index}>{item}</BannerExpandedContentStyle>
                  ))}
                </>
              }
              title={
                <BannerHeaderStyle isExpanded={!isDismissed}>
                  <Icon name={IconName.Bell} />
                  <Header styleType={Style.Uppercase} noMargin>
                    {title}
                  </Header>
                  <span>{content}</span>
                </BannerHeaderStyle>
              }
              iconStyleAlign={DirectionAndPlacement.Right}
              isExpanded={!isDismissed}
              isPadded
            />
          </BannerCollapsableStyle>
        );
      }}
    </Transition>,
    root
  );
}
