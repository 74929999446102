import styled from 'styled-components';
import IntroModalGraphic from './images/dashboardIntroModalGraphic.png';

export const IntroModalHeaderGraphicStyle = styled.div.attrs({
  className: 'IntroModalHeaderGraphicStyle',
})`
  background-image: url(${IntroModalGraphic});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom left;
  width: 100%;
  height: 10rem;
  position: relative;
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    width: 70%;
  }
`;

export const IntroModelContentContainerStyle = styled.div.attrs({
  className: 'IntroModelContentContainerStyle',
})`
  top: -1rem; // make it bottom heavy and pull the graphic up a bit
  position: relative;
  display: grid;
  gap: clamp(2rem, 5vh, 3rem);
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    gap: 2rem;
  }
`;
