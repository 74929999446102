import { useEffect } from 'react';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { useHistory, useLocation } from 'containers/routerHelpers';
import DashboardModel from 'models/DashboardModel';
import { useLazyGetApplicationQuery } from 'services/api/homeownerApi';
import {
  ApplicationStatus,
  DocketStatus,
  GetApplicationNotFoundResponse,
} from 'services/apiTypes/homeownerTypes';

export function useApplicationRedirect(
  dashboard: DashboardModel | null,
  setPageLoading: (loading: boolean) => void
) {
  const { search } = useLocation();
  const history = useHistory();
  const [getHosApplication] = useLazyGetApplicationQuery();

  // If requested, attempt to redirect to the application page immediately
  useEffect(() => {
    async function redirectToApplication(estimateKey: string) {
      try {
        await getHosApplication(estimateKey).unwrap();

        // Successfully retrieved the application from HOS, so let's go there.
        const applicationPage = generateUrlFromPage(pages.HEI_APPLICATION, { estimateKey });
        history.push(applicationPage);
      } catch (e) {
        const typedError = e as {
          status: number;
          data?: GetApplicationNotFoundResponse;
        };

        // Check the error to see if there is an application we can redirect to Underwrite
        const { status, data } = typedError;
        if (
          status === 404 &&
          data?.docketStatus === DocketStatus.Active &&
          (data.applicationStatus === ApplicationStatus.New ||
            data.applicationStatus === ApplicationStatus.Open) &&
          data.followUpUrl
        ) {
          window.location.assign(data.followUpUrl);
        }
      } finally {
        setPageLoading(false);
      }
    }

    const appRedirectParam = new URLSearchParams(search).get('autoredirectToApplication');
    let followupId: string | undefined;
    if (appRedirectParam === 'true') {
      const estimateKey = dashboard?.getApplicationTask()?.detail.estimateKey;
      if (estimateKey) {
        redirectToApplication(estimateKey);
      }
    }
    setPageLoading(Boolean(followupId));
  }, [dashboard, getHosApplication, setPageLoading, history, search]);
}
