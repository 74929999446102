import React, { useState } from 'react';
import {
  Color,
  DirectionAndPlacement,
  Header,
  Icon,
  IconName,
  Size,
  Style,
  directory,
} from '@pointdotcom/pds';
import { PlayIconStyle } from 'components/VideoPlayer/PlayButton';
import VideoPlayerModal from 'components/VideoPlayer/VideoPlayerModal';
import { LifeSaverIcon } from 'containers/DashboardPage/icons';
import { Page } from 'containers/helpers';
import { generateUrlFromPage } from 'containers/helpers';
import useLinkProps, { LinkProps } from 'containers/hooks/useLinkProps';
import DashboardModel from 'models/DashboardModel';
import { isPrequalEstimateId } from 'models/OfferEstimateModel';
import { SupportOptions } from '../SupportOptions';
import i18n from './i18n';
import {
  FundingResourcesSectionStyle,
  ItemTitleStyle,
  ResourceItemStyle,
  ResourceItemsStyle,
  SectionHeaderStyle,
} from './styles';

const iconSize = 20;

type ResourceItemProps = LinkProps & {
  headerText: string;
  content: string;
  icon?: React.ReactNode;
};

function ResourceItem({
  headerText,
  content,
  onClick,
  href,
  target,
  rel,
  icon,
}: ResourceItemProps) {
  return (
    <ResourceItemStyle
      as={href != null ? 'a' : 'button'}
      onClick={onClick}
      href={href}
      target={target}
      rel={rel}
      aria-label={[headerText, content].join('; ')}
    >
      <ItemTitleStyle>
        <Header styleSize={Size.Small} noMargin>
          {headerText}
        </Header>
        {icon ?? (
          <Icon name={IconName.ChevronRight} styleSize={iconSize} color={Color.Gray1Transparency} />
        )}
      </ItemTitleStyle>
      <span>{content}</span>
    </ResourceItemStyle>
  );
}

function VideoResource() {
  const [videoIsOpen, setVideoIsOpen] = useState<boolean>(false);

  return (
    <>
      <ResourceItem
        onClick={() => setVideoIsOpen(true)}
        headerText={i18n.keyConcepts}
        content={i18n.watchOur}
        icon={<PlayIconStyle />}
      />
      <VideoPlayerModal
        isOpen={videoIsOpen}
        onClose={() => setVideoIsOpen(false)}
        bgClickToClose
        escToClose
        showX
      />
    </>
  );
}

function CostEstimatorResource({ dashboard }: { dashboard: DashboardModel }) {
  const linkProps = useLinkProps(generateUrlFromPage(Page.DASHBOARD_ESTIMATOR));

  if (
    dashboard.getDocket()?.getLatestOffer() == null &&
    !isPrequalEstimateId(dashboard.application?.estimateKey)
  ) {
    return null;
  }

  return (
    <ResourceItem headerText={i18n.exploreTheCost} content={i18n.useThisTool} {...linkProps} />
  );
}

function HelpCenterResource() {
  const { PointDomain } = directory;
  const linkProps = useLinkProps(PointDomain.Help, { newTab: true });

  return (
    <ResourceItem headerText={i18n.haveQuestions} content={i18n.visitOurHelp} {...linkProps} />
  );
}

interface FundingResourcesSectionProps {
  dashboard: DashboardModel;
}

export function FundingResourcesSection({ dashboard }: FundingResourcesSectionProps) {
  return (
    <FundingResourcesSectionStyle>
      <SectionHeaderStyle>
        <LifeSaverIcon />
        <Header styleType={Style.Uppercase} styleAlign={DirectionAndPlacement.Center} noMargin>
          {i18n.yourFunding}
        </Header>
      </SectionHeaderStyle>
      <ResourceItemsStyle>
        <VideoResource />
        <CostEstimatorResource dashboard={dashboard} />
        <HelpCenterResource />
      </ResourceItemsStyle>
      <SupportOptions accountManager={dashboard.getAccountManager()} />
    </FundingResourcesSectionStyle>
  );
}
