export default {
  yourFunding: 'Your funding resources',
  keyConcepts: 'Watch key concepts of the HEI explained',
  watchOur:
    'Watch our educational video to learn more about how Point’s HEI works, including a sample scenario.',
  exploreTheCost: 'Explore the cost estimator and see your numbers',
  useThisTool:
    'Use this tool to understand potential repayment amounts associated with different scenarios of home appreciation.',
  haveQuestions: 'Have questions? Find your answers in our Help Center',
  visitOurHelp:
    'Visit our Help Center for clear, detailed answers about the funding process, your HEI agreement, repayment, and more.',
};
