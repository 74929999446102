import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Helmet from 'react-helmet';
import { Container, Header, TemplatedText } from '@pointdotcom/pds';
import BannerCollapsable from 'components/BannerCollapsable';
import DashboardMainHeader from 'components/DashboardMainHeader';
import FullScreenLoading from 'components/FullScreenLoading';
import MainFooter from 'components/MainFooter';
import { TagPage, Workflow } from 'components/TagPage';
import TaskCompleteModal from 'containers/DashboardPage/TaskCompleteModal';
import { useStringStorageItem } from 'hooks/useLocalStorageItem';
import DashboardModel from 'models/DashboardModel';
import { getDashboardEstFundDateTTOpen } from 'store/general';
import { useSelector } from 'store/hooks';
import rootI18n from '../i18n';
import { useApplicationRedirect } from '../useApplicationRedirect';
import { FundingResourcesSection } from './FundingResourcesSection';
import { FundingTimelineSection } from './FundingTimelineSection';
import IntroModal from './IntroModal';
import { OfferAmountSection } from './OfferAmountSection';
import { RecentActivitySection } from './RecentActivitySection';
import { TaskListSection } from './TaskListSection';
import i18n from './i18n';
import {
  DetailSectionsStyle,
  GreetingStyle,
  MainContentStyle,
  TimelineDashboardStyle,
} from './styles';

const TimelineDashboard = ({ dashboard }: { dashboard: DashboardModel | null }) => {
  const [pageLoading, setPageLoading] = useState(true);
  const isTooltipOpen = useSelector(getDashboardEstFundDateTTOpen);

  useApplicationRedirect(dashboard, setPageLoading);
  const [initialVisitDateFromStorage, setInitialVisitDate] = useStringStorageItem(
    'dashboardInitialVisitDate'
  );

  // Store the date of the first visit to the dashboard
  useEffect(() => {
    if (!initialVisitDateFromStorage) {
      setInitialVisitDate(dayjs().toISOString());
    }
  }, [initialVisitDateFromStorage, setInitialVisitDate]);

  if (!dashboard) {
    return null;
  }

  const globalUpdate = dashboard.getGlobalUpdate();
  const bannerOpen = globalUpdate != null && globalUpdate.items.length > 0;

  if (pageLoading) {
    return <FullScreenLoading />;
  }

  const initialVisit = dayjs(initialVisitDateFromStorage);
  const isRepeatVisit = dayjs().isAfter(initialVisit.add(1, 'minute')); // TODO: probably 1 day?
  const customerName = dashboard.getContact()?.firstName;
  return (
    <TimelineDashboardStyle data-ga-tracking-id="HODashboard" bannerOpen={bannerOpen}>
      <Helmet title={rootI18n.yourDashboard} />
      <TagPage page="Dashboard" workflow={Workflow.Dashboard} />
      <IntroModal />
      <DashboardMainHeader dashboard={dashboard} showSubHeader={false} hidden={isTooltipOpen} />
      <TaskCompleteModal dashboard={dashboard} />
      <Container mobileCollapse>
        <BannerCollapsable
          isOpen={bannerOpen && !isTooltipOpen}
          id="dashboard"
          title={globalUpdate?.items.length === 1 ? i18n.importantUpdate : i18n.importantUpdates}
          content={globalUpdate?.items.map((item) => item.message) ?? []}
        />

        <GreetingStyle>
          <Header noMargin>
            <TemplatedText values={{ customerName }}>
              {isRepeatVisit ? i18n.welcomeBack : i18n.welcome}
            </TemplatedText>
          </Header>
        </GreetingStyle>
        <MainContentStyle>
          <OfferAmountSection dashboard={dashboard} />
          <DetailSectionsStyle>
            <TaskListSection dashboard={dashboard} />
            <RecentActivitySection dashboard={dashboard} />
            <FundingTimelineSection dashboard={dashboard} />
          </DetailSectionsStyle>
        </MainContentStyle>
        <FundingResourcesSection dashboard={dashboard} />
      </Container>
      <MainFooter noBackground />
    </TimelineDashboardStyle>
  );
};

export default TimelineDashboard;
