import React from 'react';
import { Header, Style } from '@pointdotcom/pds';
import { DashboardSectionStyle } from './styles';

interface DashboardSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  headerText: string;
}
export default function DashboardSection({
  headerText,
  children,
  ...htmlProps
}: DashboardSectionProps) {
  return (
    <DashboardSectionStyle {...htmlProps}>
      <Header styleType={Style.Uppercase}>{headerText}</Header>
      {children}
    </DashboardSectionStyle>
  );
}
