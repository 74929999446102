import React, { useState } from 'react';
import { GenericBannerProps } from 'components/GenericMessageBanner';
import MainHeader, { HeaderType } from 'components/MainHeader';
import { NavItem } from 'components/MainHeader/nav';
import { NavProps } from 'components/MainHeader/nav';
import { SubHeaderProps } from 'components/SubHeader';
import ErrorPage, { ErrorType } from 'containers/ErrorPage';
import BaseEstimateModel from 'models/BaseEstimateModel';
import { Products } from 'store/constants';
import * as styles from './styles';

export const DEFAULT_BUY_BACK_TIME_IN_YEARS = 5;

interface EstimatorPageProps {
  product: Products;
  navItems?: Array<NavItem>;
  navProps?: NavProps;
  bannerProps: GenericBannerProps;
  headerType: HeaderType;
  subHeaderProps: SubHeaderProps;
  estimate?: BaseEstimateModel;
  children?: TSFixMe;
}

const EstimatorPage = (props: EstimatorPageProps) => {
  const {
    product,
    navItems,
    navProps,
    bannerProps,
    headerType,
    subHeaderProps,
    estimate,
    children,
  } = props;

  const [headerHidden, setHeaderHidden] = useState(false);
  const [buyBackTime, setBuyBackTime] = useState();
  const getBuyBackTime = () => buyBackTime || DEFAULT_BUY_BACK_TIME_IN_YEARS;
  const [selectedEstimatorScenario, setSelectedEstimatorScenario] = useState();

  const onBuyBackScrollShownChange = (scrollShown: boolean) => {
    setHeaderHidden(scrollShown);
  };

  if (!estimate) {
    return null;
  }

  if (estimate.getIsExpired()) {
    return <ErrorPage errorType={ErrorType.OfferExpired} showMainHeader={false} />;
  }

  return (
    <styles.EstimatorPageStyle className="EstimatorPage">
      {navItems && navProps && (
        <MainHeader
          product={product}
          bannerProps={bannerProps}
          hidden={headerHidden}
          navItems={navItems}
          navProps={navProps}
          headerType={headerType}
          subHeaderProps={subHeaderProps}
        />
      )}
      {children
        ? children({
            ...props,
            selectedEstimatorScenario,
            setSelectedEstimatorScenario,
            getBuyBackTime,
            setBuyBackTime,
            onBuyBackScrollShownChange, // this communicates with the header and the BuyBackControlSection
          })
        : null}
    </styles.EstimatorPageStyle>
  );
};

export default EstimatorPage;
