import { createApi } from '@reduxjs/toolkit/query/react';
import { getYCBMAPIDomain } from 'components/ScheduleCalendar/helpers';
import { Contact } from 'types';
import { axiosBaseQuery } from './baseQuery';

export interface ScheduleAvailability {
  busy: boolean;
  timestamp: string;
  time: string;
  date: string;
  href: string;
  calendar: string;
  memberId: string;
  name: string;
  email: string;
  legalName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  jobTitle: string;
  image?: string;
}

interface QueryArgs {
  filterNoImages?: boolean;
  skipBusy?: boolean;
  calendar?: string;
  team?: string;
}

interface PostScheduleArgs {
  scheduleAvailability: ScheduleAvailability;
  calendar: string;
  contact?: Contact;
}

export interface TimeZone {
  string: string;
  offset: number;
  offsetInJan: number;
  offsetInJul: number;
}

export interface Booking {
  form: Contact;
  memberId: string;
  timestamp: string;
  calendar: string;
  clientTimeZone: TimeZone;
  pathname: string;
}

export const BOOKING_CONFLICT_STATUS_CODE = 409;

export const defaultCal = process.env.REACT_APP_DEFAULT_YCBM_CAL || 'mypoint-am-staging';

export interface YCBMIframeParams {
  EMAIL?: string;
  FNAME?: string;
  LNAME?: string;
  PHONENUMBER?: string;
  team?: string;
}

export const youCanBookMeApi = createApi({
  reducerPath: 'youCanBookMeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${getYCBMAPIDomain()}`,
  }),
  endpoints: (builder) => ({
    getScheduleAvailability: builder.query<ScheduleAvailability, QueryArgs>({
      query: ({ skipBusy = true, calendar = defaultCal, team }) => {
        const url = `/${calendar}/calendar`;
        return {
          url,
          method: 'GET',
          data: null,
          params: {
            skipBusy,
            team,
          },
        };
      },
      transformResponse: (response: ScheduleAvailability[], _, arg) => {
        const filtered = arg.filterNoImages ? response.filter(({ image }) => !!image) : response;
        return filtered[0];
      },
    }),
    postSchedule: builder.mutation<Booking, PostScheduleArgs>({
      query: ({ scheduleAvailability, contact, calendar }) => {
        const url = `/${calendar}/schedule-form`;
        const now = new Date();
        const clientTimeZone = {
          string: new Date().toString(),
          offset: now.getTimezoneOffset(),
          offsetInJan: new Date(now.getFullYear(), 0, 1).getTimezoneOffset(),
          offsetInJul: new Date(now.getFullYear(), 6, 1).getTimezoneOffset(),
        };
        const data = {
          form: {
            firstName: contact?.firstName,
            lastName: contact?.lastName,
            email: contact?.email,
            phoneNumber: contact?.phone, // IMPORTANT: format matters
            comments: '',
          },
          memberId: scheduleAvailability?.memberId,
          timestamp: scheduleAvailability?.timestamp,
          calendar: scheduleAvailability?.calendar,
          clientTimeZone,
          pathname: window.location.pathname,
        };

        return {
          url,
          method: 'POST',
          data,
        };
      },
    }),
  }),
});

export const { useGetScheduleAvailabilityQuery, usePostScheduleMutation } = youCanBookMeApi;
