import React from 'react';
import { useEffect, useState } from 'react';
import {
  Button,
  DirectionAndPlacement,
  IconName,
  Modal,
  SplashText,
  Style,
} from '@pointdotcom/pds';
import i18n from './i18n';
import * as styles from './styles';

const IntroModal = () => {
  const STORAGE_KEY = 'dashboard_intro_modal_dismissed';
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Check if the banner was dismissed previously
    const dismissed = localStorage.getItem(STORAGE_KEY) === 'true';
    if (!dismissed) {
      setIsOpen(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem(STORAGE_KEY, 'true');
    setIsOpen(false);
  };

  if (!isOpen) return null;

  return (
    <Modal onModalClose={handleClose} isOpen={isOpen} mobileFullscreen={false} shadeBg>
      <styles.IntroModelContentContainerStyle>
        <styles.IntroModalHeaderGraphicStyle />
        <SplashText noMargin>{i18n.welcomeToYour}</SplashText>
        <Button
          onClick={handleClose}
          styleType={Style.Dark}
          block
          iconType={IconName.ChevronRight}
          styleAlignText={DirectionAndPlacement.Left}
        >
          {i18n.continueToYourDashboard}
        </Button>
      </styles.IntroModelContentContainerStyle>
    </Modal>
  );
};

export default IntroModal;
