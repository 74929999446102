export default {
  plainAnnualIncrease: 'Annual increase',
  plainAnnualIncreaseIn: 'Annual increase in home value',
  plainTotalIncrease: 'Increase',
  plainTotalIncreaseIn: 'Increase in home value',
  percAnnualIncrease: '%perc% annual increase',
  percAnnualIncreaseIn: '%perc% annual increase in home value',
  percTotalIncrease: '%perc% increase',
  percTotalIncreaseIn: '%perc% increase in home value',
  plainTotalDeclineIn: 'Decline in home value',
  plainTotalDecline: 'Decline',
  plainAnnualDeclineIn: 'Annual decline in home value',
  plainAnnualDecline: 'Annual decline',
  percAnnualDecline: '%perc% annual decline',
  percAnnualDeclineIn: '%perc% annual decline in home value',
  percTotalDecline: '%perc% decline',
  percTotalDeclineIn: '%perc% decline in home value',
  amountFinalHomeValue: '%amount% final home value',
  plainFinalHomeValue: 'Final home value',
  high: 'High',
  highAppreciation: 'High Appreciation',
  moderate: 'Moderate',
  moderateAppreciation: 'Moderate Appreciation',
  none: 'None',
  noChange: 'No Change',
  largeDecline: 'Large Decline',
  custom: 'Custom',
  customAppreciation: 'Custom Appreciation',
  finalHomeValue: 'Final Home Value',
  yourTotalRepayment: 'Your Total Repayment',
  pointEarns: 'Point Earns',
  pointShare: 'Point’s Share',
  yourShare: 'Your Share',
  youKeep: 'You Keep',
  equivalentAPR: 'Equivalent APR',
  enterAnAnnualPerc: 'Enter an annual percentage change in your home value',
  enterHomeValue: 'Enter your own final home value',
  scenario: 'Scenario',
  seeTheMath: 'See the math',
  homeAppreciation: 'Home Appreciation Scenario',
  homeAppreciationPlural: 'Home Appreciation Scenarios',
  doesNotReflect:
    'This amount does not reflect any amounts you may owe on any mortgage loan secured by the property.',
  doesNotHaveInterest:
    'This product does not have an interest rate. Your estimated repayment amount is based on the appreciation or depreciation of your home’s value. Equivalent APR is intended to represent the effective cost of the HEI under each scenario presented. Your actual Equivalent APR and repayment amount may be less if any state laws apply. The Equivalent APR is calculated using Appendix J to Part 1026 of the Truth in Lending Act.',
  homeAppreciationScenarios: 'Home appreciation scenarios based on buying back in %year%',
  homeAppreciationScenariosWithAmount:
    'Home appreciation scenarios based on receiving %amount% and buying back in %year%',
  monthlyPayment: 'Monthly Payment',
  repayment: 'Repayment',
};
