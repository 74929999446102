import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import classnames from 'classnames';
import throttle from 'lodash.throttle';
import { Container, Logo } from '@pointdotcom/pds';
import GenericMessageBanner, { GenericBannerProps } from 'components/GenericMessageBanner';
import { MainHeaderRefs } from 'components/MainHeader/constants';
import { NavItem, NavProps, getNavItemProp } from 'components/MainHeader/nav';
import { useHistory, useLocation } from 'containers/routerHelpers';
import * as styles from './styles';

interface HeaderProgressProps {
  perc?: number;
  shouldHideProgressBar: boolean;
}

const HeaderProgress = ({ shouldHideProgressBar, perc }: HeaderProgressProps) => (
  // eslint-disable-next-line react/destructuring-assignment
  <styles.HeaderProgressStyle shouldHideProgressBar={shouldHideProgressBar}>
    <styles.FillStyle perc={perc} />
  </styles.HeaderProgressStyle>
);

interface MainHeaderWithProgressProps {
  bannerProps?: GenericBannerProps;
  logoHref?: string;
  nav?: boolean;
  navItems?: NavItem[];
  navProps?: NavProps;
  shouldHideProgressBar?: boolean;
}

const isTestEnv = process.env.REACT_APP_ENV === 'test';

const MainHeaderWithProgress = forwardRef<MainHeaderRefs, MainHeaderWithProgressProps>(
  (
    {
      bannerProps = {},
      logoHref,
      nav = true,
      navItems = [],
      navProps = {},
      shouldHideProgressBar = false,
    },
    ref
  ) => {
    const location = useLocation();
    const history = useHistory();
    const [itemCenterPoints, setItemCenterPoints] = useState<Array<number | null>>([]);
    const itemRefs = useRef<Array<HTMLElement | null>>([]);
    const getHref = getNavItemProp('href', navProps);
    const getText = getNavItemProp('text', navProps);
    const bannerRef = useRef<HTMLDivElement>(null);
    const headerContainerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      bannerRef,
      headerContainerRef,
    }));

    const setCenterpointDistances = () => {
      const newItemCenterPoints = itemRefs.current.map((item) => {
        if (!item || !(item instanceof HTMLElement)) {
          return null;
        }
        const rect = item.getBoundingClientRect();
        const totalW = window.innerWidth; // TODO: get the progress bar width instead
        const unknownOffset = -3; // the getBoundingClientRect calculation seems to be this much off for some reason
        // JSDom doesn't implement the 2-arg overload of getComputedStyle
        const boxShadowString = !isTestEnv
          ? getComputedStyle(item, ':after').boxShadow?.split(' ').pop()
          : '0';
        const boxShadow = parseInt(boxShadowString ?? '', 10) || 0;
        const centerPoint = rect.left + boxShadow + unknownOffset + rect.width / 2;
        return 100 * (centerPoint / totalW);
      });

      setItemCenterPoints(newItemCenterPoints);
    };

    useEffect(() => {
      const throttledResize = throttle(setCenterpointDistances, 100);
      setCenterpointDistances();
      const delayMeasure = setTimeout(setCenterpointDistances, 100);
      window.addEventListener('resize', throttledResize);

      return () => {
        clearTimeout(delayMeasure);
        window.removeEventListener('resize', throttledResize);
      };
    }, []);

    const getCurrentPageIndex = () => {
      const index = navItems.findIndex((item) => {
        const compareHref = getHref(item);
        return compareHref === location?.pathname;
      });
      return index > -1 ? index : 0;
    };

    const getPercComplete = () => {
      let stepIndex = getCurrentPageIndex();
      stepIndex = stepIndex > itemCenterPoints.length ? itemCenterPoints.length - 1 : stepIndex;
      const centerPoint = itemCenterPoints[stepIndex] || 0;
      return centerPoint;
    };

    const handleClick = (stepComplete: boolean, item: NavItem) => () => {
      const href = getHref(item);
      if (item.action) {
        item.action(navProps);
      } else if (href) {
        // Use step complete here to block redirect if the user isnt on the next step
        history.push({
          pathname: href,
          search: location.search,
        });
      }

      return false;
    };

    const percComplete = getPercComplete();
    const stepIndex = getCurrentPageIndex();

    return (
      <>
        <GenericMessageBanner {...bannerProps} ref={bannerRef} />
        <styles.MainHeaderWithProgressStyle
          shouldHideProgressBar={shouldHideProgressBar}
          ref={headerContainerRef}
        >
          <Container flex>
            <styles.HeaderContentStyle>
              <Logo href={logoHref} />
              {nav ? (
                <styles.ProgressItemContainerStyle>
                  {navItems.map((item, i) => {
                    const href = getHref(item);
                    let stepComplete =
                      !!percComplete && // if there is a percentage complete computed
                      (stepIndex > i || // and the currentPage is greater than the current
                        stepIndex === i); // or the currentPage is the current and they have completed it
                    // Override the above logic if we are to not show the progress bar (specifically for partner ATM)
                    if (shouldHideProgressBar) {
                      stepComplete = i === stepIndex;
                    }

                    return (
                      <styles.ProgressItemStyle
                        className={classnames({ completed: stepComplete })}
                        shouldHideProgressBar={shouldHideProgressBar}
                        onClick={handleClick(stepComplete, item)}
                        key={getText(item)}
                        ref={(el) => {
                          itemRefs.current[i] = el;
                        }}
                        completed={stepComplete}
                        data-ga-tracking-id={`Nav${href?.charAt(0).toUpperCase()}${href?.slice(1)}`}
                      >
                        <span>{getText(item)}</span>
                      </styles.ProgressItemStyle>
                    );
                  })}
                </styles.ProgressItemContainerStyle>
              ) : null}
            </styles.HeaderContentStyle>
          </Container>
          <HeaderProgress perc={percComplete} shouldHideProgressBar={shouldHideProgressBar} />
        </styles.MainHeaderWithProgressStyle>
      </>
    );
  }
);

export default MainHeaderWithProgress;
