import styled from 'styled-components';
import { Header, pxToRem } from '@pointdotcom/pds';

export const HeaderCapsStyle = styled(Header).attrs({ className: 'HeaderCapsStyle' })`
  font-size: ${pxToRem(15)};
  margin: 0.3rem 0 0.3rem;
  > * {
    font-weight: normal;
    letter-spacing: 0.1em;
  }
`;

export const HeaderUpperLowerStyle = styled.header.attrs({ className: 'HeaderUpperLowerStyle' })`
  @media only print {
    > * {
      text-align: left;
    }
  }
`;
