export default {
  pageName: 'Home Value',
  continue: 'Continue',
  errorMessage: 'Please enter an estimated home value.',
  yourHomeValue: 'Your home value',
  howMuch: 'How much do you think your home is worth?',
  theZillow: 'The Zillow Zestimate<sup>&reg;</sup> home valuation for %address% is %amount%',
  theHomeValuation: 'The estimated home valuation for %address% is %amount%',
  howWillPoint: 'How will Point determine the value of my home?',
  weWillUse:
    'We will use the value from a third-party valuation service. The value you submit on this page will only be used for your initial estimate and rate when the third-party service isn’t immediately available.',
  learnMore: 'Learn more here.',

  howWasThis: 'How was this estimate calculated?',
  theEstimated:
    'The estimated home valuation listed above was generated from publicly available data and is intended to be informational. Please accept or update this figure based on what you believe your home to be worth. The stated value you submit on this page will be used for your initial estimate. If you submit a complete application, we will verify your stated home value by a third-party valuation service.',
  doINeed: 'Do I need to own my home?',
  pointsHei: 'Point’s HEI is only available to individuals who own their home.',
  higher: 'higher',
  lower: 'lower',
};
