import * as z from "zod";

export const successResponseSchema = z
  .object({
    success: z.literal(true),
  })
  .catchall(z.unknown() as z.ZodType);
export type SuccessResponse = z.infer<typeof successResponseSchema>;

export const errorResponseSchema = z
  .object({
    success: z.literal(false),
    message: z.string(),
  })
  .catchall(z.unknown() as z.ZodType);
export type ErrorResponse = z.infer<typeof errorResponseSchema>;

/**
 * Cookie which authenticates the user
 */
export const authenticationCookieSchema = z
  .string()
  .regex(new RegExp("^[A-Za-z0-9+/=]+$"));
