import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  Button,
  Checkbox,
  Container,
  DirectionAndPlacement,
  FormChangeEvent,
  Header,
  HelpTextAnimated,
  LinkButton,
  ModalProps,
  Size,
  SplashText,
  Style,
  TemplatedText,
  TextArea,
  messagingI18n,
} from '@pointdotcom/pds';
import Paginator from 'components/Paginator';
import { useAccountManagerContactInfo } from 'containers/hooks/useAccountManagerContactInfo';
import ContactModel from 'models/ContactModel';
import { DashboardAccountManager } from 'models/DashboardModel';
import { usePostHelpRequestMutation } from 'services/api/homeownerApi';
import i18n from './i18n';
import * as styles from './styles';

const AccountManagerSection = ({ accountManager }: { accountManager: DashboardAccountManager }) => {
  const amContactInfo = useAccountManagerContactInfo(accountManager);

  if (!amContactInfo) return null;
  const { name, image, email, phoneNumber } = amContactInfo;

  return (
    <styles.ToAccountManagerSectionStyle>
      {image && (
        <div>
          <img src={image} alt={name} />
        </div>
      )}
      <div>
        <div>
          <TemplatedText values={{ name }}>{i18n.toName}</TemplatedText>
        </div>
        <div>
          <a href={`mailto:${email}`}>{email}</a>
        </div>
        <div>
          <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </div>
      </div>
    </styles.ToAccountManagerSectionStyle>
  );
};

type Question = {
  label: string;
  hasCustomText?: boolean;
};

const questionDefinitions = {
  howDoIFind: { label: i18n.howDoIFind },
  whatInformation: { label: i18n.whatInformation },
  other: { label: i18n.other, hasCustomText: true },
};

type QuestionId = keyof typeof questionDefinitions;
const questions: Record<QuestionId, Question> = questionDefinitions;

const questionList: QuestionId[] = ['howDoIFind', 'whatInformation', 'other'];

const FormSection = ({
  onSubmit,
  isLoading,
  isError,
}: {
  onSubmit?: (question: string) => void;
  isLoading: boolean;
  isError: boolean;
}) => {
  const [selectedQuestionId, setSelectedQuestionId] = useState<undefined | QuestionId>(undefined);
  const [otherQuestionText, setOtherQuestionText] = useState('');
  const handleCheckboxChange: FormChangeEvent = (e, { value }) => {
    setSelectedQuestionId(value as QuestionId);
  };
  const handleOtherQuestionTextChange: FormChangeEvent = (e, { value }) => {
    setOtherQuestionText(value);
  };

  const selectedQuestion = selectedQuestionId ? questions[selectedQuestionId] : undefined;
  const questionTextToSubmit =
    selectedQuestion &&
    (selectedQuestion.hasCustomText ? otherQuestionText : selectedQuestion.label);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (questionTextToSubmit != null) {
      onSubmit?.(questionTextToSubmit);
    }
  };

  const hasTextToSubmit = questionTextToSubmit != null && questionTextToSubmit.length >= 1;

  return (
    <styles.FormSectionStyle onSubmit={handleSubmit}>
      <styles.CheckboxContainerStyle>
        {questionList.map((questionId) => (
          <Checkbox
            label={questions[questionId].label}
            onChange={handleCheckboxChange}
            checkVal={selectedQuestionId}
            value={questionId}
            noMargin
            key={questionId}
            checkedElement={
              questions[questionId].hasCustomText ? (
                <TextArea
                  placeholder={i18n.tellUsMore}
                  onChange={handleOtherQuestionTextChange}
                  value={otherQuestionText}
                />
              ) : undefined
            }
          />
        ))}
      </styles.CheckboxContainerStyle>
      <styles.SubmitButtonContainerStyle>
        <Button
          type="submit"
          content={i18n.submit}
          block
          disabled={isLoading || !hasTextToSubmit}
          loading={isLoading}
        />
        <HelpTextAnimated
          noMargin
          show={isError}
          styleType={Style.Error}
          styleAlign={DirectionAndPlacement.Center}
        >
          {messagingI18n.errors.unknownError}
        </HelpTextAnimated>
      </styles.SubmitButtonContainerStyle>
    </styles.FormSectionStyle>
  );
};

const HaveAQuestionModal = ({
  accountManager,
  homeowner,
  ...restOfProps
}: ModalProps & { accountManager: DashboardAccountManager; homeowner: ContactModel }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [postHelp, { isLoading, isSuccess, isError }] = usePostHelpRequestMutation();
  const { taskId } = useParams();

  const handleSubmit = async (homeownerQuestion: string) => {
    if (taskId) {
      await postHelp({
        id: taskId,
        data: {
          homeownerContactInfo: {
            name: homeowner.getFullName(),
            email: homeowner.email,
          },
          homeownerQuestion,
        },
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setPageIndex(1);
    }
  }, [isSuccess]);

  const handleModalExited = () => {
    setPageIndex(0);
  };

  const defaultContent = (
    <styles.DefaultContentContainerStyle>
      <Container>
        <Header styleSize={Size.Large}>Have a question?</Header>
        <Header styleSize={Size.Medium} noMargin>
          We will send this message to your account manager
        </Header>
      </Container>
      <AccountManagerSection accountManager={accountManager} />
      <FormSection onSubmit={handleSubmit} isLoading={isLoading} isError={isError} />
    </styles.DefaultContentContainerStyle>
  );

  const emailSentContent = (
    <styles.EmailSentContainerStyle>
      <img src="https://cdn.point.com/envelope.png" alt="envelope with checkmark" />
      <div>
        <Header styleSize={Size.Large}>{i18n.messageSent}</Header>
        <SplashText>
          {i18n.thankYou}
          <br />
          <br />
          <LinkButton onClick={restOfProps.onModalClose}>{i18n.close}</LinkButton>
        </SplashText>
      </div>
    </styles.EmailSentContainerStyle>
  );

  return (
    <styles.HaveAQuestionModalStyle {...restOfProps} width="700px" onExited={handleModalExited}>
      <Paginator items={[defaultContent, emailSentContent]} currentIndex={pageIndex} />
    </styles.HaveAQuestionModalStyle>
  );
};

export default HaveAQuestionModal;
