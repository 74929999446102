import React from 'react';
import { Container } from '@pointdotcom/pds';
import DashboardTask from './DashboardTask';
import { DashboardTasksProps } from './constants';
import * as styles from './styles';

export default function DashboardTasks({
  tasks,
  limit,
  onClickTask,
  compact = true,
  mobileShadowed = true,
}: DashboardTasksProps) {
  return (
    <styles.DashboardTasksStyle compact={compact} mobileShadowed={mobileShadowed}>
      <Container mobileCollapse>
        {tasks != null &&
          tasks
            .slice(0, limit ?? tasks.length)
            .map((task) => <DashboardTask key={task.id} task={task} onClick={onClickTask} />)}
      </Container>
    </styles.DashboardTasksStyle>
  );
}
