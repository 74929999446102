import styled from 'styled-components';
import { ContainerStyles, HeaderStyles, pxToRem } from '@pointdotcom/pds';
import { OfferAmountSectionStyle } from './OfferAmountSection/styles';
import { singleColumnBreakpoint } from './constants';

export const TimelineDashboardStyle = styled.div.attrs({ className: 'DashboardStyle' })<{
  bannerOpen: boolean;
}>`
  --globalBannerOpenPadding: 24px;
  transition: all 200ms ease-in-out;
  padding-top: ${({ bannerOpen }) => (bannerOpen ? 'var(--globalBannerOpenPadding)' : 0)};
  background: ${({ theme }) => theme.Color.White};

  --blockPadding: ${pxToRem(62)};
  --inlinePadding: ${pxToRem(50)};

  @media (max-width: ${singleColumnBreakpoint}) {
    --blockPadding: ${pxToRem(32)};
    --inlinePadding: ${pxToRem(32)};
    --globalBannerOpenPadding: 80px;
    > ${ContainerStyles.ContainerStyle} {
      padding: 0;
    }
  }
`;

export const GreetingStyle = styled.div.attrs({ className: 'GreetingStyle' })`
  padding-block: var(--blockPadding);
  border-bottom: 1px solid ${({ theme }) => theme.line.color.default};
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  ${HeaderStyles.HeaderStyle} {
    > * {
      text-align: center;
    }
  }
  @media (max-width: ${singleColumnBreakpoint}) {
    margin-inline: var(--inlinePadding);
    ${HeaderStyles.HeaderStyle} {
      > * {
        text-align: left;
      }
    }
  }

  & ${HeaderStyles.HeaderStyle} > * {
    font-family: ${({ theme }) => theme.fonts.serif.name};
    font-style: italic;
    font-weight: 400;
    font-size: ${pxToRem(16)};

    @media (max-width: ${singleColumnBreakpoint}) {
      font-size: ${pxToRem(14)};
    }
  }
`;

export const MainContentStyle = styled.div.attrs({ className: 'MainContentStyle' })`
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-top: var(--blockPadding);
  border-bottom: 1px solid ${({ theme }) => theme.line.color.default};

  h1 {
    margin: 0;
  }

  @media (max-width: ${singleColumnBreakpoint}) {
    grid-template-columns: 1fr;
    border-bottom: none;

    & ${OfferAmountSectionStyle} {
      border-bottom: 1rem solid ${({ theme }) => theme.Color.GrayLight1};
      margin-bottom: var(--blockPadding);
    }
  }
`;

export const DetailSectionsStyle = styled.div.attrs({ className: 'DetailSectionsStyle' })`
  border-left: 1px solid ${({ theme }) => theme.line.color.default};
  overflow: hidden;

  @media (max-width: ${singleColumnBreakpoint}) {
    border-left: none;
    padding: 0;
  }
`;
