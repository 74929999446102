import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Location, useLocation, useNavigate } from 'react-router';
import {
  ButtonClickFunc,
  Container,
  Icon,
  IconName,
  LinkButton,
  Logo,
  Style,
} from '@pointdotcom/pds';
import GenericMessageBanner, { GenericBannerProps } from 'components/GenericMessageBanner';
import { MainHeaderRefs } from 'components/MainHeader/constants';
import { NavItem, NavProps, getNavItemProp } from 'components/MainHeader/nav';
import PopupMenu from 'components/PopupMenu';
import { setMainHeaderMobileNavMenuOpen } from 'store/general';
import { useDispatch } from 'store/hooks';
import { MainHeaderContainerStyle } from '../MainHeader/styles';
import i18n from './i18n';
import {
  FullNavStyle,
  MainHeaderStyle,
  MainNavItemStyle,
  MainNavStyle,
  PopupNavStyle,
} from './styles';

const MIN_MOBILE_NAV_ITEMS = 2;

interface MainNavProps {
  popupLinkRef: React.Ref<HTMLButtonElement>;
  navItems: ReadonlyArray<NavItem>;
  navProps: NavProps;
  location: Location;
}

function MainNav({ popupLinkRef, navItems, navProps, location }: MainNavProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (!navItems) {
    return null;
  }

  let timeout: undefined | NodeJS.Timeout;
  const handlePopupNavTap = () => {
    dispatch(setMainHeaderMobileNavMenuOpen(true));
  };

  const hasMinPopupItems = navItems.length >= MIN_MOBILE_NAV_ITEMS;
  const getHref = getNavItemProp('href', navProps);
  const getText = getNavItemProp('text', navProps);

  const handleClick =
    (item: NavItem): ButtonClickFunc =>
    (e) => {
      e.preventDefault();
      const href = getHref(item);
      if (timeout) {
        clearTimeout(timeout);
      }
      if (item.action) {
        item.action(navProps);
      } else if (href) {
        navigate({
          pathname: href,
          search: location.search,
        });
      }
    };

  return (
    <MainNavStyle>
      <FullNavStyle showAsPopup={navProps?.showAsPopup} hasMinPopupItems={hasMinPopupItems}>
        {navItems.map((item, i) => {
          const current = location.pathname.replace(/\/$/g, '') === getHref(item);
          return (
            <MainNavItemStyle key={getText(item)} current={current}>
              <LinkButton
                data-current={current}
                onClick={handleClick(item)}
                data-ga-tracking-id={`Nav${getText(item)?.replace(/\s/g, '')}`}
              >
                {getText(item)}
              </LinkButton>
              {i === navItems.length - 1 ? null : (
                <Icon name={IconName.ChevronRight} styleType={Style.Subtle} styleSize={25} />
              )}
            </MainNavItemStyle>
          );
        })}
      </FullNavStyle>
      {hasMinPopupItems && (
        <PopupNavStyle
          aria-label={i18n.mainMenuButton}
          onClick={handlePopupNavTap}
          isOnlyPopup={navProps.showAsPopup}
          ref={popupLinkRef}
        >
          <i />
          <i />
          <i />
        </PopupNavStyle>
      )}
    </MainNavStyle>
  );
}

interface MainHeaderWithSequenceProps {
  bannerProps?: GenericBannerProps;
  logoHref?: null | string;
  nav?: boolean;
  navItems?: ReadonlyArray<NavItem>;
  navProps: NavProps;
  scrolled?: boolean;
}

const MainHeaderWithSequence = forwardRef<MainHeaderRefs, MainHeaderWithSequenceProps>(
  (
    { bannerProps = {}, logoHref = null, nav = true, navItems = [], navProps, scrolled = false },
    ref
  ) => {
    const location = useLocation();
    const popupLinkRef = useRef<null | HTMLButtonElement>(null);
    const hasMinPopupItems = navItems.length >= MIN_MOBILE_NAV_ITEMS;
    const bannerRef = useRef<HTMLDivElement>(null);
    const headerContainerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      bannerRef,
      headerContainerRef,
    }));

    return (
      <>
        <GenericMessageBanner {...bannerProps} ref={bannerRef} />
        <MainHeaderContainerStyle scrolled={scrolled} ref={headerContainerRef}>
          <Container flex>
            <MainHeaderStyle>
              <Logo href={logoHref ?? undefined} />
              {nav ? (
                <MainNav
                  location={location}
                  navItems={navItems}
                  navProps={navProps}
                  popupLinkRef={popupLinkRef}
                />
              ) : null}
            </MainHeaderStyle>
          </Container>
        </MainHeaderContainerStyle>
        {hasMinPopupItems && (
          <PopupMenu navItems={navItems} navProps={navProps} clickedElement={popupLinkRef} />
        )}
      </>
    );
  }
);

export default MainHeaderWithSequence;

// TODO: scrollspy needed
