import React from 'react';
import {
  Button,
  DirectionAndPlacement,
  HelpText,
  Modal,
  Size,
  Style,
  TemplatedText,
} from '@pointdotcom/pds';
import i18n from './i18n';
import * as styles from './styles';

interface ConfirmationModalProps {
  isOpen: boolean;
  onModalClose: () => void;
  onSubmit: () => void;
  percDiff: number;
  loading: boolean;
  avmIsFromZillow: boolean;
}

export default function ({
  isOpen,
  onModalClose,
  onSubmit,
  percDiff,
  loading,
  avmIsFromZillow,
}: ConfirmationModalProps) {
  const avmDescription = avmIsFromZillow ? i18n.theValueYouZillow : i18n.theValueYou;
  const helperText = avmIsFromZillow ? i18n.zillowIsAn : i18n.ourEstimate;
  return (
    <Modal isOpen={isOpen} onModalClose={onModalClose} headerText="Home Value Alert">
      <styles.ZestimateFormLayoutStyle>
        <p>
          <TemplatedText
            values={{
              amount: `${Math.round(Math.abs(percDiff))}%`,
              higherLower: <b>{percDiff < 0 ? 'lower' : 'higher'}</b>,
            }}
          >
            {avmDescription}
          </TemplatedText>
        </p>
        <p>{i18n.laterIn}</p>
        <HelpText>{helperText}</HelpText>
        <div className="ButtonRow">
          <Button
            type="button"
            styleSize={Size.Small}
            content={i18n.adjustValue}
            styleType={Style.Tertiary}
            onClick={onModalClose}
          />
          <Button
            type="button"
            styleSize={Size.Small}
            content={i18n.useMyValue}
            loading={loading}
            onClick={onSubmit}
            styleAlign={DirectionAndPlacement.Right}
          />
        </div>
      </styles.ZestimateFormLayoutStyle>
    </Modal>
  );
}
