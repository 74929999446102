import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { templatedString } from '@pointdotcom/pds';
import { Page, generateUrlFromPage } from 'containers/helpers';
import { useTaskCompletionModal } from 'containers/hooks/useTaskCompletionModal';
import { SubscribableValue, waitForSubscribable } from './awaitableSubscription';
import i18n from './i18n';
import { useApplicationProcessingOutcome } from './useApplicationProcessingOutcome';

function promiseWithTimeout<T>(promise: PromiseLike<T>, timeoutMs: number): Promise<undefined | T> {
  return Promise.race([
    promise,
    new Promise<undefined>((resolve) => {
      setTimeout(() => resolve(undefined), timeoutMs);
    }),
  ]);
}

export function useHandleApplicationSubmitted() {
  const [isProcessing, setIsProcessing] = useState(false);

  const applicationProcessingOutcome = useApplicationProcessingOutcome();
  const applicationProcessingOutcomeSubscribable = useRef(
    new SubscribableValue(applicationProcessingOutcome)
  ).current;
  useEffect(() => {
    applicationProcessingOutcomeSubscribable.value = applicationProcessingOutcome;
  }, [applicationProcessingOutcome, applicationProcessingOutcomeSubscribable]);

  const navigate = useNavigate();

  const showTaskCompletionModal = useTaskCompletionModal();

  const handleApplicationSubmitted = useCallback(async () => {
    try {
      setIsProcessing(true);
      const outcome = await promiseWithTimeout(
        waitForSubscribable(
          applicationProcessingOutcomeSubscribable,
          (newOutcome) => !newOutcome.isProcessing
        ),
        60_000
      );
      if (outcome == null) {
        // If timed out, just redirect to dashboard:
        navigate(generateUrlFromPage(Page.DASHBOARD));
        return;
      }
      const { showDashboardModal, applicationTask, dashboard, navigateTo } = outcome;
      if (showDashboardModal) {
        // Show task completion modal
        const name = dashboard?.getContact().firstName;
        showTaskCompletionModal({
          task: applicationTask,
          title:
            name != null
              ? templatedString({ template: i18n.congratsWithName, values: { name } })
              : i18n.congrats,
          heading: i18n.applicationReceived,
        });
      }
      navigate(navigateTo ?? generateUrlFromPage(Page.DASHBOARD));
    } finally {
      setIsProcessing(false);
    }
  }, [applicationProcessingOutcomeSubscribable, navigate, showTaskCompletionModal]);

  return { handleApplicationSubmitted, isProcessing };
}
