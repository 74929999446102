import styled, { DefaultTheme, css, keyframes } from 'styled-components';
import {
  ButtonLeftRightStyles,
  ContainerStyles,
  HelpTextStyles,
  IconStyles,
  InputStyles,
  Style,
  mixins,
  mobileScreen,
  pxToRem,
} from '@pointdotcom/pds';
import { Products } from 'store/constants';
import { cssUrl } from 'styleHelpers';
import { HeaderDescriptionStyle } from '../styles';
import { CapShieldIconStyle } from './CapShieldIcon/styles';
import {
  ScenariosTableCapTipCardStyle,
  ScenariosTableCapTipStyle,
  arrowSize as capTipArrowSize,
} from './ScenariosTableCapTip/styles';
import declineSVG from './images/decline.svg';
import declineSVGFade from './images/decline_fade.svg';
import highSVG from './images/high.svg';
import highSVGFade from './images/high_fade.svg';
import moderateSVG from './images/moderate.svg';
import moderateSVGFade from './images/moderate_fade.svg';
import noneSVG from './images/none.svg';
import noneSVGFade from './images/none_fade.svg';
import { ScenarioType } from './scenarios';

const numCellsWithValues = 4;
const headerSize = '40%';
const headerSizeMobile = '150px';
const cellSize = '17%';
const ctaCellSize = `${100 - numCellsWithValues * parseInt(cellSize, 10)}%`;
const cellContainerSize = `${100 - parseInt(headerSize, 10)}%`;
const cellPadding = '10px';
const cellPaddingMobile = '25px';
const cellPaddingMobileBottom = `${Math.round(parseInt(cellPaddingMobile, 10) * 1.8)}px`;
const cellHeight = '15vh';
const percFontSize = 45;
const dollarFontSize = 28;
const minTableWidth = '960px';

const lookyAnimation = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50%);
  }
`;

const lookyAnimationVert = keyframes`
  0%, 100% {
    transform: translateY(100%) rotate(90deg) scale(1.5);
  }
  50% {
    transform: translateY(-1%) rotate(90deg) scale(1.5);
  }
`;

export interface ScenarioProps {
  product?: Products;
  scenario: null | ScenarioType;
}

type ScenarioResource = {
  color?: string;
  svg?: string;
  svgInTable?: string;
  posY?: string;
};

export const scenarioRescourceMap = ({
  scenario,
  theme,
}: { theme: DefaultTheme } & ScenarioProps): ScenarioResource => {
  if (scenario == null) {
    return {};
  }

  const baseColor = theme.Color.PurpleBlue6;
  const resourceMap: Record<ScenarioType, ScenarioResource> = {
    [ScenarioType.HIGH]: {
      color: mixins.lightenDarkenColor(baseColor, -0.06),
      svg: highSVG,
      svgInTable: highSVGFade,
      posY: '0',
    },
    [ScenarioType.MODERATE]: {
      color: mixins.lightenDarkenColor(baseColor, -0.03),
      svg: moderateSVG,
      svgInTable: moderateSVGFade,
      posY: '50px',
    },
    [ScenarioType.NONE]: {
      color: mixins.lightenDarkenColor(baseColor, 0),
      svg: noneSVG,
      svgInTable: noneSVGFade,
      posY: '85px',
    },
    [ScenarioType.DECLINE]: {
      color: mixins.lightenDarkenColor(baseColor, 0.02),
      svg: declineSVG,
      svgInTable: declineSVGFade,
      posY: '0',
    },
    [ScenarioType.CUSTOM]: {
      color: theme.Color.PointBlack,
      posY: '-100%',
    },
  };
  return resourceMap[scenario] || {};
};

export const ScenarioHeaderFonts = css`
  font-family: ${({ theme }) => theme.fonts.sansSerif.name};
  font-weight: 900;
  font-size: ${pxToRem(21)};
`;

export const PercLabelStyle = styled.aside.attrs({ className: 'PercLabelStyle' })`
  max-width: 125px;
  margin-left: 5%;
  padding-left: 5%;
  border-left: 1px solid ${(props) => props.theme.line.color.invertedSubtle};
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    border-left: none;
    margin: 0;
    max-width: none;
    white-space: nowrap;
    padding-left: 0;
    &:before {
      content: '\u00a0';
      display: inline-block;
    }
  }
  @media only print {
    color: ${(props) => props.theme.Color.PointBlack};
    max-width: none;
    border: none;
    padding: 0;
    margin: 0;
    &:before {
      content: '\u00a0';
      display: inline-block;
    }
  }
`;

export const PercNumberStyle = styled.div.attrs({ className: 'PercNumberStyle' })<{
  isHomeValue: boolean;
}>`
  @media only screen and (min-width: ${({ theme }) => theme.responsive.tabletBreakpoint}) {
    font-family: ${mixins.getHeaderFontFamily({ styleType: Style.Serif })};
    font-size: ${({ isHomeValue }) => pxToRem(isHomeValue ? dollarFontSize : percFontSize)};
  }
  @media only print {
    color: ${(props) => props.theme.Color.PointBlack};
  }
`;

const HeaderContentStyle = styled.div`
  transition: opacity 480ms ease-in-out;
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    position: relative;
  }
  @media only print {
    position: relative;
    height: auto;
  }
`;

export const HeaderContentScenarioLabelStyle = styled(HeaderContentStyle).attrs({
  className: 'HeaderContentScenarioLabelStyle',
})`
  opacity: 1;
  ${ScenarioHeaderFonts};
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  > span {
    font-weight: 400;
    font-size: ${pxToRem(14)};
    font-family: ${(props) => props.theme.fonts.sansSerif.name};
    margin-top: 0.4rem;
  }
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    > span {
      display: none;
    }
    padding: 50px ${cellPaddingMobile} 30px;
    height: ${headerSizeMobile};
    max-width: 50%;
  }
  @media only print {
    color: ${(props) => props.theme.Color.PointBlack};
    border-bottom: 1px solid ${(props) => props.theme.line.color.invertedSubtle};
    padding-bottom: 5px;
    margin-bottom: 5px;
    font-size: ${(props) => mixins.getRemFromPx(22, props.theme.globals.baseFontSize.default)};
  }
`;

export const HeaderContentOverStyle = styled(HeaderContentStyle).attrs({
  className: 'HeaderContentOverStyle',
})`
  opacity: 0;
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    opacity: 1;
    height: auto;
    align-items: baseline;
    flex-flow: row nowrap;
    border-top: 1px solid ${(props) => props.theme.line.color.invertedSubtle};
    padding: ${cellPadding} ${cellPaddingMobile};
    font-size: ${(props) => mixins.getRemFromPx(13, props.theme.globals.baseFontSize.default)};
  }
  @media only print {
    opacity: 1;
    height: auto;
    align-items: baseline;
    flex-flow: row nowrap;
  }
`;

export const PercSignStyle = styled.span.attrs({ className: 'PercSignStyle' })`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: ${(props) => props.theme.fonts.serif.name};
  font-size: ${pxToRem(percFontSize)};
`;

const isHomeValueCSS = css`
  padding-right: 1.3rem;

  // HACK: TODO: Fix the centering issue with the decrement button when resized ( - )
  ${ButtonLeftRightStyles.ButtonLeftRightStyle} {
    [aria-label='decrement'] {
      i {
        margin-left: 0.5px;
        width: 11px;
        height: 11px;
      }
    }
    button {
      background: white;
      padding: 0.7rem;
    }
  }

  ${InputStyles.InputContainerStyle} {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    &:before {
      content: '$';
      font-family: ${(props) => props.theme.fonts.serif.name};
      font-size: ${pxToRem(percFontSize)};
    }
  }
  ${mobileScreen} {
    padding-inline: 1.5rem;
    ${InputStyles.InputContainerStyle} {
      input {
        font-size: 1.5rem;
      }
      ${InputStyles.InputContainerInnerStyle} {
        > * {
          &:nth-child(2) {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            &:before {
              content: '$';
              font-family: ${(props) => props.theme.fonts.serif.name};
              font-size: calc(${pxToRem(percFontSize)} - 5px);
            }
          }
        }
      }
      &:before {
        display: none;
      }
    }
  }
`;

export const CustomInputFormStyle = styled.form.attrs({ className: 'CustomInputFormStyle' })<{
  hasValue: boolean;
  isHomeValue: boolean;
}>`
  transition: all 250ms ease-in-out;
  margin: 0;
  ${({ isHomeValue }) => isHomeValue && isHomeValueCSS}
`;

export const HeaderContentCustomHeaderStyle = styled.header.attrs({
  className: 'HeaderContentCustomHeaderStyle',
})`
  ${ScenarioHeaderFonts}
`;

export const HeaderContentCustomStyle = styled(HeaderContentStyle).attrs({
  className: 'HeaderContentCustomStyle',
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${HelpTextStyles.HelpTextStyle} {
    background: none;
    color: ${({ theme }) => theme.Color.Gray3};
  }

  ${mobileScreen} {
    ${HelpTextStyles.HelpTextStyle} {
      text-align: center;
      padding-inline: 1.5rem;
    }
    align-items: center;
  }
`;

const isPercValueCSS = css`
  padding-inline: 1.6rem;
  gap: 0.3rem;
  text-align: center;
`;

// prettier-ignore
// prettier-ignore needed for line 371 (wrapping breaks the line and throws)
export const HeaderContentCustomRowStyle = styled.div.attrs({
  className: 'HeaderContentCustomRowStyle',
})<{ hasValue: boolean; isHomeValue: boolean }>`
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  ${({ isHomeValue }) => !isHomeValue && isPercValueCSS};

  ${InputStyles.InputStyle} {
    font-family: ${mixins.getHeaderFontFamily({ styleType: Style.Serif })};
    font-size: ${({ isHomeValue }) => pxToRem(isHomeValue ? dollarFontSize : percFontSize)};
    padding: 0.5rem;
    border-radius: 0;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    color: white;
    padding: 0;
  }
  ${PercLabelStyle} {
    border: none;
  }
  
  @media only screen and (min-width: ${(props) => `${parseInt(props.theme.responsive.largestMobileScreen, 10) + 1}px`}) {
    .InputContainerInnerStyle {
      > div {
        &:first-child,
        &:last-child {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    height: ${headerSizeMobile};
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: center;

    > * {
      flex: 0 1 auto;
    }

    ${PercSignStyle} {
      ${({ isHomeValue }) =>
        !isHomeValue &&
        css`
          display: none;
        `}
    }
  }
`;

export const HeaderContentSpacerStyle = styled.div.attrs({
  className: 'HeaderContentSpacerStyle',
})`
  width: ${cellPadding};
`;

export const HeaderContentCustomInnerStyle = styled(HeaderContentStyle).attrs({
  className: 'HeaderContentCustomInnerStyle',
})`
  display: flex;
  align-items: center;
`;

const ScenarioItemContentBaseStyle = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: ${cellHeight};
  min-height: 100px;
`;

export const ScenarioHeaderItemContentStyle = styled(ScenarioItemContentBaseStyle).attrs({
  className: 'ScenarioHeaderItemContentStyle',
})<{ collapsed?: boolean }>`
  height: ${(props) => (props.collapsed ? '80px' : '10vh')};
  min-height: 70px;
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    display: ${(props) => (props.collapsed ? 'none' : 'flex')};
    min-height: auto;
  }
`;

const ScenarioCellCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${(props) => props.theme.line.color.default};
  box-sizing: border-box;
  width: ${cellSize};
  flex: ${cellSize};
  padding: ${cellPadding};
`;

const ScenarioCellBaseStyle = styled.div`
  ${ScenarioCellCSS}

  sup {
    padding-left: 0.15rem;
  }

  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding: ${cellPaddingMobile};
  }
`;

export const ScenarioCellStyle = styled(ScenarioCellBaseStyle).attrs({
  className: 'ScenarioCellStyle' as string,
})<{ capTipIsShown?: null | boolean; tooltipHangsDown?: boolean }>`
  position: relative;

  > aside {
    font-family: ${(props) => props.theme.fonts.sansSerif.name};
    margin-bottom: 5px;
    display: none;
  }
  font-family: ${(props) => props.theme.fonts.sansSerif.name};
  font-weight: 500;
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    flex: auto;
    width: 100%;
    border-right: none;
    border-bottom: 1px solid ${(props) => props.theme.line.color.default};
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column wrap;
    &:last-child {
      width: 100%;
      flex: auto;
    }
    > aside {
      display: block;
    }
  }

  ${CapShieldIconStyle} {
    @media not (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
      position: absolute;
      top: 50%;
      margin: -2.25rem auto 0;
    }

    @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
      position: relative;
    }

    ${ScenariosTableCapTipStyle} {
      @media not (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
        ${({ tooltipHangsDown = false }) =>
          tooltipHangsDown
            ? css`
                bottom: calc(-5px - 2rem);

                ${ScenariosTableCapTipCardStyle} {
                  top: ${capTipArrowSize}px;
                  bottom: auto;

                  &::before {
                    top: ${-capTipArrowSize}px;
                    bottom: auto;
                    transform: scaleY(-1);
                  }
                }
              `
            : css`
                top: -5px;
              `}
      }
      @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
        top: calc(-5px - 1.75rem);
      }
      left: 50%;
    }
  }

  ${({ capTipIsShown }) =>
    capTipIsShown === false &&
    css`
      ${ScenariosTableCapTipStyle} {
        display: none;
      }
    `}

  @media (hover: hover) and (pointer: fine) {
    &:not(:hover) {
      ${ScenariosTableCapTipStyle} {
        ${({ capTipIsShown }) =>
          !capTipIsShown &&
          css`
            display: none;
          `}
      }
    }
  }

  @media not (hover: hover), not (pointer: fine) {
    ${({ capTipIsShown }) =>
      !capTipIsShown &&
      css`
        ${ScenariosTableCapTipStyle} {
          display: none;
        }
      `}
  }
`;

export const ScenarioCellValueStyle = styled.div.attrs({ className: 'ScenarioCellValueStyle' })`
  @media not (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    display: contents;
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    display: flex;
    gap: 10px;
    align-items: center;
  }
`;

export const ScenarioHeaderCellStyle = styled(ScenarioCellBaseStyle).attrs({
  className: 'ScenarioHeaderCellStyle',
})`
  justify-content: center;
  > span {
    text-align: center;
  }
  @media only print {
    justify-content: center;
    border-bottom: 1px solid ${(props) => props.theme.line.color.default};
    text-align: center;
  }
`;

export const ScenarioCTACellStyle = styled(ScenarioCellStyle).attrs({
  className: 'ScenarioCTACellStyle',
})`
  border-right: none;
  width: ${ctaCellSize};
  flex: ${ctaCellSize};
  > * {
    width: 100%;
    flex: auto;
  }
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    border-bottom: none;
    padding-bottom: ${cellPaddingMobileBottom};
    button {
      width: 100%;
    }
  }
  @media only print {
    display: none;
  }
`;

const ScenarioHeaderColumnCSS = css`
  width: ${headerSize};
  flex: ${headerSize};
  @media only print {
    border-width: 0;
    border-style: solid;
    border-color: ${(props) => props.theme.line.color.default};
    border-right-width: 1px;
    border-bottom-width: 1px;
  }
`;

export const TableTicksStyle = styled.div.attrs({ className: 'TableTicksStyle' })`
  background: repeating-linear-gradient(
    to right,
    ${({ theme }) => theme.line.color.invertedSubtle} 0px,
    ${({ theme }) => theme.line.color.invertedSubtle} 1px,
    transparent 1px,
    transparent 10%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
`;

export const ScenarioHeaderColumnStyle = styled.header.attrs({
  className: 'ScenarioHeaderColumnStyle',
})<ScenarioProps>`
  ${ScenarioHeaderColumnCSS};
  ${ScenarioCellCSS};
  transition: all 280ms ease-in-out;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 ${cellPadding};
  padding-right: 0;
  border-bottom: 1px solid ${(props) => mixins.rgba(props.theme.Color.White, 0.13)};
  > div {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding: 0;
    align-items: flex-start;
    border-bottom: none;
    > div {
      height: auto;
    }
    ${TableTicksStyle} {
      bottom: calc(2.3em + 1px);
    }
    &:after {
      background-position-y: calc(100% - 2.2em);
      bottom: 1px;
    }
  }
  @media only screen {
    border-right: none;
    color: white;
    background-color: ${(props) => scenarioRescourceMap(props).color};
    background-blend-mode: difference;
    background-image: ${(props) => {
      const img = scenarioRescourceMap(props).svgInTable;
      return img && cssUrl(img);
    }};
    transition: all 320ms ease-in-out;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right 300%;
  }
  @media only print {
    display: flex !important;
    align-items: center;
    &:before,
    &:after {
      display: none;
    }
    > div {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
    }
  }
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    background-size: 150%;
    background-position: right 100%;
  }
`;

export const ScenarioHeaderHeaderColumnStyle = styled.div.attrs({
  className: 'ScenarioHeaderHeaderColumnStyle',
})`
  ${ScenarioHeaderColumnCSS};
  ${ScenarioCellCSS};
  justify-content: flex-start;
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    flex: auto;
    width: 100%;
    border: none;
    padding: 0;
    justify-content: center;
    text-align: center;
  }
`;

interface ScenarioItemContentStyleProps {
  hasCustomInputVal?: boolean;
}

export const ScenarioItemContentStyle = styled(ScenarioItemContentBaseStyle).attrs({
  className: 'ScenarioItemContentStyle' as string,
})<ScenarioItemContentStyleProps>`
  > * {
    display: block;
    overflow: visible;
    &::before {
      content: '';
      width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      background-color: inherit;
      border: inherit;
      box-sizing: content-box;
    }
  }
  ${ScenarioHeaderColumnStyle} {
    &::before {
      right: 100%;
    }
  }
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    flex-flow: column nowrap;
    height: 100%;
    > * {
      width: 100%;
      flex: auto 0 0;
      &::before {
        display: none;
      }
    }
  }
  @media only print {
    height: auto;
  }
`;

export const ScenarioItemCustomContentStyle = styled(ScenarioItemContentStyle).attrs({
  className: 'ScenarioItemCustomContentStyle',
})<ScenarioItemContentStyleProps>`
  ${mixins.desktopHoverCondition()} {
    &:hover {
      ${InputStyles.InputStyle} {
        background-color: rgba(255, 255, 255, 0.1);
        border-bottom-color: transparent;
      }
    }
  }
  ${HeaderContentOverStyle} {
    visibility: hidden;
  }
  @media only print {
    ${(props) =>
      !props.hasCustomInputVal
        ? css`
            display: none;
          `
        : ''}
  }
`;

export const ScenarioCellsSectionStyle = styled.section.attrs({
  className: 'ScenarioCellsSectionStyle' as string,
})`
  background-color: ${(props) => props.theme.Color.GrayLight1};
  display: flex;
  flex-flow: row nowrap;
  width: ${cellContainerSize};
  flex: ${cellContainerSize};
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.line.color.default};
  &::before {
    left: 100%;
    border-bottom: inherit;
  }
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    flex-flow: column nowrap;
    width: 100%;
    flex: auto;
    border-bottom: none;
  }
  @media only print {
    > * {
      &:nth-last-child(2) {
        border-right-color: transparent;
      }
    }
  }
`;

export const ScenarioCellsHeaderSectionStyle = styled(ScenarioCellsSectionStyle).attrs({
  className: 'ScenarioCellsHeaderSectionStyle',
})`
  background-color: white;
  border-bottom: none;
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    display: none;
  }
`;

export const ScenarioCellsCustomInputStyle = styled(ScenarioCellsSectionStyle).attrs({
  className: 'ScenarioCellsCustomInput',
})<{ isHomeValue: boolean }>`
  aside {
    margin-left: 20px;
  }
  > div {
    justify-content: flex-start;
    padding: ${parseInt(cellPadding, 10) * 3}px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  @media only screen and (min-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    ${mixins.desktopHoverCondition()} {
      &:hover {
        ${IconStyles.IconStyle} {
          transition: all 300ms ease-in-out;
          animation: ${lookyAnimation} 1s ease-in-out 0s infinite;
          opacity: 1;
          svg path {
            transition: all 300ms ease-in-out;
            fill: ${(props) => props.theme.Color.PointBlack};
          }
        }
      }
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    align-items: center;
    > div {
      flex-flow: column nowrap;
    }
    ${IconStyles.IconStyle} {
      margin-top: 40px;
      animation: ${lookyAnimationVert} 1.5s ease-in-out 0s infinite;
    }
    aside {
      margin: 25% auto 0;
      max-width: 265px;
      text-align: center;
    }
  }
`;

const HoverAnimationStyle = css`
  ${HeaderContentScenarioLabelStyle} {
    opacity: 0;
  }
  ${HeaderContentOverStyle} {
    opacity: 1;
  }
`;

// prettier-ignore
// prettier-ignore needed for line 644 (wrapping breaks the line and throws)
export const ScenarioItemStyle = styled.div.attrs({ className: 'ScenarioItemStyle' })<
  ScenarioProps & { showValues: boolean }
>`
  white-space: normal;

  ${mixins.desktopHoverCondition()} {
    &:hover {
      ${ScenarioHeaderColumnStyle} {
        background-position: right calc(100% + 5px);
      }
      section {
        background-color: ${({ theme }) =>
          mixins.lightenDarkenColor(theme.Color.GrayLight1, -0.03)};
      }
      @media only screen and (min-width: ${({ theme }) => theme.responsive.tabletBreakpoint}) {
        ${({ scenario, showValues }) =>
          scenario !== ScenarioType.CUSTOM && !showValues ? HoverAnimationStyle : null};
      }
    }
  }

  @media only screen and (min-width: ${(props) => props.theme.responsive.mobileLrgBreakpoint}) and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    width: 65% !important;
  }

  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    display: inline-block;
    vertical-align: top;
    width: 80%;
    flex: 1 0 auto;
    border-color: transparent;
    border-style: solid;
    border-right-width: 10px;
    &:last-child {
      border-right-width: 40px;
    }
    &:first-child {
      border-left-width: 40px;
    }
    ${ContainerStyles.ContainerStyle} {
      padding: 0;
      margin: 0 auto !important;
      width: 100% !important;
      height: 100%;
    }
  }
`;

export const ScenariosStyle = styled.div.attrs({ className: 'ScenariosStyle' })`
  min-width: ${minTableWidth};
  overflow: hidden;
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    min-width: auto;
  }
`;

export const ScenarioItemsContainerStyle = styled.div.attrs({
  className: 'ScenarioItemsContainerStyle',
})`
  width: 100%;
  box-sizing: border-box;
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    display: flex;
    flex-flow: row nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    align-items: stretch;
    -webkit-overflow-scrolling: touch;
  }
`;

export const TableHeaderStyle = styled.header.attrs({ className: 'TableHeaderStyle' })<{
  collapsed?: boolean;
}>`
  border-top: 1px solid ${(props) => props.theme.line.color.default};
  transition: all 280ms ease-in-out;
  min-width: ${minTableWidth};
  width: 100%;
  top: 0;
  left: 0;
  font-size: ${(props) => mixins.getRemFromPx(14, props.theme.globals.baseFontSize.default)};
  /* font-size: ${({ collapsed = false, theme }) =>
    collapsed ? mixins.getRemFromPx(14, theme.globals.baseFontSize.default) : 'inherit'}; */
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    min-width: auto;
    ${({ collapsed = false }) => (collapsed ? 'border-top: none;' : '')}
  }
`;

export const ScenarioTableFooterStyle = styled.div.attrs({ className: 'ScenarioTableFooterStyle' })`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: ${(props) => mixins.getRemFromPx(14, props.theme.globals.baseFontSize.default)};
  padding: 30px 0;

  @media only print {
    height: 60px;
    justify-content: center;
  }

  ol {
    padding: 0;

    li {
      display: grid;
      grid-template-columns: 1ch 1fr;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
      counter-increment: list-item;

      &:before {
        content: counter(list-item) '.';
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const ScenarioShapeStyle = styled.div.attrs({
  className: 'ScenarioShapeStyle',
})<ScenarioProps>`
  width: 100%;
  height: 100%;
  mix-blend-mode: difference;
  background-image: ${(props) => {
    const img = scenarioRescourceMap(props).svg;
    return img && cssUrl(img);
  }};
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-y: ${(props) => scenarioRescourceMap(props).posY};
`;

export const HeaderDescInputPrintContainerStyle = styled.div.attrs({
  className: 'HeaderDescInputPrintContainerStyle',
})`
  display: none;
  flex: auto;
  width: 100%;
  padding: 3% 0;
  ${HeaderDescriptionStyle} {
    margin: 0 auto;
  }
  @media only print {
    display: block;
  }
`;

export const TablePreHeaderStyle = styled.div`
  padding: 4vh 0;
  border-top: 1px solid ${({ theme }) => theme.line.color.default};
  font-weight: bold;
  font-size: ${(props) => mixins.getRemFromPx(14, props.theme.globals.baseFontSize.default)};
`;
