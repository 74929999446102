import { z } from 'zod';

export const smartyStreetsUsAutocompleteProSuggestionSchema = z.object({
  street_line: z.string().optional(),
  secondary: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zipcode: z.string().optional(),
  entries: z.number(),
});
export type SmartyStreetsUsAutocompleteProSuggestion = z.infer<
  typeof smartyStreetsUsAutocompleteProSuggestionSchema
>;

/**
 * Response for https://www.smarty.com/products/apis/us-autocomplete-pro-api (GET, status 200)
 */
export const smartyStreetsUsAutocompleteProResponseSchema = z.object({
  suggestions: z.array(smartyStreetsUsAutocompleteProSuggestionSchema),
});
export type SmartyStreetsUsAutocompleteProResponse = z.infer<
  typeof smartyStreetsUsAutocompleteProResponseSchema
>;

export const smartyStreetsUsAutocompleteProErrorResponseSchema = z.object({
  errors: z.array(
    z.object({
      message: z.string(),
      id: z.number().optional(),
      fields: z.array(z.string()).optional(),
      name: z.string().optional(),
    })
  ),
});
export type SmartyStreetsUsAutocompleteProErrorResponse = z.infer<
  typeof smartyStreetsUsAutocompleteProErrorResponseSchema
>;
