import React from 'react';
import AuthenticatedDashboard from 'components/AuthenticatedDashboard';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import RevisedDashboardTasksListPage from './RevisedDashboardTasksListPage';
import StandardDashboardTasksListPage from './StandardDashboardTasksListPage';

const DashboardTasksListPage = () => {
  const dashboardTasksPageRevisedEnabled =
    useFeatureFlag(FeatureFlag.DashboardTasksPageRevised) === 'test';
  const DashboardTasksListImpl = dashboardTasksPageRevisedEnabled
    ? RevisedDashboardTasksListPage
    : StandardDashboardTasksListPage;

  return (
    <AuthenticatedDashboard>
      {({ dashboard }) => dashboard != null && <DashboardTasksListImpl dashboard={dashboard} />}
    </AuthenticatedDashboard>
  );
};

export default DashboardTasksListPage;
