import z from 'zod';

type StorageArea = Pick<Storage, 'clear' | 'getItem' | 'removeItem' | 'setItem'>;

export interface StorageItemOptions {
  storageArea?: StorageArea;
}

export interface StorageItemEvent<TValue> {
  storageItem: StorageItem<TValue>;
  newValue: undefined | TValue;
  oldValue: undefined | TValue;
}

export interface StorageItem<TValue> {
  get key(): string;
  get(): undefined | TValue;
  set(newValue: TValue): void;
  remove(): void;
  subscribe(listener: (event: StorageItemEvent<TValue>) => void): () => void;
}

export type StorageItemListener<TValue> = (event: StorageItemEvent<TValue>) => void;

interface GlobalListener {
  storageItem: object;
  listener: (event: { newStringValue: null | string; oldStringValue: null | string }) => void;
}

const globalListeners: WeakMap<StorageArea, Map<string, Set<GlobalListener>>> = new WeakMap();

export class StringStorageItem implements StorageItem<string> {
  key: string;
  storageArea: StorageArea;

  constructor(key: string, options: StorageItemOptions = {}) {
    this.key = key;
    this.storageArea = options.storageArea ?? localStorage;
  }

  #triggerGlobalListeners(stringValues: {
    newStringValue: null | string;
    oldStringValue: null | string;
  }) {
    const { storageArea } = this;
    const allListeners = globalListeners.get(storageArea)?.get(this.key);
    if (allListeners == null) {
      return;
    }

    const otherListeners = [...allListeners].filter(({ storageItem }) => storageItem !== this);
    for (const listener of otherListeners) {
      setTimeout(() => listener.listener(stringValues));
    }
  }

  set(newValue: string) {
    const { storageArea } = this;
    const oldValue = storageArea.getItem(this.key);
    if (newValue === oldValue) {
      return;
    }
    storageArea.setItem(this.key, newValue);

    this.#triggerGlobalListeners({ newStringValue: newValue, oldStringValue: oldValue });
  }

  get(): undefined | string {
    const { storageArea } = this;
    const stringValue = storageArea.getItem(this.key);
    return stringValue ?? undefined;
  }

  remove() {
    const { storageArea } = this;
    storageArea.removeItem(this.key);
  }

  subscribe(listener: (event: StorageItemEvent<string>) => void): () => void {
    const { storageArea } = this;
    const windowEventListener = (ev: StorageEvent) => {
      if (ev.storageArea !== storageArea || ev.key !== this.key) {
        return;
      }
      const storageItemEvent: StorageItemEvent<string> = {
        storageItem: this,
        newValue: ev.newValue ?? undefined,
        oldValue: ev.oldValue ?? undefined,
      };
      listener(storageItemEvent);
    };
    addEventListener('storage', windowEventListener);

    const globalListener: GlobalListener = {
      storageItem: this,
      listener: ({ oldStringValue, newStringValue }) => {
        const storageItemEvent: StorageItemEvent<string> = {
          storageItem: this,
          newValue: newStringValue ?? undefined,
          oldValue: oldStringValue ?? undefined,
        };
        listener(storageItemEvent);
      },
    };
    {
      let globalListenerMap = globalListeners.get(storageArea);
      if (!globalListenerMap) {
        globalListenerMap = new Map();
        globalListeners.set(storageArea, globalListenerMap);
      }
      let globalListenerSet = globalListenerMap.get(this.key);
      if (!globalListenerSet) {
        globalListenerSet = new Set();
        globalListenerMap.set(this.key, globalListenerSet);
      }
      globalListenerSet.add(globalListener);
    }

    return () => {
      removeEventListener('storage', windowEventListener);

      const globalListenerMap = globalListeners.get(storageArea);
      const globalListenerSet = globalListenerMap?.get(this.key);
      globalListenerSet?.delete(globalListener);
      // Clean up if necessary:
      if (globalListenerSet && globalListenerSet.size === 0) {
        globalListenerMap?.delete(this.key);
        if (globalListenerMap && globalListenerMap.size === 0) {
          globalListeners.delete(storageArea);
        }
      }
    };
  }
}

export class ZodStorageItem<TValue> implements StorageItem<TValue> {
  #stringStorageItem: StringStorageItem;
  get key() {
    return this.#stringStorageItem.key;
  }
  schema: z.ZodType<TValue>;

  constructor(key: string, schema: z.ZodType<TValue>, options?: StorageItemOptions) {
    this.#stringStorageItem = new StringStorageItem(key, options);
    this.schema = schema;
  }

  set(newValue: TValue) {
    const newStringValue = JSON.stringify(newValue);
    this.#stringStorageItem.set(newStringValue);
  }

  #parseStringValue(stringValue: undefined | string): undefined | TValue {
    try {
      if (stringValue) {
        const rawData = JSON.parse(stringValue);
        const { success, data: value } = this.schema.safeParse(rawData);
        if (success) {
          return value;
        }
      }
    } catch (error) {
      // Ignore any errors and return `undefined`
    }

    return undefined;
  }

  get(): undefined | TValue {
    const stringValue = this.#stringStorageItem.get();
    return this.#parseStringValue(stringValue);
  }

  remove() {
    this.#stringStorageItem.remove();
  }

  subscribe(listener: (event: StorageItemEvent<TValue>) => void): () => void {
    return this.#stringStorageItem.subscribe((event) => {
      listener({
        storageItem: this,
        newValue: this.#parseStringValue(event.newValue),
        oldValue: this.#parseStringValue(event.oldValue),
      });
    });
  }
}
