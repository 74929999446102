import { FetchBaseQueryArgs, fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import nullthrows from 'nullthrows';
import {
  SmartyStreetsUsAutocompleteProResponse,
  SmartyStreetsUsAutocompleteProSuggestion,
  smartyStreetsUsAutocompleteProResponseSchema,
} from '../smartStreetsUsAutocompleteProApi';
import { fetchWithFallbackBaseQuery, getUrlWithSearchParams, parseResponse } from './baseQuery';

export type { SmartyStreetsUsAutocompleteProSuggestion };

const baseParams = {
  key: process.env.REACT_APP_SMARTY_STREETS_WEBSITE_KEY,
};

enum RequestType {
  LOOKUP = 'lookup',
}

const smartyStreetsProxyApi = process.env.REACT_APP_SMARTY_STREETS_US_PROXY_API || null;

const proxyEndpoints: null | Record<RequestType, string> = smartyStreetsProxyApi
  ? {
      // https://<point-proxy-server>/smarty-streets/lookup
      [RequestType.LOOKUP]: `${smartyStreetsProxyApi}/lookup`,
    }
  : null;

const enableSmartyStreetsProxy =
  proxyEndpoints != null &&
  document.cookie.split(';').some((item) => item.trim() === 'enableSmartyStreetsProxy=true');

const smartyStreetEndpoints: Record<RequestType, string> = enableSmartyStreetsProxy
  ? nullthrows(proxyEndpoints)
  : {
      [RequestType.LOOKUP]: nullthrows(process.env.REACT_APP_SMARTY_STREETS_US_AUTOCOMPLETE_API),
    };

const enableProxyRetries =
  process.env.REACT_APP_ENABLE_PROXY_RETRIES === 'true' &&
  proxyEndpoints != null &&
  !enableSmartyStreetsProxy;

const fetchBaseQueryArgs: FetchBaseQueryArgs = { mode: 'cors' };

export const smartyStreetsApi = createApi({
  reducerPath: 'smartyStreetsApi',
  baseQuery: enableProxyRetries
    ? fetchWithFallbackBaseQuery(fetchBaseQueryArgs)
    : fetchBaseQuery(fetchBaseQueryArgs),
  endpoints: (builder) => ({
    getLookup: builder.query<
      SmartyStreetsUsAutocompleteProResponse,
      { typedAddress: string; maxResults?: number }
    >({
      query: ({ typedAddress, maxResults = 5 }) => {
        const params = {
          ...baseParams,
          license: 'us-autocomplete-pro-cloud',
          max_results: `${maxResults}`,
          prefer_geolocation: 'CITY',
          search: typedAddress,
        };

        return {
          url: getUrlWithSearchParams(smartyStreetEndpoints[RequestType.LOOKUP], params),
          fallbackUrl: getUrlWithSearchParams(proxyEndpoints?.[RequestType.LOOKUP], params),
        };
      },

      transformResponse: (rawResponse: undefined) =>
        parseResponse(rawResponse, smartyStreetsUsAutocompleteProResponseSchema),
    }),
  }),
});

export const { useGetLookupQuery, useLazyGetLookupQuery } = smartyStreetsApi;
