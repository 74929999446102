export default {
  payoffAmountHeadline: 'Your estimated payoff amount',
  payoffAmountDescription:
    'is the sum of your original investment amount and Point’s share of your home’s appreciation',
  pointsShareHeadline: 'Point’s share of appreciation',
  pointsShareDescription:
    'is the HEI percentage multiplied by the total appreciation of your home at exit',
  totalAppreciationHeadline: 'The total appreciation',
  totalAppreciationDescription:
    'of your home is the difference between your home’s final appraised value at the time of exit and the Appreciation Starting Value',
  cappedPayoffDescription:
    'has been capped. To determine your repayment amount, Point does two calculations, and the lower result is your repayment amount. In your case, the capped amount was lower:',

  protectionCap: 'A protection cap has been applied, preventing a repayment of %uncappedAmount%',

  yourInvestmentAmount: 'Your investment amount',
  pointsShare: 'Point’s share of appreciation',
  originalLumpSum: 'This is the original lump sum you received from Point on %fundingDate%',

  heiPercentage: 'HEI percentage',
  totalAppreciation: 'Total appreciation',
  percentAppreciation:
    'This is the share of appreciation that you give to Point in exchange for the money you received from Point',

  finalHomeValue: 'Final appraised value',
  appreciationStartingValue: 'Appreciation Starting Value',
  whenYouChoose: 'When you choose to exit, this value will be determined by a valid valuation',
  thisIsTheValue: 'This is the value from which Point calculates appreciation',

  appreciationBased: 'Appreciation based repayment',
  orignalInvestment:
    'The original investment amount (%investmentAmount%) plus Point’s Share of Appreciation (%pointShare%).',
  cappedRepayment: 'Capped repayment',
  annualRate:
    'Based on an annual rate (%capPercentage%), compounded monthly, that is applied to your original investment amount.',

  seeTheMath: 'See the math',
  summary: 'Summary',
  back: 'Back',
  plus: 'plus',
  minus: 'minus',
  times: 'times',
  versus: 'versus',
  stillHaveQuestions: 'Still have questions?',
  homeownerHelpCenter: 'Homeowner Help Center',
  close: 'Close',
};
