import * as z from "zod";
import * as Underwrite_Core_Dockets from "./Underwrite.Core.Dockets.types";
import * as Underwrite_Core_Dashboard from "./Underwrite.Core.Dashboard.types";
import * as Middleware from "./Middleware.types";
import * as HomeownerService_Tasks from "./HomeownerService.Tasks.types";

export enum TimelineItemStatus {
  Completed = "Completed",
  InProgress = "InProgress",
  NotStarted = "NotStarted",
}
export const timelineItemStatusSchema = z.nativeEnum(TimelineItemStatus);

export const baseTimelineItemSchema = z
  .object({
    title: z.string(),
    description: z.string(),
    status: timelineItemStatusSchema,
  })
  .catchall(z.unknown() as z.ZodType);
export type BaseTimelineItem = z.infer<typeof baseTimelineItemSchema>;

export enum TimelineStepId {
  PrequalificationStep = "PrequalificationStep",
  ApplicationStep = "ApplicationStep",
  ProductEducationStep = "ProductEducationStep",
  IdentityDocumentsStep = "IdentityDocumentsStep",
  InitialEstimateStep = "InitialEstimateStep",
  HomeAppraisalStep = "HomeAppraisalStep",
  PropertyTitleStep = "PropertyTitleStep",
  PropertyInsuranceStep = "PropertyInsuranceStep",
  FinalOfferStep = "FinalOfferStep",
  PointInsuranceStep = "PointInsuranceStep",
  ClosingOverviewStep = "ClosingOverviewStep",
  SigningAppointmentStep = "SigningAppointmentStep",
  WiringFundsStep = "WiringFundsStep",
}
export const timelineStepIdSchema = z.nativeEnum(TimelineStepId);

/**
 * A step in the timeline of a docket displayed in a dashboard
 */
export const timelineStepSchema = baseTimelineItemSchema.extend({
  id: timelineStepIdSchema,
});
export type TimelineStep = z.infer<typeof timelineStepSchema>;

export const stageDurationSchema = z
  .object({
    minDays: z.number(),
    maxDays: z.number(),
  })
  .catchall(z.unknown() as z.ZodType);
export type StageDuration = z.infer<typeof stageDurationSchema>;

export enum TimelineStageId {
  ApplyForFundingStage = "ApplyForFundingStage",
  VerifyInformationStage = "VerifyInformationStage",
  EvaluatePropertyStage = "EvaluatePropertyStage",
  FinalizeOfferStage = "FinalizeOfferStage",
  CompleteClosingStage = "CompleteClosingStage",
  ReceiveFundsStage = "ReceiveFundsStage",
}
export const timelineStageIdSchema = z.nativeEnum(TimelineStageId);

export const baseTimelineStageSchema = baseTimelineItemSchema.extend({
  id: timelineStageIdSchema,
  steps: z.array(timelineStepSchema),
});
export type BaseTimelineStage = z.infer<typeof baseTimelineStageSchema>;

export const timelineCompletedStageSchema = baseTimelineStageSchema.extend({
  status: z.literal(TimelineItemStatus.Completed),
  startDate: z.string().datetime({ offset: true }),
  completionDate: z.string().datetime({ offset: true }),
});
export type TimelineCompletedStage = z.infer<
  typeof timelineCompletedStageSchema
>;

export const timelineInProgressStageSchema = baseTimelineStageSchema.extend({
  status: z.literal(TimelineItemStatus.InProgress),
  startDate: z.string().datetime({ offset: true }),
  duration: stageDurationSchema,
});
export type TimelineInProgressStage = z.infer<
  typeof timelineInProgressStageSchema
>;

export const timelineNotStartedStageSchema = baseTimelineStageSchema.extend({
  status: z.literal(TimelineItemStatus.NotStarted),
  duration: stageDurationSchema,
});
export type TimelineNotStartedStage = z.infer<
  typeof timelineNotStartedStageSchema
>;

/**
 * A stage in the timeline of a docket displayed in a dashboard
 */
export const timelineStageSchema = z.discriminatedUnion("status", [
  timelineCompletedStageSchema,
  timelineInProgressStageSchema,
  timelineNotStartedStageSchema,
]);
export type TimelineStage = z.infer<typeof timelineStageSchema>;

export const timelineSchema = z
  .object({
    fundingDate: z
      .object({
        from: z.string().datetime({ offset: true }),
        to: z.string().datetime({ offset: true }),
      })
      .catchall(z.unknown() as z.ZodType),
    stages: z.array(timelineStageSchema),
    isDelayed: z.boolean().optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type Timeline = z.infer<typeof timelineSchema>;

export const globalUpdateItemSchema = z
  .object({
    message: z.string(),
  })
  .catchall(z.unknown() as z.ZodType);
export type GlobalUpdateItem = z.infer<typeof globalUpdateItemSchema>;

export const globalUpdateSchema = z
  .object({
    items: z.array(globalUpdateItemSchema),
  })
  .catchall(z.unknown() as z.ZodType);
export type GlobalUpdate = z.infer<typeof globalUpdateSchema>;

export const hosDashboardSuccessResponseSchema = z
  .object({
    success: z.literal(true),
    active_stage: Underwrite_Core_Dockets.docketStageSchema,
    recent_activity: z.array(Underwrite_Core_Dashboard.completedTaskSchema),
    initial_offer: z.number().nullable(),
    stages: z.array(Underwrite_Core_Dashboard.docketStageItemSchema),
    person: Underwrite_Core_Dashboard.dashboardPersonSchema,
    docket: Underwrite_Core_Dashboard.docketSchema,
    application:
      Underwrite_Core_Dashboard.dashboardApplicationSchema.optional(),
    authenticate: z.boolean(),
    read_only: z.boolean(),
    account_manager: z.union([z.string(), Middleware.userSchema]).nullable(),
    tasks: z.array(HomeownerService_Tasks.taskSchema).optional(),
    timeline: timelineSchema.optional(),
    globalUpdate: globalUpdateSchema.optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type HosDashboardSuccessResponse = z.infer<
  typeof hosDashboardSuccessResponseSchema
>;

export const logInByEmailRequestSchema = z
  .object({
    emailAddress: z.string(),
  })
  .catchall(z.unknown() as z.ZodType);
export type LogInByEmailRequest = z.infer<typeof logInByEmailRequestSchema>;

export const loginByEmailResponseSchema = z
  .object({})
  .catchall(z.unknown() as z.ZodType);
export type LoginByEmailResponse = z.infer<typeof loginByEmailResponseSchema>;

export const hosDashboardResponseSchema = z.union([
  hosDashboardSuccessResponseSchema,
  Underwrite_Core_Dashboard.dashboardErrorResponseSchema,
]);
export type HosDashboardResponse = z.infer<typeof hosDashboardResponseSchema>;
