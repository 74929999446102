export default {
  electronicSig: 'Electronic signature',
  socialSecurity: 'Social Security number',
  confirmSocialSecurity: 'Confirm Social Security number',
  typeYour: 'Type your full name here',
  consentTo:
    'Consent to Credit Check, Employment Verification, and Disclosure of Account Information to Escrow Companies',
  pointWillUse: 'Point will use your credit report to assess your debts.',
  youAcknowledge:
    'You acknowledge that an electronic copy of this Authorization shall be as valid as an original.',
  pointMustDisclose:
    'Point must disclose to you that we are acting on our own behalf because we are licensed as a real estate broker.',
  youAgreeThat:
    'You agree that an electronic copy of this Disclosure shall be as valid as an original.',
  iAcknowledgeReceiptOfDisclosure:
    'I acknowledge receipt of Disclosure Regarding Real Estate Agency Relationship.',
  iAcknowledgeReceiptOfConfirmation:
    'I acknowledge receipt of Confirmation of Agency Relationship.',
  thisDocument: 'this document',
  iHerebyAuthorize:
    'I hereby authorize the disclosure of certain account information to Point and the escrow companies noted in %thisDocument% solely for the purposes of closing this transaction.',
  iHerebyAuthorizePointToObtain: 'I hereby authorize Point to obtain my consumer credit report.',
  inConnectionWith: `In connection with Pointʼs evaluation of your application, Point must review certain
    information regarding your consumer credit.
    Accordingly, by checking the boxes below and submitting your application, you hereby
    authorize Point to request the release of such credit information to process your application. Such information may include
    verification of savings deposits, checking
    accounts, consumer credit balances, and payment history, including mortgage payment records
    and balances. Point will request and review your consumer credit report to assess your debts. Please consult the %summaryOfYourRights%
    for more information about your consumer rights. In order to close your transaction, Point
    and the escrow company selected may need to obtain certain account information from your
    creditors.`,
  summaryOfYourRights: 'Summary of Your Rights Under the Fair Credit Reporting Act',
  iHaveReadAndAgree:
    "I have read and agree to Point's %creditReportPolicy%, %concentToElectronic%, %termsOfUse% and %privacyPolicy%.",
  creditReportPolicy: 'Credit Report Policy',
  concentToElectronic: 'Consent to Electronic Communications and Electronic Signatures',
  termsOfUse: 'Terms of Use',
  privacyPolicy: 'Privacy Policy',
  signatureMismatch: 'Name does not match',
  ssnMismatch: 'Social Security numbers do not match',
};
