import React, { useMemo } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router';
import { Devtools } from 'components/Devtools';
import { FeatureFlagsProvider } from 'lib/featureFlags';
import { routes } from './routes';

const App = () => {
  const router = useMemo(() => createBrowserRouter(routes), []);

  return (
    <FeatureFlagsProvider>
      <RouterProvider router={router} />
      <Devtools />
    </FeatureFlagsProvider>
  );
};

export default App;
