export default {
  thankYou: 'Thank you for your interest in Point',
  weRegret:
    'We regret that we’re unable to offer Point for your property at this time. %pointQualificationsLink%',
  weApologize: `We apologize—based on updated information we’ve received for your request, it looks like we cannot invest in your home at this time. Our ability to invest is based on many factors, including but not limited to a homeowner’s mortgage balance, home value, title report, any bankruptcies, and credit score. We’ll email you with more details shortly. If you have questions about your specific case, please  %scheduleACallLink% with one of our team members.`,
  weRegretCreditNotFound:
    'We regret that we’re unable to pre-qualify you for %product% because we couldn’t access your credit history. This may be due to a typo in your name. Please %reapplyLink%.',
  ifYouFeel:
    'If you feel you’ve reached this message in error, please feel free to give us a call at (855) 495-1039, or if after hours, %scheduleATimeLink% for us to call you.',
  weRegretFrozen:
    'We regret that we’re unable to pre-qualify you for %product% because we couldn’t access your credit history. This is due to a freeze on your credit. Please remove the freeze and %reapplyLink%.',
  heiProductName: 'the Point Home Equity Investment',
  removeTheFreezeHereLink: 'remove the freeze here',
  scheduleACallLink: 'schedule a call',
  scheduleATimeLink: 'schedule a time',
  orYouMayHaveATypo:
    'Or, you may have a typo somewhere. You can go back and %restartYourApplicationLink%.',
  restartYourApplicationLink: 'restart your application',
  reapplyLink: 'reapply',
  pointQualificationsLink: 'Learn more about the qualifications for Point here.',
  offerExpired: 'This application has expired. You can go back and %restartYourApplicationLink%.',
  updatedInformation: `Unfortunately, with the updated information you provided, your property does not qualify for a
          Point Home Equity Investment. However, we hope that we can work with you sometime in the future. If you have
          any questions or if you feel you've received this message in error, please %emailHelpLink%.`,
  titleGap: `Due to constraints from our title vendors, we are unable to work with customers whose credit scores fall under
          600 in your state at this time. We will contact you when we resolve this issue.`,
  hereAreSome: 'Here are some common reasons why someone might not qualify:',
  // static reasons
  theNameAnd: 'The name and Social Security number do not match the owner of the home',
  theCreditScore: 'The credit score (FICO) is below 500',
  thePropertyValue: 'The property value is not between $155,000 and $4,500,000',
  thePropertyIs: 'The property is not in an area where Point operates',
  thereHaveBeen: 'There have been recent foreclosures or bankruptcies',
  theyDoNot: 'They do not qualify for at least $30,000 from Point',
  thereIsNot: 'There is not enough available equity in the home',
  emailHelpLink: 'send us an email',
};
