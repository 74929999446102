import styled, { IStyledComponent, css } from 'styled-components';
import {
  ButtonStyles,
  ContainerStyles,
  GraphicsStyles,
  HeaderStyles,
  mixins,
} from '@pointdotcom/pds';
import { PlayButtonStyle } from 'components/VideoPlayer/PlayButton';
import { pxToRem } from 'styleHelpers';
import { PaddedSectionStyle } from 'styles';

export { HighlightedNumberStyle } from '../styles';

export const GoodNewsSectionStyle = styled(PaddedSectionStyle)`
  isolation: isolate;
  position: relative;
  padding-bottom: 0;

  ${HeaderStyles.HeaderStyle} {
    margin-bottom: ${pxToRem(6)};

    @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
      margin-bottom: ${pxToRem(21)};
    }

    > h1 {
      font-size: ${pxToRem(64)};

      @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
        font-size: ${pxToRem(56)};
      }
      @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
        font-size: ${pxToRem(45)};
      }
    }
  }
`;

export const MessageTextStyle = styled.p`
  margin: 0;

  font-family: ${({ theme }) => theme.fonts.serif.name};
  font-size: ${({ theme }) => mixins.getRemFromPx(22, theme.globals.baseFontSize.default)};
  line-height: 1.48;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    font-size: ${({ theme }) => mixins.getRemFromPx(18, theme.globals.baseFontSize.default)};
  }
`;

export const GoodNewsMessageTextStyle = styled(MessageTextStyle)`
  font-style: italic;
  color: ${({ theme }) => theme.Color.PointBlue};
  line-height: 1.04;
`;

export const TextAndImageStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    flex-direction: column;
  }
`;

export const TextContainerStyle = styled.div.attrs({ className: 'TextContainerStyle' })<{
  offerEstimatorVideoPlayerFlagEnabled?: boolean;
}>`
  > ${ContainerStyles.ContainerStyle} {
    display: flex;
    flex-direction: column;
    gap: ${pxToRem(29)};
  }
  ${({ offerEstimatorVideoPlayerFlagEnabled = false, theme }) =>
    offerEstimatorVideoPlayerFlagEnabled
      ? css`
          @media (min-width: ${theme.responsive.tabletBreakpoint}) {
            text-align: center;
          }
        `
      : css`
          width: 60%;
          padding-bottom: ${pxToRem(80)};
        `}
  flex-shrink: 0;

  ${HeaderStyles.HeaderStyle} > * {
    text-align: inherit;
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    width: auto;
    padding-bottom: 0;
  }
`;

type ImageInfo = {
  graphicsStyle: IStyledComponent<'web'>;
  width: number;
  height: number;
  overhangLeft?: number;
  overhangRight?: number;
};

function getImageCss(imageInfo: ImageInfo, { maxWidth }: { maxWidth?: number } = {}) {
  const { graphicsStyle, width, height, overhangLeft = 0, overhangRight = 0 } = imageInfo;

  return css`
    padding: calc(1px / 64);
    ${maxWidth != null
      ? css`
          max-width: ${(maxWidth * (width - overhangLeft - overhangRight)) / width}px;
        `
      : null};

    margin: 0 auto;

    ${graphicsStyle} {
      width: auto;
      height: auto;

      margin-left: ${(100 * -overhangLeft) / (width - overhangLeft - overhangRight)}%;
      margin-right: ${(100 * -overhangRight) / (width - overhangLeft - overhangRight)}%;

      &::after {
        display: block;
        content: '';
        width: auto;
        padding-bottom: ${(height / width) * 100}%;
      }
    }
  `;
}

const partyDogSpeakerImageInfo: ImageInfo = {
  graphicsStyle: GraphicsStyles.PartyDogSpeakerStyle,
  width: 853,
  height: 572,
  overhangLeft: 112,
  overhangRight: 200,
};

export const DesktopImageContainerStyle = styled.div`
  align-self: flex-end;
  flex-grow: 1;
  z-index: -1;

  ${getImageCss(partyDogSpeakerImageInfo, { maxWidth: 463 })};

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    display: none;
  }
`;

const punchImageInfo: ImageInfo = {
  graphicsStyle: GraphicsStyles.PunchStyle,
  width: 1127,
  height: 765,
};

const partyDogImageInfo: ImageInfo = {
  graphicsStyle: GraphicsStyles.PartyDogStyle,
  width: 1127,
  height: 765,
  overhangLeft: 290,
  overhangRight: 220,
};

export const MobileImageContainerStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;

  @media (min-width: ${({ theme }) => theme.responsive.tabletBreakpoint}) {
    display: none;
  }

  > :nth-child(1) {
    ${getImageCss(punchImageInfo)};
    width: ${(100 * 126) / 260}%;
  }

  > :nth-child(2) {
    ${getImageCss(partyDogImageInfo)};
    width: ${(100 * 78) / 260}%;
  }
`;

export const BasicDetailsColumnStyle = styled.div.attrs({ className: 'BasicDetailsColumnStyle' })`
  display: contents;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.Color.GrayLight1};
    gap: ${pxToRem(22)};
    padding: ${pxToRem(28)} ${pxToRem(58)};

    &:first-child {
      padding-top: ${pxToRem(32)};
    }
    &:last-child {
      padding-bottom: ${pxToRem(32)};
    }
  }
`;

export const BasicDetailsTableStyle = styled.div.attrs({ className: 'BasicDetailsTableStyle' })`
  max-width: ${pxToRem(1024)};
  margin: auto;
  display: grid;
  gap: ${pxToRem(1)};
  background: ${({ theme }) => mixins.rgba(theme.line.color.default, 0.2)};
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  box-shadow: 0px -30px 26px -40px ${({ theme }) => mixins.rgba(theme.line.color.default, 0.67)};

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    display: flex;
    flex-direction: column;
    box-shadow: none;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    border-top: 1px solid ${({ theme }) => theme.line.color.default};
    ${BasicDetailsColumnStyle} {
      background: white;
    }
  }
`;

const BasicDetailsCellStyle = styled.div`
  background: ${({ theme }) => theme.Color.White};
  padding: ${pxToRem(20)} ${pxToRem(40)};

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    background: none;
    padding: 0;
  }
`;

export const BasicDetailsHeaderStyle = styled(BasicDetailsCellStyle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-row: 1;

  > :nth-child(1) {
    font-weight: 700;
    font-size: ${({ theme }) => mixins.getRemFromPx(15, theme.globals.baseFontSize.default)};
    text-transform: uppercase;
  }
  > :nth-child(2) {
    font-weight: 900;
    font-size: ${({ theme }) => mixins.getRemFromPx(21, theme.globals.baseFontSize.default)};
  }
`;
export const BasicDetailsDescriptionStyle = styled(BasicDetailsCellStyle)`
  grid-row: 2;
  padding-top: ${pxToRem(30)};
  padding-bottom: ${pxToRem(40)};
  font-size: ${({ theme }) => mixins.getRemFromPx(16, theme.globals.baseFontSize.default)};
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  line-height: 1.3;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    padding: 0;
  }
`;

export const PlayButtonComboStyle = styled.div.attrs({ className: 'PlayButtonComboStyle' })`
  --play-button-color: ${({ theme }) => theme.Color.PurpleBlue5};

  ${ButtonStyles.ButtonBaseStyle} {
    background-color: var(--play-button-color);
  }

  ${ButtonStyles.ButtonContentStyle} {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    justify-content: center;

    > :first-child {
      position: relative;

      --play-button-size: 5.2rem;
      width: 2rem;
      @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
        --play-button-size: 1.7rem;
        width: var(--play-button-size);
      }

      ${PlayButtonStyle} {
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-50%);
        --size: var(--play-button-size);
        --bg-color: var(--play-button-color);
      }
    }
  }
  ${ButtonStyles.ButtonBaseStyle} {
    ${mixins.desktopHoverCondition()} {
      &:hover {
        ${PlayButtonStyle} {
          transform: translateY(-50%) scale(1.1);
        }
      }
    }
  }
`;
